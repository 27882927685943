import React from "react";
import StaticHeader from "../components/StaticHeader";


import imgAvatar from '../dashboardstyle/images/avatarProfile.jpg'
import fImg from '../dashboardstyle/images/featured.jpg'

const CommunityDetails = () => {
  return (
    <div>
      <StaticHeader />

      {/* <div className="p-120"></div> */}

      <section class="gray-bg no-top-padding-secz">
        <div class="container">
          <div class="breadcrumbs inline-breadcrumbs fl-wrap block-breadcrumbs">
            <a href="#">User Details</a>
          
          </div>
          <div class="fl-wrap">
            <div class="row">
              <div class="col-md-8">
                <div class="user-profile-header fl-wrap">
                  <div class="user-profile-header_media fl-wrap">
                    <div
                      class="bg"
                     

                      style={{
                    
                        backgroundImage: `url(${fImg})`,
                      }}
                      
                    ></div>

                    <div class="user-profile-header_media_title">
                      <h3>Alisa Noory</h3>
                      <h4>Founder of ChatGPT</h4>
                    </div>
                    <div class="user-profile-header_stats">
                      <ul class="no-list-style">
                        <li>
                          <span><i className="fas fa-eye"></i> 10</span>Views
                        </li>
                      
                      </ul>
                    </div>
                 
                  </div>
                  <div class="user-profile-header_content fl-wrap">
                    <div class="user-profile-header-avatar">
                      <img src={imgAvatar} alt="" />
                    </div>
                    <p>
                      Praesent eros turpis, commodo vel justo at, pulvinar
                      mollis eros. Mauris aliquet eu quam id ornare. Morbi ac
                      quam enim. Cras vitae nulla condimentum, semper dolor non,
                      faucibus dolor. Vivamus adipiscing eros quis orci
                      fringilla, sed pretium lectus viverra. Pellentesque
                      habitant morbi tristique senectus et netus et malesuada
                      fames ac turpis egestas. Donec nec velit non odio aliquam
                      suscipit. Sed non neque faucibus, condimentum lectus at,
                      accumsan enim.{" "}
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Maecenas in pulvinar neque. Nulla finibus lobortis
                      pulvinar. Donec a consectetur nulla. Nulla posuere sapien
                      vitae lectus suscipit, et pulvinar nisi tincidunt. Aliquam
                      erat volutpat. Curabitur convallis fringilla diam sed
                      aliquam. Sed tempor iaculis massa faucibus feugiat. In
                      fermentum facilisis massa, a consequat purus viverra.
                    </p>
                   
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="box-widget-item fl-wrap block_box">
                  <div class="box-widget-item-header">
                    <h3>About Athor </h3>
                  </div>
                  <div class="box-widget">
                    <div class="box-widget-author fl-wrap">
                      <div class="box-widget-author-title">
                        <div class="box-widget-author-title-img">
                          <img src={imgAvatar} alt="" />
                        </div>
                        <div class="box-widget-author-title_content">
                          <a href="user-single.html">Alisa Noory</a>
                          <span>Founder and CEO</span>
                        </div>
                        <div class="box-widget-author-title_opt">
                          <a
                            href="#"
                            class="tolt color-bg cwb text-center"
                            data-microtip-position="top"
                            data-tooltip="Chat With Owner"
                          >
                            <i class="fas fa-comments"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="box-widget-item fl-wrap block_box">
                  <div class="box-widget-item-header">
                    <h3>User Contacts </h3>
                  </div>
                  <div class="box-widget">
                    <div class="box-widget-content bwc-nopad">
                      <div class="list-author-widget-contacts list-item-widget-contacts bwc-padside">
                        <ul class="no-list-style">
                          <li>
                            <span>
                              <i class="fas fa-map-marker"></i> Adress :
                            </span>{" "}
                            <a href="#">57 Sloane, Bryanston</a>
                          </li>
                          <li>
                            <span>
                              <i class="fas fa-phone"></i> Phone :
                            </span>{" "}
                            <a href="#">+27 11 187 1654</a>
                          </li>
                          <li>
                            <span>
                              <i class="fas fa-envelope"></i> Mail :
                            </span>{" "}
                            <a href="#">AlisaNoory@domain.com</a>
                          </li>
                          <li>
                            <span>
                              <i class="fas fa-globe-africa"></i> Website :
                            </span>{" "}
                            <a href="#">thinkt.co.za</a>
                          </li>
                        </ul>
                      </div>
                      <div class="list-widget-social bottom-bcw-box  fl-wrap">
                        <ul class="no-list-style">
                          <li>
                            <a href="#" target="_blank">
                              <i class="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i class="fab fa-x-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i class="fab fa-vk"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i class="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                        <div class="bottom-bcw-box_link">
                          <a
                            href="#"
                            class="show-single-contactform tolt text-center"
                            data-microtip-position="top"
                            data-tooltip="Write Message"
                          >
                            <i class="fas fa-envelope"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CommunityDetails;
