import React, { useEffect, useState } from "react";
import { CircleLoader } from "react-spinners";
import { auth, db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import Avatar from "react-avatar";

import imgChart from '../dashboardstyle/images/chart.png';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const DashboardAttend = () => {
  const [virtualSpaces, setVirtualSpaces] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const email = user.email;
          // Query virtual spaces (offices) where the user is invited
          const qSpaces = query(
            collection(db, "virtual-offices"),
            where("participants", "array-contains", email)
          );
          const spacesSnapshot = await getDocs(qSpaces);
          const spacesData = spacesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setVirtualSpaces(spacesData);

          // Query events where the user is invited
          const qEvents = query(
            collection(db, "events"),
            where("participants", "array-contains", email)
          );
          const eventsSnapshot = await getDocs(qEvents);
          const eventsData = eventsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEvents(eventsData);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Combine virtual spaces and events with a new "category" property
  const combinedItems = [
    ...virtualSpaces.map((item) => ({ ...item, category: "Virtual Space" })),
    ...events.map((item) => ({ ...item, category: "Event" })),
  ];

  // Stat counts
  const totalVirtualSpaces = virtualSpaces.length;
  const totalVirtualEvents = events.filter((e) => e.type === "virtual-event").length;
  const totalHybridEvents = events.filter((e) => e.type === "hybrid-event").length;
  const totalInPersonEvents = events.filter((e) => e.type === "inperson-event").length;

  const handleAttend = (id, category) => {
    // Replace the alert with your actual attend logic (e.g., navigation)
    alert(`Attending ${category} ${id}`);
  };

  if (loading) {
    return (
      <div className="loader-containerz">
        <CircleLoader size={50} color="#ce2b7c" loading={loading} />
      </div>
    );
  }
  return (
    <div>
      <div class="content-bodyz default-height">
        <div class="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                {/* Left Side */}
                <div className="col-xl-6">
                  <div class="col-xl-12">
                    <div class="card tryal-gradient-meeting">
                      <div class="card-body tryal row">
                        <div class="col-xl-7 col-sm-7">
                          <h2 class="mb-0">Attend Virtual Events in Space</h2>
                          <span>
                            Let eConnect help you organise and virtual events
                          </span>
                         
                        </div>
                        <div class="col-xl-5 col-sm-5 ">
                        <img src={imgChart} alt="" className="sd-shape"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Side */}
              

                <div className="col-xl-6">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="row">
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card bgl-primary">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 text-primary" style={{backgroundColor: '#ffffff'}}>
                                  <i className='fas fa-hotel'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">Total Virtual Spaces</p>
                                  <h4 className="mb-0">{totalVirtualSpaces}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card bgl-warning">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 bgl-warning text-warning " style={{backgroundColor: '#ffffff'}}>
                                  <i className='fas fa-vr-cardboard'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">Virtual Events</p>
                                  <h4 className="mb-0">{totalVirtualEvents}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card  bgl-success">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 bgl-success text-success" style={{backgroundColor: '#ffffff'}}>
                                  <i className='fas fa-globe'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">Hybrid Events</p>
                                  <h4 className="mb-0">{totalHybridEvents}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card bgl-sec-tz">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 bgl-sec-t text-sec-t" style={{backgroundColor: '#ffffff'}}>
                                  <i className='fas fa-user-friends'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">In-person Events</p>
                                  <h4 className="mb-0">{totalInPersonEvents}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        


                <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Events and Spaces</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-responsive-md">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name / Description</th>
                          <th>Date</th>
                          <th>Category</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {combinedItems.length === 0 ? (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No events or spaces available
                            </td>
                          </tr>
                        ) : (
                          combinedItems.map((item) => (
                            <tr key={item.id}>
                              <td>{item.id}</td>
                              <td>
                                {item.category === "Virtual Space"
                                  ? item.officeName
                                  : item.description}
                              </td>
                              <td>
                                {item.category === "Virtual Space"
                                  ? item.createdAt && item.createdAt.seconds
                                    ? new Date(item.createdAt.seconds * 1000).toLocaleDateString()
                                    : "N/A"
                                  : item.date}
                              </td>
                              <td>{item.category}</td>
                              <td>
                              <div className="project-btn">
                              <a
                              href={`virtual-office-space/${item.id}`}
                                  className="badge badge-primary light badge-md"
                              
                                >
                                  Attend Event
                                </a>
                              </div>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {combinedItems.length === 0 && (
            <div className="alert alert-info mt-4">
              There are no events or spaces available at the moment.
            </div>
          )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAttend;
