import { JitsiMeeting } from '@jitsi/react-sdk';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { SignJWT } from "jose";

const APP_ID = "econnectapplication"; // Matches app_id in Jitsi config
const APP_SECRET = "c6b2332f35c4e46da00e67dab9637e90af3ac37b42ec";
const JITSI_DOMAIN = "e-connect-dev-meeting.e-connect.co.za";


const MeetingRoom = ({ roomName }) => {
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const auth = getAuth();

    // 🔹 Monitor Firebase Authentication state
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser || null);
        });
        return () => unsubscribe();
    }, [auth]);

    // 🔹 Function to Generate JWT Token
    const generateToken = async () => {
        if (!user) return;
        if (!APP_ID || !APP_SECRET) {
            console.error("Jitsi APP_ID or APP_SECRET is undefined. Check your .env file.");
            return;
        }

        const payload = {
            aud: APP_ID,
            iss: APP_ID,
            sub: JITSI_DOMAIN,
            room: roomName, // 🔹 Only allow access to this specific room
            exp: Math.floor(Date.now() / 1000) + 60 * 60, // 1-hour validity
            context: {
                user: {
                    name: user.displayName || "Guest",
                    email: user.email || "",
                    moderator: true, // 🔹 Ensure this user is a moderator
                }
            }
        };

        const encoder = new TextEncoder();
        const jitsiToken = await new SignJWT(payload)
            .setProtectedHeader({ alg: "HS256" })
            .setExpirationTime("1h")
            .sign(encoder.encode(APP_SECRET));

        setToken(jitsiToken);
        localStorage.setItem("jitsiToken", jitsiToken); // 🔹 Save to prevent re-creation
        localStorage.setItem("tokenExpiry", Date.now() + 59 * 60 * 1000); // 🔹 Store expiry (59 min)
    };

    // 🔹 Generate Token when User Logs In & Only If Expired
    useEffect(() => {
        if (!user) return;

        const storedToken = localStorage.getItem("jitsiToken");
        const tokenExpiry = localStorage.getItem("tokenExpiry");

        if (storedToken && tokenExpiry && Date.now() < tokenExpiry) {
            setToken(storedToken); // 🔹 Use the same token if it's still valid
        } else {
            generateToken(); // 🔹 Generate a new token only if expired
        }
    }, [user]);

    return token ? (
        <div style={styles.container}>
            <JitsiMeeting
                domain={JITSI_DOMAIN}
                roomName="meetingtest"
                configOverwrite={{
                    prejoinPageEnabled: false,
                    startWithAudioMuted: true,
                    startWithVideoMuted: true
                }}
                jwt={token}
                userInfo={{ displayName: user?.displayName || "Guest", email: user?.email }}
                getIFrameRef={(iframe) => {
                    iframe.style.width = "100%";
                    iframe.style.height = "100vh";
                }}
            />
        </div>
    ) : (
        <p style={styles.loading}>Loading...</p>
    );
};

// 🔹 Full-screen Jitsi meeting styles
const styles = {
    container: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "#000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    loading: {
        textAlign: "center",
        fontSize: "20px",
        fontWeight: "bold",
        color: "#333",
        marginTop: "20vh",
    },
};

export default MeetingRoom;
