import React, { useState, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { db } from '../../../firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { DotLoader } from 'react-spinners';
import { toast } from 'react-toastify';

// Global cache for IP geolocation to avoid repetitive network calls
const ipCache = {};

// Component to display IP address with geolocation details
const IPDetails = ({ ip }) => {
  const [location, setLocation] = useState('');

  useEffect(() => {
    const fetchLocation = async () => {
      if (!ip) return;
      if (ipCache[ip]) {
        setLocation(ipCache[ip]);
      } else {
        try {
          const response = await fetch(`https://ipapi.co/${ip}/json/`);
          const data = await response.json();
          const loc = data.city && data.country_name 
            ? `${data.city}, ${data.country_name}` 
            : 'Location not found';
          ipCache[ip] = loc;
          setLocation(loc);
        } catch (error) {
          console.error('Error fetching IP location', error);
          setLocation('Unknown location');
        }
      }
    };
    fetchLocation();
  }, [ip]);

  return (
    <div>
      <div>{ip}</div>
      <div style={{ fontSize: '0.8rem', color: '#555' }}>{location}</div>
    </div>
  );
};

/**
 * NEW HELPER FUNCTION
 * This function groups logs by user, room, and date (derived from the join event).
 * For each session it extracts:
 * - Enter details: the join event time and duration until the next event (if any)
 * - Status details: a semicolon-separated list of status changes (with times and durations)
 * - Exit details: the exit event time and total session duration (from join to exit)
 */
const computeSessionRecords = (logs) => {
  const sessionsByKey = {}; // key format: "user_date_room"
  logs.forEach(log => {
    // Determine the event time:
    let eventTime = null;
    if (log.event === 'join') {
      eventTime = log.joinedAt ? log.joinedAt.toDate() : null;
    } else if (log.event === 'exit') {
      eventTime = log.exitedAt ? log.exitedAt.toDate() : null;
    } else {
      eventTime = log.createdAt ? log.createdAt.toDate() : null;
    }
    if (!eventTime) return;
    const dateStr = eventTime.toLocaleDateString();
    const user = log.userName || 'N/A';
    const room = log.roomName || 'N/A';
    const key = `${user}_${dateStr}_${room}`;
    if (!sessionsByKey[key]) sessionsByKey[key] = [];
    sessionsByKey[key].push({ ...log, eventTime });
  });

  const records = [];
  Object.entries(sessionsByKey).forEach(([key, group]) => {
    // Sort the group by eventTime ascending
    group.sort((a, b) => a.eventTime - b.eventTime);
    const user = group[0].userName || 'N/A';
    const room = group[0].roomName || 'N/A';
    const date = group[0].eventTime.toLocaleDateString();

    // Get the join (enter) event – the first event with event === 'join'
    const joinLog = group.find(log => log.event === 'join');

    // Get the exit event – the last event with event === 'exit'
    const exitLog = [...group].reverse().find(log => log.event === 'exit');

    // Calculate enter details:
    let enterDetails = '';
    if (joinLog) {
      const joinTime = joinLog.eventTime;
      let nextTime = null;
      const joinIndex = group.findIndex(log => log.id === joinLog.id);
      if (joinIndex >= 0 && joinIndex < group.length - 1) {
        nextTime = group[joinIndex + 1].eventTime;
      }
      let durationText = '';
      if (nextTime) {
        const durationMs = nextTime - joinTime;
        const durationMinutes = Math.round(durationMs / 60000);
        durationText = ` for ${durationMinutes} minutes`;
      }
      enterDetails = `Joined at ${joinTime.toLocaleTimeString()}${durationText}`;
    }

    // Calculate status details: include logs between join and exit that are not join/exit.
    const statusLogs = group.filter(log => log.event !== 'join' && log.event !== 'exit');
    let statusDetails = '';
    if (statusLogs.length > 0) {
      statusDetails = statusLogs.map((log, idx) => {
        const statusTime = log.eventTime;
        let nextTime = null;
        if (idx < statusLogs.length - 1) {
          nextTime = statusLogs[idx + 1].eventTime;
        } else if (exitLog) {
          nextTime = exitLog.eventTime;
        }
        let durationText = '';
        if (nextTime) {
          const durationMs = nextTime - statusTime;
          const durationMinutes = Math.round(durationMs / 60000);
          durationText = ` (${durationMinutes} minutes)`;
        }
        return `${log.status || 'Status changed'} at ${statusTime.toLocaleTimeString()}${durationText}`;
      }).join('; ');
    }

    // Calculate exit details:
    let exitDetails = '';
    if (exitLog) {
      const exitTime = exitLog.eventTime;
      let totalDurationText = '';
      if (joinLog) {
        const totalMs = exitTime - joinLog.eventTime;
        const totalMinutes = Math.round(totalMs / 60000);
        totalDurationText = ` (total ${totalMinutes} minutes)`;
      }
      exitDetails = `Exited at ${exitTime.toLocaleTimeString()}${totalDurationText}`;
    }

    records.push({
      user,
      room,
      date,
      enter: enterDetails,
      status: statusDetails,
      exit: exitDetails,
    });
  });
  return records;
};

// (Existing helper function for sessions per user if needed elsewhere)
const computeSessionsForUser = (logs) => {
  const sortedLogs = [...logs].sort((a, b) => {
    const aTime = a.createdAt ? a.createdAt.toDate() : (a.joinedAt ? a.joinedAt.toDate() : new Date(0));
    const bTime = b.createdAt ? b.createdAt.toDate() : (b.joinedAt ? b.joinedAt.toDate() : new Date(0));
    return aTime - bTime;
  });
  const sessions = [];
  for (let i = 0; i < sortedLogs.length; i++) {
    const log = sortedLogs[i];
    if (log.event === 'join') {
      const startTime = log.joinedAt ? log.joinedAt.toDate() : null;
      let endTime = null;
      if (i + 1 < sortedLogs.length && sortedLogs[i + 1].event === 'exit') {
        endTime = sortedLogs[i + 1].exitedAt ? sortedLogs[i + 1].exitedAt.toDate() : null;
        i++; // Skip exit event
      }
      let durationStr = '';
      if (startTime && endTime) {
        const durationMs = endTime - startTime;
        const durationMinutes = Math.round(durationMs / 60000);
        durationStr = `${durationMinutes} minutes`;
      }
      sessions.push({
        session: `${log.status || 'N/A'} from ${startTime ? startTime.toLocaleString() : 'unknown'} to ${endTime ? endTime.toLocaleString() : 'ongoing'}${durationStr ? ` (${durationStr})` : ''}`
      });
    }
  }
  return sessions;
};

// Modal to show detailed activity logs for a specific user
const UserDetailsModal = ({ user, logs, onClose }) => {
  // ... (UserDetailsModal remains unchanged)
  const [filters, setFilters] = useState({
    timestamp: 'all',
    event: 'all',
    room: 'all',
    status: 'all',
    ip: 'all',
    additional: ''
  });
  const [simplifiedView, setSimplifiedView] = useState(false);

  const timestampOptions = [
    { value: 'all', label: 'All' },
    { value: 'lastHour', label: 'Last Hour' },
    { value: 'today', label: 'Today' },
    { value: 'thisWeek', label: 'This Week' },
    { value: 'thisMonth', label: 'This Month' }
  ];

  const [selectedUser, setSelectedUser] = useState(null);
  const uniqueEvents = useMemo(() => {
    const events = logs.map(log => log.event).filter(Boolean);
    return ['all', ...Array.from(new Set(events))];
  }, [logs]);

  const uniqueRooms = useMemo(() => {
    const rooms = logs.map(log => log.roomName).filter(Boolean);
    return ['all', ...Array.from(new Set(rooms))];
  }, [logs]);

  const uniqueStatuses = useMemo(() => {
    const statuses = logs.map(log => log.status).filter(Boolean);
    return ['all', ...Array.from(new Set(statuses))];
  }, [logs]);

  const uniqueIPs = useMemo(() => {
    const ips = logs.map(log => log.ipAddress).filter(Boolean);
    return ['all', ...Array.from(new Set(ips))];
  }, [logs]);

  const handleFilterChange = (e, field) => {
    setFilters({ ...filters, [field]: e.target.value });
  };

  const clearFilters = () => {
    setFilters({
      timestamp: 'all',
      event: 'all',
      room: 'all',
      status: 'all',
      ip: 'all',
      additional: ''
    });
  };

  const filteredLogs = logs.filter(log => {
    const logDate = log.createdAt
      ? log.createdAt.toDate()
      : log.joinedAt
        ? log.joinedAt.toDate()
        : log.exitedAt
          ? log.exitedAt.toDate()
          : null;
    if (!logDate) return false;
    const now = new Date();
    if (filters.timestamp !== 'all') {
      if (filters.timestamp === 'lastHour' && now - logDate > 3600 * 1000) return false;
      if (filters.timestamp === 'today' && logDate.toDateString() !== now.toDateString()) return false;
      if (filters.timestamp === 'thisWeek') {
        const weekAgo = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
        if (logDate < weekAgo) return false;
      }
      if (filters.timestamp === 'thisMonth') {
        const monthAgo = new Date(now.getTime() - 30 * 24 * 3600 * 1000);
        if (logDate < monthAgo) return false;
      }
    }
    if (filters.event !== 'all' && (log.event || '').toLowerCase() !== filters.event.toLowerCase()) return false;
    if (filters.room !== 'all' && (log.roomName || '').toLowerCase() !== filters.room.toLowerCase()) return false;
    if (filters.status !== 'all' && (log.status || '').toLowerCase() !== filters.status.toLowerCase()) return false;
    if (filters.ip !== 'all' && (log.ipAddress || '').toLowerCase() !== filters.ip.toLowerCase()) return false;
    
    let additionalInfo = '';
    if (log.event === 'join') {
      additionalInfo = `Joined at ${log.joinedAt ? log.joinedAt.toDate().toLocaleString() : ''}`;
    } else if (log.event === 'exit') {
      additionalInfo = `Exited at ${log.exitedAt ? log.exitedAt.toDate().toLocaleString() : ''}`;
    }
    if (filters.additional && !additionalInfo.toLowerCase().includes(filters.additional.toLowerCase())) return false;
    return true;
  });

  // For the simplified view in the modal, we can still use computeSessionsForUser.
  const sessions = useMemo(() => computeSessionsForUser(filteredLogs), [filteredLogs]);

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="User Details"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          maxHeight: '80vh',
          overflowY: 'auto'
        }
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>User Activity – {user}</h2>
        <button onClick={onClose}>Close</button>
      </div>
      <div style={{ marginBottom: '1rem', textAlign: 'right' }}>
        <button onClick={clearFilters} style={{ marginRight: '1rem' }}>Clear Filters</button>
        <button onClick={() => setSimplifiedView(!simplifiedView)}>
          {simplifiedView ? 'Detailed View' : 'Simplify View'}
        </button>
      </div>
      {simplifiedView ? (
        filteredLogs.length === 0 ? (
          <p>No sessions to display.</p>
        ) : (
          <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '0.9rem' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Session Summary</th>
              </tr>
            </thead>
            <tbody>
              {sessions.map((s, idx) => (
                <tr key={idx}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {s.session}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      ) : (
        filteredLogs.length === 0 ? (
          <p>No activity found for this user with the current filters. Click "Clear Filters" to view all logs.</p>
        ) : (
          <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '0.9rem' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Timestamp
                  <select
                    value={filters.timestamp}
                    onChange={(e) => handleFilterChange(e, 'timestamp')}
                    style={{ width: '100%' }}
                  >
                    {timestampOptions.map(opt => (
                      <option key={opt.value} value={opt.value}>{opt.label}</option>
                    ))}
                  </select>
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Event
                  <select
                    value={filters.event}
                    onChange={(e) => handleFilterChange(e, 'event')}
                    style={{ width: '100%' }}
                  >
                    {uniqueEvents.map(ev => (
                      <option key={ev} value={ev}>{ev}</option>
                    ))}
                  </select>
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                  User
                  <select
                    value={filters.user}
                    onChange={(e) => handleFilterChange(e, 'user')}
                    style={{ width: '100%' }}
                  >
                    {['all', ...Array.from(new Set(logs.map(log => log.userName).filter(Boolean)))].map(u => (
                      <option key={u} value={u}>{u}</option>
                    ))}
                  </select>
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Room
                  <select
                    value={filters.room}
                    onChange={(e) => handleFilterChange(e, 'room')}
                    style={{ width: '100%' }}
                  >
                    {uniqueRooms.map(r => (
                      <option key={r} value={r}>{r}</option>
                    ))}
                  </select>
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Status
                  <select
                    value={filters.status}
                    onChange={(e) => handleFilterChange(e, 'status')}
                    style={{ width: '100%' }}
                  >
                    {uniqueStatuses.map(s => (
                      <option key={s} value={s}>{s}</option>
                    ))}
                  </select>
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                  IP Address
                  <select
                    value={filters.ip}
                    onChange={(e) => handleFilterChange(e, 'ip')}
                    style={{ width: '100%' }}
                  >
                    {uniqueIPs.map(ip => (
                      <option key={ip} value={ip}>{ip}</option>
                    ))}
                  </select>
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Additional Info
                  <input
                    type="text"
                    value={filters.additional}
                    onChange={(e) => handleFilterChange(e, 'additional')}
                    placeholder="Filter"
                    style={{ width: '100%' }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredLogs.map(log => (
                <tr key={log.id}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {log.createdAt 
                      ? log.createdAt.toDate().toLocaleString() 
                      : log.joinedAt 
                        ? log.joinedAt.toDate().toLocaleString() 
                        : log.exitedAt 
                          ? log.exitedAt.toDate().toLocaleString() 
                          : ''}
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.event}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', display: 'flex', alignItems: 'center' }}>
                    {log.userName || 'N/A'}
                    <i
                      className="fas fa-eye"
                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                      onClick={() => setSelectedUser(log.userName)}
                      title="View user details"
                    ></i>
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.roomName || 'N/A'}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.status || 'N/A'}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {log.ipAddress ? <IPDetails ip={log.ipAddress} /> : 'N/A'}
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {log.event === 'join'
                      ? `Joined at ${log.joinedAt ? log.joinedAt.toDate().toLocaleString() : ''}`
                      : (log.event === 'exit'
                        ? `Exited at ${log.exitedAt ? log.exitedAt.toDate().toLocaleString() : ''}`
                        : '')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      )}
    </Modal>
  );
};

const VirtualSpaceReport = ({ spaceId, onClose }) => {
  const [logs, setLogs] = useState([]);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    timestamp: 'all',
    event: 'all',
    user: 'all',
    room: 'all',
    status: 'all',
    ip: 'all',
    additional: ''
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [simplifiedView, setSimplifiedView] = useState(false);

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        setLoading(true);
        const spaceDocRef = doc(db, 'virtual-offices', spaceId);
        const spaceDocSnap = await getDoc(spaceDocRef);
        if (spaceDocSnap.exists()) {
          setSpaceDetails(spaceDocSnap.data());
        } else {
          toast.error('Virtual space not found');
        }
        const logsSnapshot = await getDocs(collection(db, 'virtual-offices', spaceId, 'activityLogs'));
        const logsData = logsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() })); // Now including all events (join, status changes, exit)
        logsData.sort((a, b) => {
          const aTime = a.createdAt ? a.createdAt.toDate() : (a.joinedAt ? a.joinedAt.toDate() : new Date(0));
          const bTime = b.createdAt ? b.createdAt.toDate() : (b.joinedAt ? b.joinedAt.toDate() : new Date(0));
          return bTime - aTime;
        });
        setLogs(logsData);
      } catch (error) {
        console.error("Error fetching report data", error);
        toast.error("Error fetching report data");
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, [spaceId]);

  const uniqueEvents = useMemo(() => {
    const events = logs.map(log => log.event).filter(Boolean);
    return ['all', ...Array.from(new Set(events))];
  }, [logs]);

  const uniqueUsers = useMemo(() => {
    const users = logs.map(log => log.userName).filter(Boolean);
    return ['all', ...Array.from(new Set(users))];
  }, [logs]);

  const uniqueRooms = useMemo(() => {
    const rooms = logs.map(log => log.roomName).filter(Boolean);
    return ['all', ...Array.from(new Set(rooms))];
  }, [logs]);

  const uniqueStatuses = useMemo(() => {
    const statuses = logs.map(log => log.status).filter(Boolean);
    return ['all', ...Array.from(new Set(statuses))];
  }, [logs]);

  const uniqueIPs = useMemo(() => {
    const ips = logs.map(log => log.ipAddress).filter(Boolean);
    return ['all', ...Array.from(new Set(ips))];
  }, [logs]);

  const timestampOptions = [
    { value: 'all', label: 'All' },
    { value: 'lastHour', label: 'Last Hour' },
    { value: 'today', label: 'Today' },
    { value: 'thisWeek', label: 'This Week' },
    { value: 'thisMonth', label: 'This Month' }
  ];

  const handleFilterChange = (e, field) => {
    setFilters({ ...filters, [field]: e.target.value });
  };

  const clearFilters = () => {
    setFilters({
      timestamp: 'all',
      event: 'all',
      user: 'all',
      room: 'all',
      status: 'all',
      ip: 'all',
      additional: ''
    });
  };

  const filteredLogs = logs.filter(log => {
    const logDate = log.createdAt 
      ? log.createdAt.toDate() 
      : log.joinedAt 
        ? log.joinedAt.toDate() 
        : log.exitedAt 
          ? log.exitedAt.toDate() 
          : null;
    if (!logDate) return false;
    const now = new Date();
    if (filters.timestamp !== 'all') {
      if (filters.timestamp === 'lastHour' && now - logDate > 3600 * 1000) return false;
      if (filters.timestamp === 'today' && logDate.toDateString() !== now.toDateString()) return false;
      if (filters.timestamp === 'thisWeek') {
        const weekAgo = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
        if (logDate < weekAgo) return false;
      }
      if (filters.timestamp === 'thisMonth') {
        const monthAgo = new Date(now.getTime() - 30 * 24 * 3600 * 1000);
        if (logDate < monthAgo) return false;
      }
    }
    if (filters.event !== 'all' && (log.event || '').toLowerCase() !== filters.event.toLowerCase()) return false;
    if (filters.user !== 'all' && (log.userName || '').toLowerCase() !== filters.user.toLowerCase()) return false;
    if (filters.room !== 'all' && (log.roomName || '').toLowerCase() !== filters.room.toLowerCase()) return false;
    if (filters.status !== 'all' && (log.status || '').toLowerCase() !== filters.status.toLowerCase()) return false;
    if (filters.ip !== 'all' && (log.ipAddress || '').toLowerCase() !== filters.ip.toLowerCase()) return false;
    
    let additionalInfo = '';
    if (log.event === 'join') {
      additionalInfo = `Joined at ${log.joinedAt ? log.joinedAt.toDate().toLocaleString() : ''}`;
    } else if (log.event === 'exit') {
      additionalInfo = `Exited at ${log.exitedAt ? log.exitedAt.toDate().toLocaleString() : ''}`;
    }
    if (filters.additional && !additionalInfo.toLowerCase().includes(filters.additional.toLowerCase())) return false;
    return true;
  });

  // Use the new helper to create session records for the simplified clock in/clock out table.
  const sessionRecords = useMemo(() => computeSessionRecords(filteredLogs), [filteredLogs]);

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Virtual Space Report"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '90vh',
          overflowY: 'auto'
        }
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>Virtual Space Report</h2>
        <button onClick={onClose}>Close</button>
      </div>
      {loading ? (
        <div className="loader-container">
          <DotLoader size={60} color={'#ce2b7c'} />
        </div>
      ) : (
        <div>
          {spaceDetails && (
            <div style={{ marginBottom: '1rem' }}>
              <h3>{spaceDetails.officeName}</h3>
              <p>{spaceDetails.description}</p>
              <h4>Table Details</h4>
              {spaceDetails.units && spaceDetails.units.map((unit, index) => (
                <div key={index} style={{ marginBottom: '1rem' }}>
                  <h5>Unit: {unit.unitName}</h5>
                  {unit.tables && unit.tables.length > 0 ? (
                    <ul>
                      {unit.tables.map((table, idx) => (
                        <li key={idx}>
                          <strong>{table.tableName}</strong> – Chairs: {table.chairs} {table.tableLogo && '(Logo available)'}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No tables added.</p>
                  )}
                </div>
              ))}
            </div>
          )}
          <hr />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Activity Logs</h4>
            <div>
              <button onClick={clearFilters} style={{ marginRight: '1rem' }}>Clear Filters</button>
              <button onClick={() => setSimplifiedView(!simplifiedView)}>
                {simplifiedView ? 'Detailed View' : 'Simplify View'}
              </button>
            </div>
          </div>
          {simplifiedView ? (
            sessionRecords.length === 0 ? (
              <p>No logs match the current filters. Click "Clear Filters" to view all logs.</p>
            ) : (
              // NEW SIMPLIFIED CLOCK IN/OUT TABLE
              <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '0.9rem' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>User</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Room</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Enter</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Status</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Exit</th>
                  </tr>
                </thead>
                <tbody>
                  {sessionRecords.map((record, idx) => (
                    <tr key={idx}>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{record.user}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{record.room}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{record.date}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{record.enter}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{record.status}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{record.exit}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          ) : (
            // Detailed view remains as before
            filteredLogs.length === 0 ? (
              <p>No logs match the current filters. Click "Clear Filters" to view all logs.</p>
            ) : (
              <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '0.9rem' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                      Timestamp
                      <select value={filters.timestamp} onChange={(e) => handleFilterChange(e, 'timestamp')} style={{ width: '100%' }}>
                        {timestampOptions.map(opt => (
                          <option key={opt.value} value={opt.value}>{opt.label}</option>
                        ))}
                      </select>
                    </th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                      Event
                      <select value={filters.event} onChange={(e) => handleFilterChange(e, 'event')} style={{ width: '100%' }}>
                        {uniqueEvents.map(ev => (
                          <option key={ev} value={ev}>{ev}</option>
                        ))}
                      </select>
                    </th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                      User
                      <select value={filters.user} onChange={(e) => handleFilterChange(e, 'user')} style={{ width: '100%' }}>
                        {uniqueUsers.map(u => (
                          <option key={u} value={u}>{u}</option>
                        ))}
                      </select>
                    </th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                      Room
                      <select value={filters.room} onChange={(e) => handleFilterChange(e, 'room')} style={{ width: '100%' }}>
                        {uniqueRooms.map(r => (
                          <option key={r} value={r}>{r}</option>
                        ))}
                      </select>
                    </th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                      Status
                      <select value={filters.status} onChange={(e) => handleFilterChange(e, 'status')} style={{ width: '100%' }}>
                        {uniqueStatuses.map(s => (
                          <option key={s} value={s}>{s}</option>
                        ))}
                      </select>
                    </th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                      IP Address
                      <select value={filters.ip} onChange={(e) => handleFilterChange(e, 'ip')} style={{ width: '100%' }}>
                        {uniqueIPs.map(ip => (
                          <option key={ip} value={ip}>{ip}</option>
                        ))}
                      </select>
                    </th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                      Additional Info
                      <input
                        type="text"
                        value={filters.additional}
                        onChange={(e) => handleFilterChange(e, 'additional')}
                        placeholder="Filter"
                        style={{ width: '100%' }}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredLogs.map(log => (
                    <tr key={log.id}>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                        {log.createdAt 
                          ? log.createdAt.toDate().toLocaleString() 
                          : log.joinedAt 
                            ? log.joinedAt.toDate().toLocaleString() 
                            : log.exitedAt 
                              ? log.exitedAt.toDate().toLocaleString() 
                              : ''}
                      </td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.event}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px', display: 'flex', alignItems: 'center' }}>
                        {log.userName || 'N/A'}
                        <i
                          className="fas fa-eye"
                          style={{ cursor: 'pointer', marginLeft: '5px' }}
                          onClick={() => setSelectedUser(log.userName)}
                          title="View user details"
                        ></i>
                      </td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.roomName || 'N/A'}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.status || 'N/A'}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                        {log.ipAddress ? <IPDetails ip={log.ipAddress} /> : 'N/A'}
                      </td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                        {log.event === 'join'
                          ? `Joined at ${log.joinedAt ? log.joinedAt.toDate().toLocaleString() : ''}`
                          : (log.event === 'exit'
                            ? `Exited at ${log.exitedAt ? log.exitedAt.toDate().toLocaleString() : ''}`
                            : '')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          )}
        </div>
      )}
      {selectedUser && (
        <UserDetailsModal
          user={selectedUser}
          logs={logs.filter(log => (log.userName || '').toLowerCase() === selectedUser.toLowerCase())}
          onClose={() => setSelectedUser(null)}
        />
      )}
    </Modal>
  );
};

export default VirtualSpaceReport;
