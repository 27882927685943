import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import signUpimg from '../images/resource/sign-up.jpg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { auth, db } from '../firebase'; // Ensure db is exported from your firebase config
import { doc, setDoc } from 'firebase/firestore'; // Import Firestore functions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader, PulseLoader } from 'react-spinners'; // Import the spinner
import logo from '../dashboardstyle/images/gradient logo.png'

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const validate = () => {
    const errors = {};
    if (!formData.firstname) errors.firstname = "First name is required";
    if (!formData.lastname) errors.lastname = "Last name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.password) errors.password = "Password is required";
    if (formData.password !== formData.confirmPassword) errors.confirmPassword = "Passwords do not match";
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Set loading to true
      try {
        // Create user
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        // Update profile display name
        await updateProfile(userCredential.user, { displayName: `${formData.firstname} ${formData.lastname}` });
        // Write additional user data to Firestore
        await setDoc(doc(db, "users", userCredential.user.uid), {
          firstName: formData.firstname,
          lastName: formData.lastname,
          email: formData.email,
          createdAt: new Date()
        });
        // Send email verification
        await sendEmailVerification(userCredential.user);
        toast.success("Verification email sent. Please check your inbox.");
        navigate('/verify');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false); // Set loading to false after action completes
      }
    } else {
      Object.values(validationErrors).forEach(error => toast.error(error));
    }
  };

  return (
    <div className='back-holderz'>
      <div className="fix-wrapper login-auth-screens">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card mb-0 h-auto">
                <div className="card-body">
                  <div className="text-center mb-3">
                    <a><img className="logo-auth" src={logo} alt="" width='50' /></a>
                  </div>
                  <h5 className="text-center mb-4">Create an account!</h5>
                  <div className="text-center ms-2">
                    <Link to="/login">Already have an account? Sign in</Link>
                  </div>
                  <br />
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-4">
                      <input 
                        type="text" 
                        name="firstname"
                        className="form-control" 
                        placeholder="First Name"
                        value={formData.firstname}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="form-group mb-4">
                      <input 
                        type="text" 
                        name="lastname"
                        className="form-control" 
                        placeholder="Last Name"
                        value={formData.lastname}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="form-group mb-4">
                      <input 
                        type="text" 
                        name="email"
                        className="form-control" 
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="mb-sm-4 mb-3 position-relative password-field">
                      <input 
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Create Password"
                        value={formData.password}
                        className="form-control"
                        onChange={handleChange}
                        required
                      />
                      <span className="show-passz eye">
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </span>
                    </div>

                    <div className="mb-sm-4 mb-3 position-relative password-field">
                      <input 
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        className="form-control"
                        onChange={handleChange}
                        required
                      />
                      <span className="show-passz eye">
                        <FontAwesomeIcon
                          icon={showConfirmPassword ? faEye : faEyeSlash}
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      </span>
                    </div>

                    <div className="form-row d-flex flex-wrap justify-content-between mb-2">
                      {/* Additional elements if needed */}
                    </div>
                    <div className="text-center">
                      <button className="btn btn-primary btn-block">
                        {loading ? <PulseLoader size={10} color="#fff" /> : "Sign Up"}
                      </button>
                    </div>
                  </form>
                  <br />
                  <div className='loginform-terms'>
                    <small>By continuing, you agree to the <a href=''>Privacy Policy</a> and the <a href=''>Terms of Use</a> </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
};

export default SignUp;
