import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { CircleLoader } from 'react-spinners';

const EventsAttend = () => {
  const [virtualOffices, setVirtualOffices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const [activeTab, setActiveTab] = useState('virtual-spaces'); // new state for tabs

  useEffect(() => {
    const fetchVirtualOffices = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          setUserEmail(user.email);
          // Query Firestore to get all virtual offices where the user is invited
          const q = query(
            collection(db, 'virtual-offices'),
            where('participants', 'array-contains', user.email)
          );
          const querySnapshot = await getDocs(q);
          const officeList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setVirtualOffices(officeList);
        }
      } catch (error) {
        console.error('Error fetching virtual offices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVirtualOffices();
  }, []);

  if (loading) {
    return (
      <div className="loader-containerz">
        <CircleLoader size={50} color={"#ce2b7c"} loading={loading} />
      </div>
    );
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'virtual-spaces':
        return virtualOffices.length === 0 ? (
          <div className="card project-card">
            <div className="card-body py-3 px-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-xl-12 col-md-12 col-sm-12 align-items-center customers">
                  <div className="media-body text-center">
                    <h6 className="text-black">No virtual offices available</h6>
                    <p>You have not been invited to any virtual offices yet.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          virtualOffices.map((office) => (
            <div className="card project-card" key={office.id}>
              <div className="card-body py-3 px-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-3 col-md-4 col-sm-12 align-items-center customers">
                    <div className="media-body">
                      <p className="text-primary mb-0">#ID-{office.id}</p>
                      <h5 className="text-black" style={{ fontWeight: 'bold' }}>{office.officeName}</h5>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 col-sm-6 mt-3 mt-xl-0">
                    <div className="d-flex project-image">
                      <div>
                        <p className="mb-0">Created:</p>
                        <h6 className="mb-0">
                          <i className="fas fa-calendar me-3"></i>
                          {new Date(office.createdAt.seconds * 1000).toLocaleDateString()}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-sm-6 col-sm-4 mt-xl-0 mt-3">
                    <div className="d-flex justify-content-sm-end project-btn">
                      <a
                        href={`virtual-office-space/${office.id}`}
                        className="badge badge-primary light badge-md"
                      >
                        Access Virtual Space
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        );
      case 'virtual-event':
        return (
          <div className="card project-card">
            <div className="card-body  text-center">
              <h6 className="text-black">No virtual events available</h6>
            </div>
          </div>
        );
      case 'hybrid-event':
        return (
          <div className="card project-card">
            <div className="card-body  text-center">
              <h6 className="text-black">No hybrid events available</h6>
            </div>
          </div>
        );
      case 'inperson-event':
        return (
          <div className="card project-card">
             <div className="card-body  text-center">
              <h6 className="text-black">No in-person events available</h6>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="content-bodyx default-height">
        <div className="container-fluid">
          <div className="project-page d-flex justify-content-between align-items-center flex-wrap">
            <div className="card-tabs mb-4">
              <ul className="nav nav-tabs style-1" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${activeTab === 'virtual-spaces' ? 'active' : ''}`}
                    onClick={() => setActiveTab('virtual-spaces')}
                    role="tab"
                  >
                    Virtual Spaces
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${activeTab === 'virtual-event' ? 'active' : ''}`}
                    onClick={() => setActiveTab('virtual-event')}
                    role="tab"
                  >
                    Virtual Event
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${activeTab === 'hybrid-event' ? 'active' : ''}`}
                    onClick={() => setActiveTab('hybrid-event')}
                    role="tab"
                  >
                    Hybrid Event
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${activeTab === 'inperson-event' ? 'active' : ''}`}
                    onClick={() => setActiveTab('inperson-event')}
                    role="tab"
                  >
                    In-person Event
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              {renderContent()}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="mb-sm-0 mb-3">
              <p className="mb-0 text-black">
                Showing {virtualOffices.length} of {virtualOffices.length} Data
              </p>
            </div>
            <nav>
              <ul className="pagination pagination-circle">
                <li className="page-item page-indicator">
                  <a className="page-link" href="javascript:void(0)">
                    <i className="fas fa-angle-left"></i>
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="javascript:void(0)">
                    1
                  </a>
                </li>
                <li className="page-item page-indicator">
                  <a className="page-link" href="javascript:void(0)">
                    <i className="fas fa-angle-right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsAttend;
