import React from "react";
import StaticHeader from "../components/StaticHeader";

import bannerImg from "../images/35Img.jpg";

import imgS1 from "../../src/dashboardstyle/images/speaker-3-1.jpg"
import imgS2 from "../../src/dashboardstyle/images/speaker-3-2.jpg"
import imgS3 from "../../src/dashboardstyle/images/speaker-3-3.jpg"
import imgS4 from "../../src/dashboardstyle/images/speaker-3-4.jpg"
import imgS5 from "../../src/dashboardstyle/images/speaker-3-5.jpg"
import imgS6 from "../../src/dashboardstyle/images/speaker-3-6.jpg"

const Community = () => {
  return (
    <div>
      <StaticHeader />

      <div className="listing">
        <section
          class="parallax-section single-par"
          data-scrollax-parent="true"
        >
          <div
            class="bg par-elem "
            style={{
              transform: "translateZ(0px), translateY(-3.77358%",
              backgroundImage: `url(${bannerImg})`,
            }}
          ></div>
          <div class="overlay op7"></div>
          <div class="container">
            <div class="section-title center-align big-title">
              <h2>
                <span>People</span>
              </h2>
              <span class="section-separator"></span>
            </div>
          </div>
          <div class="header-sec-link">
            <a href="#sec1" class="custom-scroll-link text-center">
              <i class="fas fa-angle-double-down"></i>
            </a>
          </div>
        </section>
      </div>

      <section class="gray-bg small-padding no-top-padding-sec" id="sec1">
        <div class="container">
          <div
            class="list-main-wrap-header fl-wrap   block_box no-vis-shadow no-bg-header fixed-listing-header"
            style={{ zIndex: 1000 }}
          >
            <div class="list-main-wrap-title">
              <h2>Search People</h2>
            </div>

            <div class="list-main-wrap-opt">
              <div class="price-opt">
                <span class="price-opt-title">Sort by:</span>
                <div class="listsearch-input-item">
                  <select
                    data-placeholder="Popularity"
                    class="chosen-select no-search-select"
                  >
                    <option>Popularity</option>
                    <option>Average rating</option>
                  </select>
                </div>
              </div>
            </div>

            <a class="custom-scroll-link back-to-filters clbtg" href="#lisfw">
              <i class="fas fa-search"></i>
            </a>
          </div>
          <div></div>

          <div class="fl-wrap">
            <div
              class="listsearch-input-wrap lws_mobile fl-wrap tabs-act inline-lsiw vishidelem"
              id="lisfw"
            >
              <div class="tabs-container fl-wrap">
                <div class="tab">
                  <div id="filters-search" class="tab-content  first-tab ">
                    <div class="fl-wrap">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="listsearch-input-item custom-wrapper">
                            <select
                              data-placeholder="Categories"
                              class="chosen-select no-search-select"
                            >
                              <option>All Categories</option>
                              <option>Shops</option>
                              <option>Hotels</option>
                              <option>Restaurants</option>
                              <option>Fitness</option>
                              <option>Events</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="listsearch-input-item">
                            <span class="iconn-dec">
                              <i class="far fa-bookmark"></i>
                            </span>
                            <input
                              type="text"
                              placeholder="Who are you looking for ?"
                              value=""
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="listsearch-input-item location autocomplete-container">
                            <span class="iconn-dec">
                              <i class="fas fa-map-marker"></i>
                            </span>
                            <input
                              type="text"
                              placeholder="Where to look?"
                              class="autocomplete-input pac-target-input"
                              id="autocompleteid3"
                              value=""
                              autocomplete="off"
                            />
                            <a href="/people-details">
                              <i class="fas fa-location"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="speakers-section-four" >
        <div class="auto-container">
          <div class="row">
            <div
              class="speaker-block-four col-lg-4 col-md-6 col-sm-12 wow zoomIn animated"
              
            >
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image-box">
                    <img src={imgS1} alt="" />
                  </figure>
                </div>
                <div class="info-box">
                  <div class="inner">
                    <h5 class="name">
                      <a href="/people-details">Harman Kardon</a>
                    </h5>
                    <span class="designation">Founder of ChatGPT</span>
                    <ul class="social-links">
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-x-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="speaker-block-four col-lg-4 col-md-6 col-sm-12 wow zoomIn animated"
              
            >
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image-box">
                    <img src={imgS2} alt="" />
                  </figure>
                </div>
                <div class="info-box">
                  <div class="inner">
                    <h5 class="name">
                      <a href="/people-details">Elizabeth</a>
                    </h5>
                    <span class="designation">Founder of ChatGPT</span>
                    <ul class="social-links">
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-x-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="speaker-block-four col-lg-4 col-md-6 col-sm-12 wow zoomIn animated"
          
            >
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image-box">
                    <img src={imgS3} alt="" />
                  </figure>
                </div>
                <div class="info-box">
                  <div class="inner">
                    <h5 class="name">
                      <a href="/people-details">Bernard Smith</a>
                    </h5>
                    <span class="designation">Founder of ChatGPT</span>
                    <ul class="social-links">
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-x-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="speaker-block-four col-lg-4 col-md-6 col-sm-12 wow zoomIn animated"
              
            >
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image-box">
                    <img src={imgS4} alt="" />
                  </figure>
                </div>
                <div class="info-box">
                  <div class="inner">
                    <h5 class="name">
                      <a href="/people-details">James Brown</a>
                    </h5>
                    <span class="designation">Founder of ChatGPT</span>
                    <ul class="social-links">
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-x-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="speaker-block-four col-lg-4 col-md-6 col-sm-12 wow zoomIn animated"
              
            >
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image-box">
                    <img src={imgS5} alt="" />
                  </figure>
                </div>
                <div class="info-box">
                  <div class="inner">
                    <h5 class="name">
                      <a href="/people-details">Mike Newer</a>
                    </h5>
                    <span class="designation">Founder of ChatGPT</span>
                    <ul class="social-links">
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-x-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="speaker-block-four col-lg-4 col-md-6 col-sm-12 wow zoomIn animated"
              
            >
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image-box">
                    <img src={imgS6} alt="" />
                  </figure>
                </div>
                <div class="info-box">
                  <div class="inner">
                    <h5 class="name">
                      <a href="/people-details">Antonio Hamilton</a>
                    </h5>
                    <span class="designation">Founder of ChatGPT</span>
                    <ul class="social-links">
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-x-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/people-details">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Community;
