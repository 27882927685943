import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const pinStyle = {
  cursor: "pointer",
  color: "#fff",
  fontSize: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  background: "linear-gradient(to bottom, rgba(242, 4, 135, 1) 0%, rgba(162, 0, 89, 1) 100%)",

  borderRadius: "50%",
  width: "40px",
  height: "40px",
  boxShadow: "0px 0px 5px rgba(0,0,0,0.3)"
};



const Pin = ({ logoUrl }) => {
  return (
    <div style={pinStyle}>
      <FontAwesomeIcon icon={faMapMarkerAlt} />
    </div>
  );
};

export default Pin;
