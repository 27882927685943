import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { db, storage, auth } from '../../../firebase';
import { addDoc, collection, doc, getDoc, onSnapshot, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import Modal from 'react-modal';
import { DotLoader, FadeLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import Avatar from 'react-avatar';
import { Tooltip } from 'react-tooltip';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import EmojiPicker from 'emoji-picker-react';
import Cookies from "js-cookie";

import { Theme } from 'emoji-picker-react';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";

import imgDark from '../../../images/dark logo.png';
import EconnectMeetingModal from './EconnectMeetingModal';
import BackgroundStyleLight from '../../../components/BackgroundStyleLight';
import logo from '../../../images/Plain white.png';

import eLogo from '../../../dashboardstyle/images/gradient logo.png'

import bannerImg from '../../../dashboardstyle/images/banner.png'


import avatar4 from '../../../images/avatar-4.jpg'
import avatar1 from '../../../images/avatar-1.jpg'
import avatar3 from '../../../images/avatar-3.jpg'
import avatar6 from '../../../images/avatar-6.jpg'
import avatar2 from '../../../images/avatar-2.jpg'
import Swal from 'sweetalert2';









const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "40%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },
  overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }
};

const customStylesBio = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    background: 'var(--bs-body-bg)',
    border: 'none',
    bottom: "auto",
    width: "40%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },
  overlay: { backgroundColor: "rgba(0, 0, 0, 0.9)" }
};

const VirtualOfficeSpaceUnits = () => {
  const { spaceId } = useParams();
  const [currentUser, setCurrentUser] = useState(null);
  const [sessionLogRef, setSessionLogRef] = useState(null);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentUnit, setCurrentUnit] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isUserSeated, setIsUserSeated] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false);
  const [isInvited, setIsInvited] = useState(false);
  const [isCheckingInvitation, setIsCheckingInvitation] = useState(true);
  const [isJitsiModalOpen, setIsJitsiModalOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");


  const [uploadProgress, setUploadProgress] = useState(0);



  const [showUserPopup, setShowUserPopup] = useState(false);
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [status, setStatus] = useState("Available");

  const [showChat, setShowChat] = useState(false);

  const [activeTab, setActiveTab] = useState("chat"); 
  const [activeChat, setActiveChat] = useState({ type: "group", id: "everyone", name: "Everyone" });

  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [theme, setTheme] = useState("light");

  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  // Function to update theme based on cookie or body attribute
  const updateTheme = () => {
    const savedTheme = Cookies.get("theme-version") || "light";
    setTheme(savedTheme);
  };

  useEffect(() => {
    updateTheme(); // Set initial theme

    // Listen for changes when the theme switches
    const handleThemeChange = () => updateTheme();
    window.addEventListener("theme-change", handleThemeChange);

    return () => {
      window.removeEventListener("theme-change", handleThemeChange);
    };
  }, []);

  const handleEmojiClick = (emojiData, event) => {
    setMessageInput((prev) => prev + emojiData.emoji);
    // Optionally hide the picker after selection
    setShowEmojiPicker(false);
  };



  const chatEndRef = useRef(null);


  const lastStatusChangeTimeRef = useRef(Date.now());
  const previousStatusRef = useRef(status);

  const joinTimeRef = useRef(null);


  const initialLoadRef = useRef(true);

  const [onlineUsers, setOnlineUsers] = useState([]);


  useEffect(() => {
    if (!currentUser) return; // Exit if currentUser is null
  
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserDetails(userDocSnap.data());
        } else {
          toast.error("User document not found.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Error fetching user data.");
      }
    };
  
    fetchUserData();
  }, [currentUser]); // Use currentUser instead of currentUser.uid
  

  const getFileIcon = (fileType) => {
    if (!fileType) return "fas fa-file";
    if (fileType.startsWith("image/")) return "fas fa-image";
    if (fileType.startsWith("video/")) return "fas fa-video";
    if (fileType.includes("zip") || fileType.includes("compressed")) return "fas fa-file-archive";
    if (fileType === "application/pdf") return "fas fa-file-pdf";
    // Fallback icon:
    return "fas fa-file";
  };
  

  const toggleView = () => {
    setShowChat((prev) => !prev);
  };

  // useEffect(() => {
  //   if (spaceId) {
  //     const logsRef = collection(db, "virtual-offices", spaceId, "activityLogs");
  //     const unsubscribe = onSnapshot(logsRef, (snapshot) => {
  //       console.log("Realtime logs snapshot:", snapshot.docs.map(doc => doc.data()));
  //       snapshot.docChanges().forEach((change) => {
  //         console.log("Doc change detected:", change.type, change.doc.data());
  //         if (change.type === "added") {
  //           // Skip initial load logs
  //           if (initialLoadRef.current) return;
  //           const logData = change.doc.data();
  //           // Skip events generated by the current user
  //           if (logData.userId === auth.currentUser.uid) return;
  //           if (logData.event === "join") {
  //             toast.success(`${logData.userName} has joined the unit`);
  //           } else if (logData.event === "exit") {
  //             toast.info(`${logData.userName} has left the unit`);
  //           }
  //         }
  //       });
  //       // After the first snapshot, mark initial load as complete.
  //       initialLoadRef.current = false;
  //     });
  //     return () => unsubscribe();
  //   }
  // }, [spaceId]);

 // Online Users Query
 useEffect(() => {
  if (spaceId) {
    const q = query(
      collection(db, 'users'),
      where('online', '==', true),
      where('currentSpace', '==', spaceId)
    );
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOnlineUsers(users);
      },
      (error) => {
        console.error("Error fetching online users:", error);
      }
    );
    return () => unsubscribe();
  }
}, [spaceId]);
  
  


  useEffect(() => {
    if (spaceId && currentUnit) {
      const logsRef = collection(db, "virtual-offices", spaceId, "activityLogs");
      const unsubscribe = onSnapshot(logsRef, (snapshot) => {
        console.log("Realtime logs snapshot:", snapshot.docs.map(doc => doc.data()));
        snapshot.docChanges().forEach((change) => {
          console.log("Doc change detected:", change.type, change.doc.data());
          if (change.type === "added") {
            // Skip initial snapshot logs
            if (initialLoadRef.current) return;
            const logData = change.doc.data();
            // Only process logs for the current unit and not for events generated by the current user.
            if (logData.roomName !== currentUnit) return;
            if (logData.userId === auth.currentUser.uid) return;
            // For join events, only show if the event timestamp is later than when the current user joined.
            if (logData.event === "join") {
              if (logData.joinedAt && logData.joinedAt.toMillis() > joinTimeRef.current) {
                toast.success(`${logData.userName} has joined the unit`);
              }
            // } else if (logData.event === "exit") {
            //   toast.info(`${logData.userName} has left the unit`);
            // }
          } else if (logData.event === "exit") {
            if (logData.exitedAt && logData.exitedAt.toMillis() > joinTimeRef.current) {
              toast.info(`${logData.userName} has left the unit`);
            }
          }
          
          }
        });
        // Mark initial load complete after the first snapshot
        initialLoadRef.current = false;
      });
      return () => unsubscribe();
    }
  }, [spaceId, currentUnit]);

  // ----- Real-Time Chat Subscription (all messages in this space) -----
  useEffect(() => {
    if (spaceId) {
      const chatsRef = collection(db, "virtual-offices", spaceId, "chats");
      const unsubscribe = onSnapshot(chatsRef, (snapshot) => {
        const messages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        // Sort messages by timestamp (if available)
        messages.sort((a, b) => (a.timestamp?.toMillis() || 0) - (b.timestamp?.toMillis() || 0));
        setChatMessages(messages);
      });
      return () => unsubscribe();
    }
  }, [spaceId]);

  // Auto-scroll to the bottom when new messages arrive
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  const recentChats = useMemo(() => {
    if (!currentUser?.uid) return [];
    const conversations = {};
    const currentUserId = currentUser.uid;
    chatMessages.forEach(msg => {
      let key, type;
      if (!msg.recipientId) {
        key = "everyone";
        type = "group";
        const lastMsg = msg.fileUrl
          ? { type: "file", fileName: msg.fileName, fileType: msg.fileType }
          : msg.text;
        if (!conversations[key]) {
          conversations[key] = {
            type,
            id: key,
            name: "Everyone",
            lastMessage: lastMsg,
            lastTimestamp: msg.timestamp ? msg.timestamp.toMillis() : 0,
          };
        } else {
          const msgTime = msg.timestamp ? msg.timestamp.toMillis() : 0;
          if (msgTime > conversations[key].lastTimestamp) {
            conversations[key].lastTimestamp = msgTime;
            conversations[key].lastMessage = lastMsg;
          }
        }
      } else {
        let otherUserId, otherUserName = "";
        if (msg.senderId === currentUserId) {
          otherUserId = msg.recipientId;
        } else {
          otherUserId = msg.senderId;
          otherUserName = msg.senderName;
        }
        if (!otherUserName) {
          const otherUser = onlineUsers.find(u => u.id === otherUserId);
          if (otherUser) {
            otherUserName = `${otherUser.firstName} ${otherUser.lastName}`;
          }
        }
        key = otherUserId;
        type = "private";
        const lastMsg = msg.fileUrl
          ? { type: "file", fileName: msg.fileName, fileType: msg.fileType }
          : msg.text;
        if (!conversations[key]) {
          conversations[key] = {
            type,
            id: key,
            name: otherUserName || "Unknown",
            lastMessage: lastMsg,
            lastTimestamp: msg.timestamp ? msg.timestamp.toMillis() : 0,
          };
        } else {
          const msgTime = msg.timestamp ? msg.timestamp.toMillis() : 0;
          if (msgTime > conversations[key].lastTimestamp) {
            conversations[key].lastTimestamp = msgTime;
            conversations[key].lastMessage = lastMsg;
          }
        }
      }
    });
    const conversationArray = Object.values(conversations);
    conversationArray.sort((a, b) => b.lastTimestamp - a.lastTimestamp);
    return conversationArray;
  }, [chatMessages, onlineUsers, currentUser]);
  

  // const recentChats = useMemo(() => {
  //   const conversations = {};
  //   const currentUserId = auth.currentUser.uid;
  //   chatMessages.forEach(msg => {
  //     let key, type;
  //     if (!msg.recipientId) {
  //       // Group chat ("everyone")
  //       key = "everyone";
  //       type = "group";
  //       if (!conversations[key]) {
  //         conversations[key] = {
  //           type,
  //           id: key,
  //           name: "Everyone",
  //           lastMessage: msg.text,
  //           lastTimestamp: msg.timestamp ? msg.timestamp.toMillis() : 0
  //         };
  //       } else {
  //         const msgTime = msg.timestamp ? msg.timestamp.toMillis() : 0;
  //         if (msgTime > conversations[key].lastTimestamp) {
  //           conversations[key].lastTimestamp = msgTime;
  //           conversations[key].lastMessage = msg.text;
  //         }
  //       }
  //     } else {
  //       // Private message
  //       let otherUserId, otherUserName = "";
  //       if (msg.senderId === currentUserId) {
  //         otherUserId = msg.recipientId;
  //       } else {
  //         otherUserId = msg.senderId;
  //         otherUserName = msg.senderName;
  //       }
  //       // If the name is empty, try to find it in the onlineUsers array.
  //       if (!otherUserName) {
  //         const otherUser = onlineUsers.find(u => u.id === otherUserId);
  //         if (otherUser) {
  //           otherUserName = `${otherUser.firstName} ${otherUser.lastName}`;
  //         }
  //       }
  //       key = otherUserId;
  //       type = "private";
  //       if (!conversations[key]) {
  //         conversations[key] = {
  //           type,
  //           id: key,
  //           name: otherUserName || "Unknown",
  //           lastMessage: msg.text,
  //           lastTimestamp: msg.timestamp ? msg.timestamp.toMillis() : 0
  //         };
  //       } else {
  //         const msgTime = msg.timestamp ? msg.timestamp.toMillis() : 0;
  //         if (msgTime > conversations[key].lastTimestamp) {
  //           conversations[key].lastTimestamp = msgTime;
  //           conversations[key].lastMessage = msg.text;
  //         }
  //       }
  //     }
  //   });
  //   const conversationArray = Object.values(conversations);
  //   conversationArray.sort((a, b) => b.lastTimestamp - a.lastTimestamp);
  //   return conversationArray;
  // }, [chatMessages, onlineUsers]);
  

  // const recentChats = useMemo(() => {
  //   const conversations = {};
  //   const currentUserId = auth.currentUser.uid;
  //   chatMessages.forEach(msg => {
  //     let key, type;
  //     if (!msg.recipientId) {
  //       // Group chat ("everyone")
  //       key = "everyone";
  //       type = "group";
  //       if (!conversations[key]) {
  //         conversations[key] = {
  //           type,
  //           id: key,
  //           name: "Everyone",
  //           lastMessage: msg.text,
  //           lastTimestamp: msg.timestamp ? msg.timestamp.toMillis() : 0
  //         };
  //       } else {
  //         const msgTime = msg.timestamp ? msg.timestamp.toMillis() : 0;
  //         if (msgTime > conversations[key].lastTimestamp) {
  //           conversations[key].lastTimestamp = msgTime;
  //           conversations[key].lastMessage = msg.text;
  //         }
  //       }
  //     } else {
  //       // Private message
  //       let otherUserId, otherUserName = "";
  //       if (msg.senderId === currentUserId) {
  //         otherUserId = msg.recipientId;
  //       } else {
  //         otherUserId = msg.senderId;
  //         otherUserName = msg.senderName;
  //       }
  //       key = otherUserId;
  //       type = "private";
  //       if (!conversations[key]) {
  //         conversations[key] = {
  //           type,
  //           id: key,
  //           name: otherUserName || "Unknown",
  //           lastMessage: msg.text,
  //           lastTimestamp: msg.timestamp ? msg.timestamp.toMillis() : 0
  //         };
  //       } else {
  //         const msgTime = msg.timestamp ? msg.timestamp.toMillis() : 0;
  //         if (msgTime > conversations[key].lastTimestamp) {
  //           conversations[key].lastTimestamp = msgTime;
  //           conversations[key].lastMessage = msg.text;
  //         }
  //       }
  //     }
  //   });
  //   const conversationArray = Object.values(conversations);
  //   conversationArray.sort((a, b) => b.lastTimestamp - a.lastTimestamp);
  //   return conversationArray;
  // }, [chatMessages]);

  // ----- Compute Filtered Messages Based on Active Chat -----
  const filteredMessages = useMemo(() => {
    if (!currentUser?.uid) return [];
    return chatMessages.filter(msg => {
      if (activeChat.type === 'group') {
        // Group messages: recipientId is null or undefined
        return !msg.recipientId;
      } else {
        // Private messages: only those between the current user and the selected user
        return ((msg.senderId === auth.currentUser.uid && msg.recipientId === activeChat.id) ||
                (msg.senderId === activeChat.id && msg.recipientId === auth.currentUser.uid));
      }
    });
  }, [chatMessages, activeChat]);

  // ----- Send Chat Message -----
  const sendMessage = async () => {
    if (!messageInput.trim()) return;
    try {
      await addDoc(collection(db, "virtual-offices", spaceId, "chats"), {
        text: messageInput,
        senderId: auth.currentUser.uid,
        senderName: `${userDetails.firstName} ${userDetails.lastName}`,
        timestamp: serverTimestamp(),
        recipientId: activeChat.type === "private" ? activeChat.id : null,
      });
      setMessageInput('');
    } catch (error) {
      toast.error("Error sending message");
      console.error("Error sending message:", error);
    }
  };

  
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setUploading(true);
    try {
      // Create a unique file name and path
      const storageRef = ref(
        storage,
        `chat_files/${auth.currentUser.uid}/${Date.now()}_${file.name}`
      );
      // Start a resumable upload
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Calculate progress percentage
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
          toast.error("Error uploading file");
          setUploading(false);
        },
        async () => {
          // Upload completed successfully, get download URL
          const fileUrl = await getDownloadURL(uploadTask.snapshot.ref);
          // Send chat message with file details
          await addDoc(collection(db, "virtual-offices", spaceId, "chats"), {
            senderId: auth.currentUser.uid,
            senderName: `${userDetails.firstName} ${userDetails.lastName}`,
            timestamp: serverTimestamp(),
            fileUrl,
            fileName: file.name,
            fileType: file.type,
          });
          toast.success("File attached and sent!");
          setUploading(false);
          setUploadProgress(0);
          e.target.value = "";
        }
      );
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
      setUploading(false);
    }
  };
  

  // Function to trigger file input click
  const handleAttachFile = () => {
    console.log("Paperclip button clicked. fileInputRef:", fileInputRef.current);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  

  // In your chat messages rendering, update the message display:
  // For example, inside your chat conversation list:
  const renderMessageContent = (msg) => {
    if (msg.fileUrl) {
      // Render a file message block
      return (
        <div
          className="file-message"
          onClick={() => window.open(msg.fileUrl, "_blank")}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          <i className="fas fa-file me-1"></i> {msg.fileName}
        </div>
      );
    }
    return <p className="mb-0">{msg.text}</p>;
  };
 


  // Helper function to render a user's avatar
  const renderUserAvatar = (user) => {
    if (user.photoURL) {
      return <img src={user.photoURL} alt="user-img" className="img-fluid rounded-circle" />;
    } else {
      return <Avatar name={user.name} size="30" round />;
    }
  };

  // ----- Compute Room Users for Swiper -----
  // Here we derive the list of users from chat messages.
  // (Note: In a real app you might fetch an explicit list from Firestore.)



  // Check if the user is already seated
  useEffect(() => {
    const fetchUserSeatingStatus = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser || !spaceDetails) return;
        const userFirstName = userDetails.firstName || 'Unknown';
        const userLastName = userDetails.lastName || 'Unknown';

        const isUserCurrentlySeated = spaceDetails.units.some(unit =>
          unit.tables.some(table =>
            table.occupiedChairs?.some(
              chair => chair.name === userFirstName && chair.surname === userLastName
            )
          )
        );
        setIsUserSeated(isUserCurrentlySeated);
      } catch (error) {
        console.error("Error fetching user seating status: ", error);
      }
    };
    if (spaceDetails) fetchUserSeatingStatus();
  }, [spaceDetails, userDetails]);

  // Fetch space details
  useEffect(() => {
    const fetchSpaceDetails = async () => {
      try {
        setLoading(true);
        setIsCheckingInvitation(true);
        const user = auth.currentUser;
        if (!user) {

          Swal.fire({
            title: "Login!",
            text: "You need to be logged in to access this space.",
            icon: "error",
            confirmButtonText: "Login",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/login"; // Redirects to the login page
            }
          });
          

    


          setIsCheckingInvitation(false);
          return;
        }
        const spaceDoc = doc(db, 'virtual-offices', spaceId);
        const docSnap = await getDoc(spaceDoc);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.participants?.includes(user.email)) {
            setIsInvited(true);
          } else {
            setIsInvited(false);
            return;
          }
          const unitsWithLogos = await Promise.all(
            data.units.map(async (unit) => {
              const tablesWithLogos = await Promise.all(
                unit.tables.map(async (table) => {
                  if (table.tableLogo) {
                    const tableLogoUrl = await getDownloadURL(ref(storage, table.tableLogo));
                    return { ...table, tableLogoUrl };
                  }
                  return table;
                })
              );
              return { ...unit, tables: tablesWithLogos };
            })
          );
          setSpaceDetails({ ...data, units: unitsWithLogos });
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching space details:', error);
      } finally {
        setLoading(false);
        setIsCheckingInvitation(false);
      }
    };
    fetchSpaceDetails();
  }, [spaceId]);

  // Subscribe to realtime updates
  useEffect(() => {
    const spaceDocRef = doc(db, 'virtual-offices', spaceId);
    const unsubscribe = onSnapshot(spaceDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const updatedSpaceDetails = docSnapshot.data();
        setSpaceDetails(updatedSpaceDetails);
        const userFirstName = userDetails.firstName || 'Unknown';
        const userLastName = userDetails.lastName || 'Unknown';
        const isUserCurrentlySeated = updatedSpaceDetails.units.some(unit =>
          unit.tables.some(table =>
            table.occupiedChairs?.some(
              chair => chair.name === userFirstName && chair.surname === userLastName
            )
          )
        );
        setIsUserSeated(isUserCurrentlySeated);
      } else {
        console.log("Space document does not exist!");
      }
    });
    return () => unsubscribe();
  }, [spaceId, userDetails]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  // Adjust table size after render and on resize
  const adjustTableSize = () => {
    document.querySelectorAll('.table-area').forEach(tableArea => {
      const topChairs = tableArea.querySelector('.row-chairs:first-child')?.children.length || 0;
      const bottomChairs = tableArea.querySelector('.row-chairs:last-child')?.children.length || 0;
      const leftChairs = tableArea.querySelector('.vertical-chairs:first-child')?.children.length || 0;
      const rightChairs = tableArea.querySelector('.vertical-chairs:last-child')?.children.length || 0;
      const maxTopBottom = Math.max(topChairs, bottomChairs);
      const maxLeftRight = Math.max(leftChairs, rightChairs);
      let width = 60;
      let height = 60;
      if (topChairs === 4 && bottomChairs === 4) {
        width = 110;
      } else if (maxTopBottom === 3) {
        width = 90;
      } else if (maxTopBottom === 2) {
        width = 80;
      } else {
        width = 60;
      }
      if (maxLeftRight >= 6) {
        height = 160;
      } else if (maxLeftRight === 5) {
        height = 150;
      } else if (maxLeftRight === 4) {
        height = 120;
      } else {
        height = 60;
      }
      if (topChairs === bottomChairs && leftChairs === rightChairs) {
        width = height = Math.max(width, height);
      }
      if (maxLeftRight > maxTopBottom) {
        height = Math.max(height, width + 40);
      }
      const tableElement = tableArea.querySelector('.table-rect');
      if (tableElement) {
        tableElement.style.width = `${width}px`;
        tableElement.style.height = `${height}px`;
      }
    });
  };

  // Call adjustTableSize after rendering and on window resize
  useEffect(() => {
    adjustTableSize();
  }, [spaceDetails, currentUnit]);

  useEffect(() => {
    if (!showChat) {
      adjustTableSize();
    }
  }, [showChat]);

  useEffect(() => {
    window.addEventListener('resize', adjustTableSize);
    return () => window.removeEventListener('resize', adjustTableSize);
  }, []);

  // Calculate chair distribution (unchanged)
  const calculateChairDistribution = (totalChairs) => {
    const maxTopBottomChairs = 4;
    const maxLeftRightChairs = 6;
    const rows = { topRow: 0, bottomRow: 0, leftRow: 0, rightRow: 0 };
    if (totalChairs <= maxTopBottomChairs * 2 + maxLeftRightChairs * 2) {
      const perSide = Math.floor(totalChairs / 4);
      const remaining = totalChairs % 4;
      rows.topRow = Math.min(maxTopBottomChairs, perSide + (remaining > 0 ? 1 : 0));
      rows.bottomRow = Math.min(maxTopBottomChairs, perSide + (remaining > 1 ? 1 : 0));
      rows.leftRow = Math.min(maxLeftRightChairs, perSide + (remaining > 2 ? 1 : 0));
      rows.rightRow = Math.min(maxLeftRightChairs, perSide);
    }
    return rows;
  };

  // When a chair is clicked, either sit on it (if empty) or show details
  const renderChair = (occupiedChair, onSit) => {
    return (
      <div
        className="chair"
        onClick={() => {
          if (occupiedChair) {
            handleAvatarClick(occupiedChair);
          } else {
            onSit();
          }
        }}
      >
        {occupiedChair && (
          <Avatar
            name={`${occupiedChair.name} ${occupiedChair.surname}`}
            size="25"
       
          />
        )}
      </div>
    );
  };

 


  // const handleUnitClick = async (unitName) => {
  //   // Prevent joining if already in a unit
  //   if (currentUnit) {
  //     toast.warning(`You are already in unit: ${currentUnit}. Please exit before joining another unit.`);
  //     return;
  //   }
  //   // Ensure user details have been loaded
  //   if (!userDetails.firstName || !userDetails.lastName) {
  //     toast.error("User details are not loaded yet. Please wait a moment and try again.");
  //     return;
  //   }
  //   debugUserDetails();
  //   // Set the unit state
  //   setSelectedUnit(unitName);
  //   setCurrentUnit(unitName);
  //   // Get the user's IP address
  //   const ipAddress = await fetchUserIP();
  //   // Build the join log object (serverTimestamp will be set by the server)
  //   const joinLog = {
  //     event: "join",
  //     roomName: unitName,
  //     status: status,
  //     joinedAt: serverTimestamp(),
  //     ipAddress,
  //     userId: auth.currentUser.uid,
  //     userName: `${userDetails.firstName} ${userDetails.lastName}`
  //   };
  //   try {
  //     console.log("Attempting to write join log:", joinLog);
  //     // Write the join log to the user's activityLogs
  //     const userLogsRef = collection(db, "users", auth.currentUser.uid, "activityLogs");
  //     const newUserLogDoc = await addDoc(userLogsRef, joinLog);
  //     setSessionLogRef(newUserLogDoc);
  //     console.log("Join log written to user activityLogs with id:", newUserLogDoc.id);
  //     // Also write the join log to the virtual office's activityLogs
  //     const spaceLogsRef = collection(db, "virtual-offices", spaceId, "activityLogs");
  //     const newSpaceLogDoc = await addDoc(spaceLogsRef, joinLog);
  //     console.log("Join log written to virtual-offices activityLogs with id:", newSpaceLogDoc.id);
  //     // Record the current local join time so we ignore previous logs
  //     joinTimeRef.current = Date.now();
  //     // Note: We do not call toast.success here so that the current user does not see their own join toast.
  //     // Other users already in the unit will see the join event via the realtime subscription.
  //   } catch (error) {
  //     console.error("Error logging join activity", error);
  //     toast.error("Error logging join activity");
  //   }
  // };



  const handleUnitClick = async (unitName) => {
    if (currentUnit) {
      toast.warning(`You are already in unit: ${currentUnit}. Please exit before joining another unit.`);
      return;
    }
    if (!userDetails.firstName || !userDetails.lastName) {
      toast.error("User details are not loaded yet. Please wait a moment and try again.");
      return;
    }
    
    
    debugUserDetails();
    setSelectedUnit(unitName);
    setCurrentUnit(unitName);
    const ipAddress = await fetchUserIP();
    const joinLog = {
      event: "join",
      roomName: unitName,
      status,
      joinedAt: serverTimestamp(),
      ipAddress,
      userId: auth.currentUser.uid,
      userName: `${userDetails.firstName} ${userDetails.lastName}`
    };
    try {
      // Write join log under the user's and virtual office's activityLogs
      const userLogsRef = collection(db, "users", auth.currentUser.uid, "activityLogs");
      const newUserLogDoc = await addDoc(userLogsRef, joinLog);
      setSessionLogRef(newUserLogDoc);
      const spaceLogsRef = collection(db, "virtual-offices", spaceId, "activityLogs");
      const newSpaceLogDoc = await addDoc(spaceLogsRef, joinLog);
      console.log("Join logs written with ids:", newUserLogDoc.id, newSpaceLogDoc.id);
      joinTimeRef.current = Date.now();
      // Update the current user's document so that online: true and currentSpace is set to spaceId
      await updateDoc(doc(db, 'users', auth.currentUser.uid), {
        online: true,
        currentSpace: spaceId
      });
    } catch (error) {
      console.error("Error logging join activity", error);
      toast.error("Error logging join activity");
    }
  };



  const debugUserDetails = () => {
    console.log("User details:", userDetails);
  };
  


  



  const handleSitOnChair = async (unitName, tableIndex, chairIndex) => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === unitName);
    if (!unit) { toast.error("Unit not found."); return; }
    const table = unit.tables[tableIndex];
    if (!table) { toast.error("Table not found."); return; }
    if (!table.occupiedChairs) { table.occupiedChairs = []; }
    const userFirstName = userDetails.firstName || 'Unknown';
    const userLastName = userDetails.lastName || 'Unknown';
    const userBio = userDetails.aboutMe || 'Bio not provided';
    const isUserAlreadySeated = spaceDetails.units.some((unit) =>
      unit.tables.some((table) =>
        table.occupiedChairs?.some(
          (chair) => chair.name === userFirstName && chair.surname === userLastName
        )
      )
    );
    if (isUserAlreadySeated) {
      toast.warning('You are already sitting on a chair. Please exit the current chair before selecting a new one.');
      return;
    }
    const isChairOccupied = table.occupiedChairs.some(chair => chair.index === chairIndex);
    if (isChairOccupied) {
      toast.warning('This chair is already occupied.');
      return;
    }
    table.occupiedChairs.push({
      index: chairIndex,
      name: userFirstName,
      surname: userLastName,
      aboutMe: userBio
    });
    setSpaceDetails({ ...spaceDetails });
    setIsUserSeated(true);
    try {
      const sanitizedUnits = spaceDetails.units.map((unit) => ({
        ...unit,
        tables: unit.tables.map((table) => ({
          ...table,
          occupiedChairs: table.occupiedChairs || []
        }))
      }));
      await updateDoc(doc(db, 'virtual-offices', spaceId), { units: sanitizedUnits });
      toast.success('You have successfully sat on the chair.');
      setSelectedRoom(`${unitName}-meeting`);
      setIsJitsiModalOpen(true);
    } catch (error) {
      console.error('Error updating document: ', error);
      toast.error('Error sitting on the chair.');
    }
  };

  // const handleSitOnChair = async (unitName, tableIndex, chairIndex) => {
  //   const unit = spaceDetails.units.find((unit) => unit.unitName === unitName);
  //   if (!unit) { toast.error("Unit not found."); return; }
  //   const table = unit.tables[tableIndex];
  //   if (!table) { toast.error("Table not found."); return; }
  //   if (!table.occupiedChairs) { table.occupiedChairs = []; }
  //   const userFirstName = userDetails.firstName || 'Unknown';
  //   const userLastName = userDetails.lastName || 'Unknown';
  //   const userBio = userDetails.aboutMe || 'Bio not provided';
  //   const isUserAlreadySeated = spaceDetails.units.some((unit) =>
  //     unit.tables.some((table) =>
  //       table.occupiedChairs?.some(
  //         (chair) => chair.name === userFirstName && chair.surname === userLastName
  //       )
  //     )
  //   );
  //   if (isUserAlreadySeated) {
  //     toast.warning('You are already sitting on a chair. Please exit the current chair before selecting a new one.');
  //     return;
  //   }
  //   const isChairOccupied = table.occupiedChairs.some(chair => chair.index === chairIndex);
  //   if (isChairOccupied) {
  //     toast.warning('This chair is already occupied.');
  //     return;
  //   }
  //   table.occupiedChairs.push({
  //     index: chairIndex,
  //     name: userFirstName,
  //     surname: userLastName,
  //     aboutMe: userBio
  //   });
  //   setSpaceDetails({ ...spaceDetails });
  //   setIsUserSeated(true);
  //   try {
  //     const sanitizedUnits = spaceDetails.units.map((unit) => ({
  //       ...unit,
  //       tables: unit.tables.map((table) => ({
  //         ...table,
  //         occupiedChairs: table.occupiedChairs || []
  //       }))
  //     }));
  //     await updateDoc(doc(db, 'virtual-offices', spaceId), { units: sanitizedUnits });
  //     toast.success('You have successfully sat on the chair.');
  //     setSelectedRoom(`${unitName}-meeting`);
  //     setIsJitsiModalOpen(true);
  //   } catch (error) {
  //     console.error('Error updating document: ', error);
  //     toast.error('Error sitting on the chair.');
  //   }
  // };

  const handleAvatarClick = (chairDetails) => {
    setModalData(chairDetails); 
    setIsUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setIsUserModalOpen(false); 
    setModalData(null); 
  };


  const handleExitChair = async () => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === currentUnit);
    const userFirstName = userDetails.firstName || 'Unknown';
    const userLastName = userDetails.lastName || 'Unknown';
    const updatedTables = unit.tables.map((table) => ({
      ...table,
      occupiedChairs: (table.occupiedChairs || []).filter(
        (chair) => chair.name !== userFirstName || chair.surname !== userLastName
      )
    }));
    const updatedUnits = spaceDetails.units.map((u) =>
      u.unitName === currentUnit ? { ...u, tables: updatedTables } : u
    );
    setSpaceDetails({ ...spaceDetails, units: updatedUnits });
    setIsUserSeated(false);
    try {
      await updateDoc(doc(db, 'virtual-offices', spaceId), { units: updatedUnits });
      toast.success('You have exited the chair.');
    } catch (error) {
      console.error('Error exiting chair: ', error);
      toast.error('Error exiting the chair.');
    }
  };


  // const handleExitRoom = async () => {
  //   const unit = spaceDetails.units.find((unit) => unit.unitName === currentUnit);
  //   const updatedTables = unit.tables.map(table => ({
  //     ...table,
  //     occupiedChairs: (table.occupiedChairs || []).filter(
  //       chair => chair.name !== userDetails.firstName || chair.surname !== userDetails.lastName
  //     )
  //   }));
  //   const updatedUnits = spaceDetails.units.map(u =>
  //     u.unitName === currentUnit ? { ...u, tables: updatedTables } : u
  //   );
  //   setSpaceDetails({ ...spaceDetails, units: updatedUnits });
    
  //   const ipAddress = await fetchUserIP();
  //   const exitTime = serverTimestamp();
  //   const exitLog = {
  //     event: "exit",
  //     roomName: currentUnit,
  //     status: status,
  //     exitedAt: exitTime,
  //     ipAddress,
  //     userId: auth.currentUser.uid,
  //     userName: `${userDetails.firstName} ${userDetails.lastName}`  // NEW field
  //   };
    
  //   try {
  //     if (sessionLogRef) {
  //       await updateDoc(sessionLogRef, { exitedAt: exitTime });
  //     }
      
  //     const spaceLogsRef = collection(db, "virtual-offices", spaceId, "activityLogs");
  //     await addDoc(spaceLogsRef, exitLog);
  
  //     setCurrentUnit(null);
  //     // Local toast (if desired)
  //     toast.success('You have exited the room');
  //     console.log("Exit activity logged:", exitLog);
  //   } catch (error) {
  //     console.error('Error exiting room: ', error);
  //     toast.error('Error exiting the room.');
  //   }
  // };

  const handleExitRoom = async () => {
    const unit = spaceDetails.units.find(unit => unit.unitName === currentUnit);
    const updatedTables = unit.tables.map(table => ({
      ...table,
      occupiedChairs: (table.occupiedChairs || []).filter(
        chair => chair.name !== userDetails.firstName || chair.surname !== userDetails.lastName
      )
    }));
    const updatedUnits = spaceDetails.units.map(u =>
      u.unitName === currentUnit ? { ...u, tables: updatedTables } : u
    );
    setSpaceDetails({ ...spaceDetails, units: updatedUnits });
    
    const ipAddress = await fetchUserIP();
    const exitTime = serverTimestamp();
    const exitLog = {
      event: "exit",
      roomName: currentUnit,
      status,
      exitedAt: exitTime,
      ipAddress,
      userId: auth.currentUser.uid,
      userName: `${userDetails.firstName} ${userDetails.lastName}`
    };
    
    try {
      if (sessionLogRef) {
        await updateDoc(sessionLogRef, { exitedAt: exitTime });
      }
      const spaceLogsRef = collection(db, "virtual-offices", spaceId, "activityLogs");
      await addDoc(spaceLogsRef, exitLog);
      setCurrentUnit(null);
      toast.success('You have exited the room');
      console.log("Exit activity logged:", exitLog);
      // Update the user's document to mark them offline and clear currentSpace
      await updateDoc(doc(db, 'users', auth.currentUser.uid), {
        online: false,
        currentSpace: ""
      });
    } catch (error) {
      console.error('Error exiting room: ', error);
      toast.error('Error exiting the room.');
    }
  };

  const unreadCounts = useMemo(() => {
    const counts = {};
    chatMessages.forEach(msg => {
      // Only count messages from others that are unread.
      if (msg.senderId !== auth.currentUser.uid && msg.isRead === false) {
        const key = !msg.recipientId ? "everyone" : msg.senderId;
        counts[key] = (counts[key] || 0) + 1;
      }
    });
    return counts;
  }, [chatMessages]);
  

  const groupedMessages = useMemo(() => {
    const groups = {};
    filteredMessages.forEach(msg => {
      if (msg.timestamp) {
        const dateObj = new Date(msg.timestamp.toMillis());
        // Set to midnight for grouping
        dateObj.setHours(0, 0, 0, 0);
        const dateKey = dateObj.toISOString();
        if (!groups[dateKey]) groups[dateKey] = [];
        groups[dateKey].push(msg);
      }
    });
    // Sort groups by date ascending
    return Object.keys(groups)
      .sort((a, b) => new Date(a) - new Date(b))
      .map(key => ({
        date: new Date(key),
        messages: groups[key]
      }));
  }, [filteredMessages]);
  
  // Format day header in full (e.g. "09 March 2025")
  const formatChatDayTitle = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (date.getTime() === today.getTime()) return "Today";
    else if (date.getTime() === yesterday.getTime()) return "Yesterday";
    else return date.toLocaleDateString(undefined, { day: '2-digit', month: 'long', year: 'numeric' });
  };

  const handleMeetingClose = async () => {
    // Call your exit function (e.g. handleExitChair) to remove the user from the chair.
    // You can choose to call handleExitChair if the user is seated.
    if (isUserSeated) {
      await handleExitChair();
    }
    // Then close the Jitsi modal
    setIsJitsiModalOpen(false);
  };

  useEffect(() => {
    const logStatusChange = async () => {
      const now = Date.now();
      const duration = now - lastStatusChangeTimeRef.current; // Duration in milliseconds.
      const ipAddress = await fetchUserIP();
      
      // Log the previous status period.
      const statusLog = {
        event: "statusChange",
        roomName: currentUnit || "N/A",
        status: previousStatusRef.current,
        startTime: new Date(lastStatusChangeTimeRef.current).toISOString(),
        endTime: new Date(now).toISOString(),
        duration, // in milliseconds
        ipAddress,
      };
      reportActivity(statusLog);
      
      // Update refs to store the new status change time and value.
      lastStatusChangeTimeRef.current = now;
      previousStatusRef.current = status;
    };

    // Skip logging on the very first render (when there is no change yet).
    if (previousStatusRef.current !== status) {
      logStatusChange();
    }
  }, [status, currentUnit]);



  if (isCheckingInvitation || loading) {
    return (
      <div className="loader-container">
        <DotLoader size={60} color={'#ce2b7c'} />
      </div>
    );
  }

  const statusOptions = [
    { label: "Available", icon: <i className="fas fa-check-circle available"></i> },
    { label: "Busy", icon: <i className="fas fa-times-circle busy"></i> },
    { label: "Do not disturb", icon: <i className="fas fa-ban not-distrub"></i> },
    { label: "Be right back", icon: <i className="fas fa-clock brb"></i> },
    { label: "Appear away", icon: <i className="fas fa-user-times away"></i> },
    { label: "Appear offline", icon: <i className="fas fa-user-slash offline"></i> },
  ];

  const fetchUserIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address", error);
      return "Unknown";
    }
  };

  const reportActivity = async (logData) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
    try {
      // Log under the current user's document
      const userLogsRef = collection(db, "users", currentUser.uid, "activityLogs");
      await addDoc(userLogsRef, {
        ...logData,
        createdAt: serverTimestamp()
      });
  
      // Log under the virtual space document
      // Here, spaceId is available from useParams in your component.
      const spaceLogsRef = collection(db, "virtual-offices", spaceId, "activityLogs");
      await addDoc(spaceLogsRef, {
        ...logData,
        userId: currentUser.uid,  // Optionally include user id
        createdAt: serverTimestamp()
      });
  
      console.log("Activity logged:", logData);
    } catch (error) {
      console.error("Error logging activity", error);
    }
  };
  

  if (!currentUser) {
    return <div className="loader-container"><DotLoader size={60} color={'#ce2b7c'} /></div>;
  }
  

  if (!isInvited) {
    return <div>

<section class="error-section">
<div class="auto-container">

<h4></h4>
<div class="text">Sorry, we couldn't find the page you're looking for.</div>
<Link to='/'><a href="/" class="theme-btn btn-style-five" style={{color: '#fff'}}>Home Page</a></Link>
</div>
</section>



    </div>;
  }

  return (
    <div className="glass-back">
        <input
      type="file"
      ref={fileInputRef}
      style={{ display: 'none' }}
      onChange={handleFileChange}
    />
    {/* ...the rest of your JSX */}
 
      {/* <BackgroundStyleLight /> */}
      {!currentUnit && (
        <section className="virtual-space-sec">
          <div className="container-fluid">
            <div className="content-wrapper-header">
              <div className="content-wrapper-context">
                <h3 className="img-content">
                  <img src={imgDark} width="50" style={{ paddingRight: '6px' }} alt="logo" />
                  {spaceDetails.officeName}
                </h3>
                <div className="content-text">{spaceDetails.description}</div>
                <button className="content-button">Join virtual space</button>
              </div>
              <img className="content-wrapper-img" src="https://assets.codepen.io/3364143/glass.png" alt="glass" />
            </div>
          </div>
        </section>
      )}

      <section>
        <div className="auto-container">
          {!currentUnit && (
            <div className="row clearfix">
              <div className="short-box clearfix mt-4">
                <div className="text-center">
                  <p>Showing {spaceDetails.units.length} Units</p>
                </div>
              </div>
              {spaceDetails.units.map((unit, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-sm-12 mt-4">
                  <div className="card-unit">
                    <div className="card-img-unit">
                      {/* <img src="https://dl.dropbox.com/s/u3j25jx9tkaruap/Webp.net-resizeimage.jpg?raw=1" alt="Unit" /> */}

                      <img
  src={spaceDetails?.logo || eLogo}
  width="50"

  alt="Office Logo"
  onError={(e) => {
    e.target.onerror = null;
    e.target.src = eLogo;
  }}
/>
                    </div>
                    <div className="desc">
                      <h6 className="primary-text">Virtual Space</h6>
                      <h6 className="secondary-text">{unit.unitName}</h6>
                    </div>
                    <button className="primary-text btn-pp" onClick={() => handleUnitClick(unit.unitName)}>
                      Join Space
                    </button>
                    <div className="details">

                    <div className="rating">
  <h6 className="primary-text">
    {spaceDetails.units.reduce(
      (totalSeats, unit) =>
        totalSeats +
        unit.tables.reduce((sum, table) => sum + (Number(table.chairs) || 0), 0), // Ensure table.chairs is a number
      0
    )}
  </h6>
  <h6 className="secondary-text">Seats</h6>
</div>


                      <div className="activity">
                        <h6 className="primary-text">{unit.tables.length}</h6>
                        <h6 className="secondary-text">Tables</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      {currentUnit && (
        <section className="current-unit-section blur-backgroundz">
          {/* <BackgroundStyleLight /> */}
          <div className="event-header">
            <div className="logo">
              <img alt="Logo" className='imgLogoE' />
              <span>{currentUnit}</span>
            </div>
            <div className="icons">
              
            <i 
    className={showChat ? "fas fa-table" : "fas fa-users"} 
    onClick={toggleView} 
    style={{ cursor: "pointer"}}
  ></i>

<div className="profile" role="button" onClick={() => setShowUserPopup(!showUserPopup)}>
  {`${userDetails.firstName?.charAt(0)}${userDetails.lastName?.charAt(0)}`}
  <span className={`status-icon ${status.toLowerCase().replace(/\s/g, '-')}`}>
    <i className={`fas ${statusOptions.find((opt) => opt.label === status)?.icon.props.className}`}></i>
  </span>
</div>



              {isUserSeated && (
                <button onClick={handleExitChair} type="button" className="ml-2 btn btn-sm light btn-secondary">
                  Exit Chair
                </button>
              )}
              <button onClick={handleExitRoom} type="button" className="ml-2 btn btn-sm light btn-danger">
                Leave the room
              </button>
            </div>
          </div>

      

          {showChat ? (



  
  <section  style={{ height: '100vh' }}>
    {uploading && (

<div className="upload-preloader">
<FadeLoader color="#ce2b7c" size={50} />
<span style={{paddingTop:'8px'}}>Uploading...{Math.floor(uploadProgress)}%</span>
</div>

)}
    <div className='chat-fun' style={{ height: '100%' }}>
    <div>
 <div className="layout-wrapper d-lg-flex" style={{ height: '100%' }}>
      {/* Start left sidebar-menu */}
      <div className="side-menu flex-lg-column me-lg-1 ms-lg-0"  style={{ overflow: 'hidden' }}>
       

        {/* Start side-menu nav */}
        <div className="flex-lg-column my-auto">
          <ul className="nav nav-pills side-menu-nav justify-content-center" role="tablist">
            <li
              className="nav-item"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Profile"
            >
            <a
                    className={`nav-link ${activeTab === "profile" ? "active" : ""}`}
                    onClick={() => setActiveTab("profile")}
                    role="tab"
                  >
                    <i className="fas fa-user"></i>
                  </a>
            </li>
            <li
              className="nav-item"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Chats"
            >
               <a
                    className={`nav-link ${activeTab === "chat" ? "active" : ""}`}
                    onClick={() => setActiveTab("chat")}
                    role="tab"
                  >
                    <i className="fas fa-comments"></i>
                  </a>
            </li>

           
          </ul>
        </div>
        {/* end side-menu nav */}
      </div>
      {/* end left sidebar-menu */}

      {/* start chat-leftsidebar */}
      <div className="chat-leftsidebar me-lg-1 ms-lg-0">
        <div className="tab-content">
          {/* Start Profile tab-pane */}

          {activeTab === "profile" && (
          <div className="tab-pane show active" >
            {/* Start profile content */}
            <div>
              <div className="px-4 pt-4">
                <div className="user-chat-nav float-end">
                  <div className="dropdown">
                    <a
                      href="#"
                      className="font-size-18 text-muted dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ri-more-2-fill"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                      <a className="dropdown-item" href="#">
                        Edit
                      </a>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </div>
                  </div>
                </div>
                <h4 className="mb-0">My Profile</h4>
              </div>

              <div className="text-center p-4 border-bottom">
                <div className="mb-4">
                  <img
                    src={avatar1}
                    className="rounded-circle avatar-lg img-thumbnail"
                    alt=""
                  />
                </div>

                <h5 className="font-size-16 mb-1 text-truncate">Patricia Smith</h5>
                <p className="text-muted text-truncate mb-1">
                  <i className="fas fa-circle font-size-10 text-success me-1 ms-0 d-inline-block"></i> Active
                </p>
              </div>
              {/* End profile user */}

              {/* Start user-profile-desc */}
              <div className="p-4 user-profile-desc" data-simplebar>
                <div className="text-muted">
                  <p className="mb-4">
                    If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual.
                  </p>
                </div>

                <div id="tabprofile" className="accordion">
                  <div className="accordion-item card border mb-2">
                    <div className="accordion-header" id="about2">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#about"
                        aria-expanded="true"
                        aria-controls="about"
                      >
                        <h5 className="font-size-14 m-0">
                          <i className="fas fa-user me-2 ms-0 align-middle d-inline-block"></i> About
                        </h5>
                      </button>
                    </div>
                    <div
            
                    >
                      <div className="accordion-body">
                        <div>
                          <p className="text-muted mb-1">Name</p>
                          <h5 className="font-size-14">Patricia Smith</h5>
                        </div>

                        <div className="mt-4">
                          <p className="text-muted mb-1">Email</p>
                          <h5 className="font-size-14">adc@123.com</h5>
                        </div>

                        <div className="mt-4">
                          <p className="text-muted mb-1">Time</p>
                          <h5 className="font-size-14">11:40 AM</h5>
                        </div>

                     
                      </div>
                    </div>
                  </div>
                  {/* End About card */}

                
                  {/* End Attached Files card */}
                </div>
                {/* end profile-user-accordion */}
              </div>
              {/* end user-profile-desc */}
            </div>
            {/* End profile content */}
          </div>

)}
          {/* End Profile tab-pane */}

          {/* Start chats tab-pane */}

          {activeTab === "chat" && (
          <div
            className="tab-pane fade show active"
            
          >
            {/* Start chats content */}
            <div>
              <div className="px-4 pt-4 chat-title">
                <h4 className="mb-4">Chats</h4>
                <div className="search-box chat-search-box">
                  <div className="input-group mb-3 rounded-3">
                    {/* <span className="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
                      <i className="fas fa-search search-icon font-size-18"></i>
                    </span> */}
                    <input
                      type="text"
                      className="form-control bg-light"
                      placeholder="Search messages or users"
                      aria-label="Search messages or users"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
                {/* Search Box */}
              </div>
              {/* .p-4 */}

              {/* Start user status */}
              <div className="user-status-wrapper">
      <div className="px-4 pb-4 user-status-container" dir="ltr">
      <Swiper
    modules={[Navigation, Pagination]}
    spaceBetween={10}
    slidesPerView={3}
    navigation={{
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }}
    pagination={{ clickable: true, el: ".custom-swiper-pagination" }}
    breakpoints={{
      640: { slidesPerView: 2 },
      768: { slidesPerView: 3 },
      1024: { slidesPerView: 4 },
    }}
    className="user-status-carousel"
  >
    <SwiperSlide key="everyone">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setActiveChat({ type: "group", id: "everyone", name: "Everyone" });
        }}
        className={`user-status-box ${activeChat.type === "group" ? "active" : ""}`}
      >
        <div className="avatar-xs mx-auto d-block chat-user-img online">
          <span className="avatar-title rounded-circle bg-primary-subtle text-primary">ALL</span>
          <span className="user-status"></span>
        </div>
        <h5 className="font-size-13 text-truncate mt-3 mb-1">Everyone</h5>
      </a>
    </SwiperSlide>
    {onlineUsers
      .filter(user => user.id !== auth.currentUser.uid)
      .map(user => (
      <SwiperSlide key={user.id}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setActiveChat({
              type: "private",
              id: user.id,
              name: `${user.firstName} ${user.lastName}`
            });
          }}
          className={`user-status-box ${
            activeChat.type === "private" && activeChat.id === user.id ? "active" : ""
          }`}
        >
          <div className="avatar-xs mx-auto d-block chat-user-img online">
            {user.photoURL 
              ? <img src={user.photoURL} alt="user-img" className="img-fluid rounded-circle" /> 
              : <Avatar name={`${user.firstName} ${user.lastName}`} size="30" round />
            }
            <span className="user-status"></span>
          </div>
          <h5 className="font-size-13 text-truncate mt-3 mb-1">
            {user.firstName} {user.lastName}
          </h5>
        </a>
      </SwiperSlide>
    ))}
  </Swiper>

        {/* Custom Small Navigation Buttons */}
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>

      {/* Pagination Dots Positioned Outside */}
      <div className="custom-swiper-pagination"></div>
    </div>
              {/* end user status */}

              {/* Start chat-message-list */}
              <div className="recent">
                <h5 className="mb-3 px-3 font-size-16">Recent</h5>

                <div className="chat-message-list px-2" data-simplebar>
                <ul className="list-unstyled chat-list chat-user-list">
                {recentChats.map(convo => (
    <li key={convo.id} className={activeChat.type === convo.type && activeChat.id === convo.id ? "active" : ""}>
      <a href="#" onClick={(e) => {
        e.preventDefault();
        setActiveChat({ type: convo.type, id: convo.id, name: convo.name });
      }}>
        <div className="d-flex">
          <div className="chat-user-img online align-self-center me-3 ms-0">
            {convo.type === "group" ? (
              <div className="avatar-xs mx-auto d-block chat-user-img online">
                <span className="avatar-title rounded-circle bg-primary-subtle text-primary">ALL</span>
              </div>
            ) : (
              <Avatar name={convo.name} size="30" round />
            )}
            <span className="user-status"></span>
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <h5 className="text-truncate font-size-15 mb-1">{convo.name}</h5>
            <p className="chat-user-message text-truncate mb-0">
              {typeof convo.lastMessage === "object" && convo.lastMessage.type === "file" ? (
                <>
                  <i className={getFileIcon(convo.lastMessage.fileType)}></i> {convo.lastMessage.fileName}
                </>
              ) : (
                convo.lastMessage
              )}
            </p>
          </div>
          <div className="font-size-11">
            {convo.lastTimestamp ? new Date(convo.lastTimestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ""}
          </div>
          {unreadCounts[convo.id] > 0 && (
            <div className="unread-message">
              <span className="badge badge-soft-danger rounded-pill">{unreadCounts[convo.id]}</span>
            </div>
          )}
        </div>
      </a>
    </li>
  ))}
</ul>
                </div>
              </div>
              {/* End chat-message-list */}
            </div>
            {/* Start chats content */}
          </div>
          )}
          {/* End chats tab-pane */}
        </div>
        {/* end tab content */}
      </div>
      {/* end chat-leftsidebar */}

      {/* Start User chat */}
      <div className="user-chat w-100 overflow-hidden">
      <div className="d-lg-flex">
        <div className="w-100 overflow-hidden position-relative">
          {/* Top Bar: Conditionally hide call/video icons for group chat */}
          <div className="p-3 p-lg-4 border-bottom user-chat-topbar">
            <div className="row align-items-center">
              <div className="col-sm-4 col-8">
                <div className="d-flex align-items-center">
                  <div className="d-block d-lg-none me-2 ms-0">
                    <a href="javascript: void(0);" className="user-chat-remove text-muted font-size-16 p-2">
                      <i className="ri-arrow-left-s-line"></i>
                    </a>
                  </div>
                  <div className="me-3 ms-0">
                    <img src={avatar4} className="rounded-circle avatar-xs" alt=""/>
                  </div>
                  <div className="flex-grow-1 overflow-hidden chat-name">
                    <h5 className="font-size-16 mb-0 text-truncate ">
                      <a href="#" className="text-reset user-profile-show">{activeChat.name}</a>{" "}
                      {activeChat.type === "group" && (
                        <i className="fas fa-circle font-size-10 text-success d-inline-block ms-1"></i>
                      )}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-sm-8 col-4">
                <ul className="list-inline user-chat-nav text-end mb-0">
                  <li className="list-inline-item">
                    <div className="dropdown">
                      <button className="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-search"></i>
                      </button>
                      <div className="dropdown-menu p-0 dropdown-menu-end dropdown-menu-md">
                        <div className="search-box p-2">
                          <input type="text" className="form-control bg-light border-0" placeholder="Search..."/>
                        </div>
                      </div>
                    </div>
                  </li>
                  {activeChat.type === "private" && (
                    <>
                      <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                        <button type="button" className="btn nav-btn" data-bs-toggle="modal" data-bs-target="#audiocallModal">
                          <i className="fas fa-phone"></i>
                        </button>
                      </li>
                      <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                        <button type="button" className="btn nav-btn" data-bs-toggle="modal" data-bs-target="#videocallModal">
                          <i className="fas fa-video"></i>
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          
          {/* Chat Conversation List */}
          <div className="chat-conversation p-3 p-lg-4" data-simplebar>
            {/* <ul className="list-unstyled mb-0">
              {filteredMessages.map((msg) => {
                const isCurrentUser = msg.senderId === auth.currentUser.uid;
                return (
                  <li key={msg.id} className={isCurrentUser ? "right" : ""}>
                    <div className="conversation-list">
                      <div className="chat-avatar">
                        <img src={isCurrentUser ? avatar1 : avatar4} alt=""/>
                      </div>
                      <div className="user-chat-content">
                        <div className="ctext-wrap">
                          <div className="ctext-wrap-content">
                            <p className="mb-0">{msg.text}</p>
                            <p className="chat-time mb-0">
                              <i className="fas fa-clock align-middle"></i>{" "}
                              <span className="align-middle">
                                {msg.timestamp ? new Date(msg.timestamp.toMillis()).toLocaleTimeString() : ""}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="conversation-name">{msg.senderName}</div>
                      </div>
                    </div>
                  </li>
                );
              })}
              <div ref={chatEndRef}></div>
            </ul> */}
<ul className="list-unstyled mb-0">
  {groupedMessages.map(group => (
    <React.Fragment key={group.date.toISOString()}>
      <li>
        <div className="chat-day-title">
          <span className="title">{formatChatDayTitle(group.date)}</span>
        </div>
      </li>
      {group.messages.map(msg => {
        const isCurrentUser = msg.senderId === auth.currentUser.uid;
        return (
          <li key={msg.id} className={isCurrentUser ? "right" : ""}>
            <div className="conversation-list">
              <div className="chat-avatar">
                <img src={isCurrentUser ? avatar1 : avatar4} alt=""/>
              </div>
              <div className="user-chat-content">
                <div className="ctext-wrap">
                  <div className="ctext-wrap-content">
                  {renderMessageContent(msg)}
                    {/* <p className="mb-0">{msg.text}</p> */}
                    <p className="chat-time mb-0">
                      <i className="fas fa-clock align-middle"></i>{" "}
                      <span className="align-middle">
                        {msg.timestamp
                          ? new Date(msg.timestamp.toMillis()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                          : ""}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="conversation-name">{msg.senderName}</div>
              </div>
            </div>
          </li>
        );
      })}
    </React.Fragment>
  ))}
  <div ref={chatEndRef}></div>
</ul>


          </div>
          
          {/* Chat Input Section */}
          <div className="chat-input-section p-3 p-lg-4 border-top mb-0">

          {showEmojiPicker && (
          <div
            className="emoji-picker-container"
            style={{
              position: 'absolute',
              bottom: '60px',
              left: '20px',
              zIndex: 1000,
            }}
          >
            <EmojiPicker onEmojiClick={handleEmojiClick}
            theme={theme}
            />
          </div>
        )}
            <div className="row g-0">
              <div className="col">
                <input
                  type="text"
                  className="form-control form-control-lg bg-light border-light"
                  placeholder="Enter Message..."
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                />
              </div>
              <div className="col-auto">
                <div className="chat-input-links ms-md-2 me-md-0">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="top" title="Emoji">
                    <button
                    type="button"
                    className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    <i className="fas fa-smile"></i>
                  </button>
                    </li>
                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="top" title="Attached File">
                      <button type="button"    onClick={handleAttachFile} className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                        <i className="fas fa-paperclip"></i>
                      </button>
                    </li>
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light"
                        onClick={sendMessage}
                      >
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* End Chat Input Section */}
        </div>
      </div>
    </div>
      {/* end  layout wrapper */}
    </div>
    </div>
      

    </div>
  </section>





) : (
  // Show tables when 'fa-table' icon is clicked
  
  <div className="tables-container">
    
    <div className="room-layout">
      <div className="tables-grid container room-cards-container">
        {spaceDetails.units
          .find((unit) => unit.unitName === currentUnit)
          .tables.map((table, index) => {
            const { topRow, bottomRow, leftRow, rightRow } = calculateChairDistribution(table.chairs);
            return (
              <div className="table-card" key={table.tableName}>
                <div className="meeting-title">{table.tableName}</div>
                <div className="table-container">
                  <div className="table-area">
                    {/* Top row */}
                    <div className="row-chairs">
                      {Array(topRow).fill().map((_, i) => {
                        const occupied = table.occupiedChairs?.find(chair => chair.index === i);
                        return (
                          <div
                            key={`top-${i}`}
                            onClick={() => {
                              if (occupied) {
                                handleAvatarClick(occupied);
                              } else {
                                handleSitOnChair(currentUnit, index, i);
                              }
                            }}
                            className="chair"
                          >
                            {occupied && (
                              <Avatar name={`${occupied.name} ${occupied.surname}`} size="25" round='6px' />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="d-flex justify-content-center align-items-center my-2">
                      {/* Left vertical chairs */}
                      <div className="vertical-chairs me-1">
                        {Array(leftRow).fill().map((_, i) => {
                          const occupied = table.occupiedChairs?.find(chair => chair.index === i + topRow + rightRow);
                          return (
                            <div
                              key={`left-${i}`}
                              onClick={() => {
                                if (occupied) {
                                  handleAvatarClick(occupied);
                                } else {
                                  handleSitOnChair(currentUnit, index, i + topRow + rightRow);
                                }
                              }}
                              className="chair"
                            >
                              {occupied && (
                                <Avatar name={`${occupied.name} ${occupied.surname}`} size="25" round='6px' />
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {/* Table */}
                      <div className="table-rect">
                        {table.tableLogoUrl ? (
                          <img src={table.tableLogoUrl} alt="Table Logo" className="table-logo" width='38' />
                        ) : (
                          <img src={eLogo} alt="Table Logo" className="table-logo" width='35' />
                        )}
                      </div>
                      {/* Right vertical chairs */}
                      <div className="vertical-chairs ms-1">
                        {Array(rightRow).fill().map((_, i) => {
                          const occupied = table.occupiedChairs?.find(chair => chair.index === i + topRow);
                          return (
                            <div
                              key={`right-${i}`}
                              onClick={() => {
                                if (occupied) {
                                  handleAvatarClick(occupied);
                                } else {
                                  handleSitOnChair(currentUnit, index, i + topRow);
                                }
                              }}
                              className="chair"
                            >
                              {occupied && (
                                <Avatar name={`${occupied.name} ${occupied.surname}`} size="25" round='6px' />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* Bottom row */}
                    <div className="row-chairs">
                      {Array(bottomRow).fill().map((_, i) => {
                        const occupied = table.occupiedChairs?.find(chair => chair.index === i + topRow + rightRow + leftRow);
                        return (
                          <div
                            key={`bottom-${i}`}
                            onClick={() => {
                              if (occupied) {
                                handleAvatarClick(occupied);
                              } else {
                                handleSitOnChair(currentUnit, index, i + topRow + rightRow + leftRow);
                              }
                            }}
                            className="chair"
                          >
                            {occupied && (
                              <Avatar name={`${occupied.name} ${occupied.surname}`} size="25" round='6px' />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <button className="join-btn">Join</button>
              </div>
            );
          })}
      </div>
    </div>
  </div>
)}

          {/* <div className="fab-wrapper">
            <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
            <label className="fabF" htmlFor="fabCheckbox">
              <span className="fab-dots fab-dots-1"></span>
              <span className="fab-dots fab-dots-2"></span>
              <span className="fab-dots fab-dots-3"></span>
            </label>
            <div className="fab-wheel">
              <a className="fab-action fab-action-1" data-tooltip-id="my-tooltip-help">
                <i className="fas fa-question"></i>
              </a>
              <a className="fab-action fab-action-2" data-tooltip-id="my-tooltip-polls">
                <i className="fas fa-poll"></i>
              </a>
              <a className="fab-action fab-action-3" data-tooltip-id="my-tooltip-qa">
                <i className="fas fa-comment-alt"></i>
              </a>
              <a className="fab-action fab-action-4" data-tooltip-id="my-tooltip-chat">
                <i className="fas fa-comments"></i>
              </a>
              <Tooltip id="my-tooltip-help" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Help!</h5>
                <p style={{ color: '#fff' }}>eConnect Chat Support</p>
              </Tooltip>
              <Tooltip id="my-tooltip-chat" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Chat!</h5>
                <p style={{ color: '#fff' }}>Enjoy interacting with the people in this webinar!</p>
              </Tooltip>
              <Tooltip id="my-tooltip-qa" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Q&A!</h5>
                <p style={{ color: '#fff' }}>An easy way for everyone to ask questions</p>
              </Tooltip>
              <Tooltip id="my-tooltip-polls" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Polls</h5>
                <p style={{ color: '#fff' }}>Get a quick pulse of the audience</p>
              </Tooltip>
            </div>
          </div> */}




        </section>

        
      )}

   

      <Modal isOpen={isUserModalOpen} onRequestClose={handleCloseUserModal} style={customStylesBio}>
        {modalData && (
          <div className="text-center">
            <Avatar name="Mduduzi Mahlangu" color={"#8d14ff"} size="62" round={true} />
            <br />
            <p className="mt-2"><strong>{modalData.name} {modalData.surname}</strong></p>
            {console.log("Modal Data: ", modalData)}
            <p>{modalData.aboutMe ? modalData.aboutMe : "Bio not available"}</p>
            <button onClick={handleCloseUserModal} type="button" className="btn btn-sm light btn-danger">
              Close
            </button>
          </div>
        )}
      </Modal>

  

      <EconnectMeetingModal
        isOpen={isJitsiModalOpen}
        onClose={handleMeetingClose} 
        roomName={selectedRoom}
        userName={`${userDetails.firstName} ${userDetails.lastName}`}
      />

{showUserPopup && (
  <div className="user-popup">
    <div className="profile">
      {`${userDetails.firstName?.charAt(0)}${userDetails.lastName?.charAt(0)}`}
    </div>

    <div className="pop-card-info">
      <p className="user-name">{userDetails.firstName} {userDetails.lastName}</p>
      <small className="user-email">{userDetails?.email || auth.currentUser?.email || "Email not available"}</small>
    </div>
    
    <button className="status-selector light btn-info" onClick={() => setShowStatusMenu(!showStatusMenu)}>
      <span>{status} <i className='fas fa-chevron-right'></i></span>
    </button>

    {showStatusMenu && (
      <ul className="status-menu">
        {statusOptions.map((option) => (
          <li 
            key={option.label} 
            onClick={() => { 
              setStatus(option.label); 
              setShowStatusMenu(false); 
              setShowUserPopup(false); 
            }}
          >
            {option.icon} {option.label}
          </li>
        ))}
      </ul>
    )}
  </div>
)}





    </div>
  );
};

export default VirtualOfficeSpaceUnits;
