import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";


const PageLoader = () => {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    setFadeOut(false);

    const handleLoad = () => {
      setTimeout(() => setFadeOut(true), 500); // Start fade-out transition
      setTimeout(() => setLoading(false), 1000); // Fully remove after fade-out
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, [location]);

  return (
    loading && (
      <div className={`preloader ${fadeOut ? "fade-out" : "fade-in"}`}>
        <div className="loader"></div>
      </div>
    )
  );
};

export default PageLoader;
