import React from 'react'
import { BounceLoader, CircleLoader, DotLoader, FadeLoader, MoonLoader } from 'react-spinners'

const ChatTest = () => {
  return (

    
    <div className="upload-preloader">
    <FadeLoader color="#ce2b7c" size={50} />
    <span style={{paddingTop:'8px'}}>Uploading...58%</span>
  </div>
  
  )
}

export default ChatTest
