import React, { useState } from "react";
import StaticHeader from "../components/StaticHeader";
import mainslider from "../images/main-slider1.jpg";

import abtimg1 from "../images/about-img-1.jpg";
import abtimg2 from "../images/about-img-2.jpg";

import sp1 from "../images/resource/rspeaker-1.jpg";
import sp2 from "../images/resource/rspeaker-2.jpg";
import sp3 from "../images/resource/rspeaker-3.jpg";
import sp4 from "../images/resource/rspeaker-4.jpg";

import logo from "../images/e-gradient.png";


import imgS1 from '../images/clients/silver-1.png'
import imgS2 from '../images/clients/silver-2.png'
import imgS3 from '../images/clients/silver-3.png'
import imgS4 from '../images/clients/silver-4.png'
import imgS5 from '../images/clients/silver-5.png'

import imgG1 from '../images/clients/gold-1.png'
import imgG2 from '../images/clients/gold-2.png'
import imgG3 from '../images/clients/gold-3.png'
import imgG4 from '../images/clients/gold-4.png'
import imgG5 from '../images/clients/gold-5.png'

import imgP1 from '../images/clients/platinum-1.png'
import imgP2 from '../images/clients/platinum-2.png'
import imgP3 from '../images/clients/platinum-3.png'


const EventDetailsz = () => {
  return (
    <div>
      <header class="main-header event-hed">
        <div class="main-box">
          <div class="auto-container clearfix">
            <div class="nav-outer clearfix">
              <div class="mobile-nav-toggler">
                <span class="icon flaticon-menu-1"></span>
              </div>

              <nav class="main-menu navbar-expand-md navbar-light">
                <div
                  class="collapse navbar-collapse clearfix"
                  id="navbarSupportedContent"
                >
                  <div class="logo-box hed-box">
                    <div class="logo">
                      <a href="#">
                        <img width="50" src={logo} alt="" title="" />
                      </a>
                    </div>
                  </div>

                  <ul class="navigation clearfix navigation-resp">
                    <li class="current dropdownz">
                      <a href="#">Home</a>
                    </li>
                    <li class="dropdownz">
                      <a href="#">About</a>
                    </li>
                    <li class="dropdownz">
                      <a href="#">Speakers</a>
                    </li>
                    <li class="dropdownz">
                      <a href="#">Schedule</a>
                    </li>

                    <li class="dropdownz">
                      <a href="#">Register</a>
                    </li>

                    <li class="dropdownz">
                      <a href="#">Sponsors</a>
                    </li>
                  </ul>

                  <div class="outer-boxz">
                    <div class="btn-boxz">
                      <a href="#" class="theme-btn btn-style-one">
                        <span class="btn-title">Get Tickets!</span>
                      </a>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <section class="banner-sectionz">
        <div class="banner-carouselz">
          <div class="swiper-wrapperz">
            <div
              class="swiper-slidez"
              style={{
                backgroundImage: `url(${mainslider})`,
              }}
            >
              <div
                class="banner-one__countdown-timer-box"
                data-wow-delay="100ms"
              >
                <div
                  class="countdown time-countdown-two"
                  data-countdown-time="2025/08/01"
                >
                  <li>
                    {" "}
                    <div class="box">
                      {" "}
                      <span class="days">156</span>{" "}
                      <span class="timeRef">Days</span>{" "}
                    </div>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <div class="box">
                      {" "}
                      <span class="hours">11</span>{" "}
                      <span class="timeRef clr-1">Hours</span>{" "}
                    </div>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <div class="box">
                      {" "}
                      <span class="minutes">08</span>{" "}
                      <span class="timeRef clr-2">Min</span>{" "}
                    </div>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <div class="box">
                      {" "}
                      <span class="seconds">39</span>{" "}
                      <span class="timeRef clr-3">Sec</span>{" "}
                    </div>{" "}
                  </li>
                </div>
              </div>
              <div class="auto-container">
                <div class="content-boxz">
                  {/* <span class="titlez">Hurryup! Don't Waste Time</span> */}
                  <h2>Creative business agency Conference 2027</h2>
                  <ul class="info-listz">
                    <li>
                      <span class="icon fas fa-clock"></span> January 24, 2027
                    </li>
                    <li>
                      <span class="icon fas fa-map-pin"></span> Gauteng, South
                      Africa
                    </li>
                  </ul>
                  <div class="btn-boxz">
                    <a href="#" class="g-btn">
                      <button class="btn-title">
                        Get Started Now <i className="fas fa-arrow-right"></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="features-section">
        <span class="float-text">our feature</span>
        <div class="auto-container">
          <div class="sec-title">
            <h2>Our Feature</h2>
          </div>
          <div class="row">
            <div class="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated">
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-best"></span>
                </div>
                <h4>
                  <a href="javascript:void(0)">Experience</a>
                </h4>
                <div class="text">
                  Meet the experts from the tech and design world you follow
                  online.lorem temporibus id. integandi
                </div>
              </div>
            </div>

            <div
              class="feature-block activated col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated"
              data-wow-delay="400ms"
            >
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-rocket-1"></span>
                </div>
                <h4>
                  <a href="javascript:void(0)">Networking</a>
                </h4>
                <div class="text">
                  Connect with and exchange ideas with designers from all over
                  the world.Party Intimate cocktail party
                </div>
              </div>
            </div>

            <div
              class="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated"
              data-wow-delay="800ms"
            >
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-medal-3"></span>
                </div>
                <h4>
                  <a href="javascript:void(0)">Party</a>
                </h4>
                <div class="text">
                  Intimate cocktail party watching the sunset over San Francisco
                  Bay.Lorem emporibus id. In intege salutandi
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="about-section">
        <div
          class="parallax-scene parallax-scene-1 anim-icons"
          style={{
            transform: "translate3d(0px, 0px, 0px) rotate(0.0001deg)",
            transformStyle: "preserve-3d",
            backfaceVisibility: "hidden",
            pointerEvents: "none",
          }}
        >
          <span
            className="icon icon-mic"
            style={{
              transform: "translate3d(-17.5px, -16.8px, 0px)",
              transformStyle: "preserve-3d",
              backfaceVisibility: "hidden",
              position: "relative",
              display: "block",
              left: "0px",
              top: "0px",
            }}
          ></span>
          <span
            className="icon icon-mic"
            style={{
              transform: "translate3d(-17.5px, -16.8px, 0px)",
              transformStyle: "preserve-3d",
              backfaceVisibility: "hidden",
              position: "absolute",
              display: "block",
              left: "0px",
              top: "0px",
            }}
          ></span>
          <span
            className="icon icon-mic-2"
            style={{
              transform: "translate3d(-26.2px, -25.2px, 0px)",
              transformStyle: "preserve-3d",
              backfaceVisibility: "hidden",
              position: "absolute",
              display: "block",
              left: "0px",
              top: "0px",
            }}
          ></span>
          <span
            className="icon icon-mic-2"
            style={{
              transform: "translate3d(-34.9px, -33.5px, 0px)",
              transformStyle: "preserve-3d",
              backfaceVisibility: "hidden",
              position: "absolute",
              display: "block",
              left: "0px",
              top: "0px",
            }}
          ></span>
          <span
            className="icon icon-speaker"
            style={{
              transform: "translate3d(-43.6px, -41.9px, 0px)",
              transformStyle: "preserve-3d",
              backfaceVisibility: "hidden",
              position: "absolute",
              display: "block",
              left: "0px",
              top: "0px",
            }}
          ></span>
          <span
            className="icon icon-speaker-2"
            style={{
              transform: "translate3d(-52.4px, -50.3px, 0px)",
              transformStyle: "preserve-3d",
              backfaceVisibility: "hidden",
              position: "absolute",
              display: "block",
              left: "0px",
              top: "0px",
            }}
          ></span>
          <span
            className="icon icon-shape-1"
            style={{
              transform: "translate3d(-26.2px, -25.2px, 0px)",
              transformStyle: "preserve-3d",
              backfaceVisibility: "hidden",
              position: "absolute",
              display: "block",
              left: "0px",
              top: "0px",
            }}
          ></span>
          <span
            className="icon icon-man"
            style={{
              transform: "translate3d(-69.8px, -67.1px, 0px)",
              transformStyle: "preserve-3d",
              backfaceVisibility: "hidden",
              position: "absolute",
              display: "block",
              left: "0px",
              top: "0px",
            }}
          ></span>
        </div>

        <div class="auto-container">
          <div class="row">
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="sec-title">
                  <span class="title">About Event</span>
                  <h2>What is a Startup conference?</h2>
                  <div class="text">
                    Get stuffed mate cup of char Eaton on your bike mate mufty
                    victoria sponge morish up the duff zonked, knees up blag do
                    one butty David loo jolly good absolutely bladdered daft,
                    cheesed off wind up cracking.!
                  </div>
                </div>
                <div class="btn-box">
                  <a href="#" class="theme-btn btn-style-three">
                    <span class="btn-title">Register Now</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <div class="image-box">
                <figure class="image-1 wow fadeIn animated">
                  <img src={abtimg1} alt="" />
                </figure>
                <figure class="image-2 wow fadeInRight animated">
                  <img src={abtimg2} alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="speakers-section">
        <div class="anim-icons">
          <span class="icon icon-shape-2 "></span>
        </div>
        <div class="auto-container">
          <div class="sec-title text-center">
            <span class="title">Who Are Speaking</span>
            <h2>Who's Speaking?</h2>
          </div>

          <div class="row">
            <div class="speaker-block col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
              <div class="image-box">
                <figure class="image">
                  <img src={sp1} alt="" />
                </figure>
                <div class="overlay-box">
                  <div class="info-box">
                    <h4 class="name">
                      <a href="#">Fergus Douche</a>
                    </h4>
                    <span class="designation">Event Manager</span>
                  </div>
                  <ul class="social-links">
                    <li>
                      <a href="#">
                        <span class="fab fa-facebook-f"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-twitter"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-vimeo-v"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-linkedin-in"></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="caption-box">
                  <h4 class="name">Fergus Douche</h4>
                  <span class="designation">Event Manager</span>
                </div>
              </div>
            </div>

            <div class="speaker-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow ">
              <div class="image-box">
                <figure class="image">
                  <img src={sp2} alt="" />
                </figure>
                <div class="overlay-box">
                  <div class="info-box">
                    <h4 class="name">
                      <a href="#">Nathaneal Down</a>
                    </h4>
                    <span class="designation">Developer Expert</span>
                  </div>
                  <ul class="social-links">
                    <li>
                      <a href="#">
                        <span class="fab fa-facebook-f"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-twitter"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-vimeo-v"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-linkedin-in"></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="caption-box">
                  <h4 class="name">Nathaneal Down</h4>
                  <span class="designation">Developer Expert</span>
                </div>
              </div>
            </div>

            <div class="speaker-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="image-box">
                <figure class="image">
                  <img src={sp3} alt="" />
                </figure>
                <div class="overlay-box">
                  <div class="info-box">
                    <h4 class="name">
                      <a href="#">Benjamin Evalent</a>
                    </h4>
                    <span class="designation">Lead Designer</span>
                  </div>
                  <ul class="social-links">
                    <li>
                      <a href="#">
                        <span class="fab fa-facebook-f"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-twitter"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-vimeo-v"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-linkedin-in"></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="caption-box">
                  <h4 class="name">Benjamin Evalent</h4>
                  <span class="designation">Lead Designer</span>
                </div>
              </div>
            </div>

            <div class="speaker-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="image-box">
                <figure class="image">
                  <img src={sp4} alt="" />
                </figure>
                <div class="overlay-box">
                  <div class="info-box">
                    <h4 class="name">
                      <a href="#">Bailey Wonger</a>
                    </h4>
                    <span class="designation">Marketar</span>
                  </div>
                  <ul class="social-links">
                    <li>
                      <a href="#">
                        <span class="fab fa-facebook-f"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-twitter"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-vimeo-v"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="fab fa-linkedin-in"></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="caption-box">
                  <h4 class="name">Bailey Wonger</h4>
                  <span class="designation">Marketer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="register-section">
        <div class="auto-container">
          <div class="sec-title light text-center">
            <samp class="title">Register Now</samp>
            <h2>Register Attend</h2>
          </div>

          <div class="register-form">
            <form method="post" action="#">
              <div class="row">
                <div class="form-group col-lg-3 col-md-6 col-sm-12">
                  <input
                    type="text"
                    name="username"
                    placeholder="Full name"
                    required=""
                  />
                </div>

                <div class="form-group col-lg-3 col-md-6 col-sm-12">
                  <input
                    type="email"
                    name="email"
                    value=""
                    placeholder="Email address"
                    required=""
                  />
                </div>

                <div class="form-group col-lg-3 col-md-6 col-sm-12">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    required=""
                  />
                </div>

                <div class="form-group col-lg-3 col-md-6 col-sm-12">
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    required=""
                  />
                </div>

                <div class="form-group col-lg-12 text-center">
                  <button type="submit" class="theme-btn btn-style-three">
                    <span class="btn-title">Register Now</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section class="pricing-section alternate">
        <div class="anim-icons">
          <span class="icon icon-circle-1 wow zoomIn animated"></span>
        </div>
        <div class="auto-container">
          <div class="sec-title text-center">
            <span class="title">Pricing Plan</span>
            <h2>Ticket Information</h2>
          </div>

          <div class="row">
            <div class="pricing-block col-lg-6 col-md-12 col-sm-12 wow fadeInLeft animated">
              <div class="inner-box">
                <div class="upper-box clearfix">
                  <h3 class="title">Per-Parson Pass</h3>
                  <div class="price">R95.00</div>
                </div>

                <div class="lower-content">
                  <div class="text">
                    David blatant a blinding shot such a fibber gosh baking
                    cakes what a plonker, cockup victoria sponge.
                  </div>

                  <ul class="feature-list clearfix">
                    <li class="true">Conference Tickets</li>
                    <li class="true">50 Videos to Upload</li>
                    <li class="true">Easy Access</li>
                    <li class="true">16 Contacts</li>
                    <li class="true">Free Lunch And Coffee</li>
                    <li class="false">Printed Metarials</li>
                    <li class="true">Certificate</li>
                    <li class="false">More Options</li>
                  </ul>

                  <div class="btn-box">
                    <a href="#" class="theme-btn btn-style-three">
                      <span class="btn-title">Buy Ticket</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="pricing-block style-two col-lg-6 col-md-12 col-sm-12 wow fadeInRight animated">
              <div class="inner-box">
                <div class="upper-box clearfix">
                  <h3 class="title">Full-Family Pass</h3>
                  <div class="price">R120.00</div>
                </div>

                <div class="lower-content">
                  <div class="text">
                    David blatant a blinding shot such a fibber gosh baking
                    cakes what a plonker, cockup victoria sponge.
                  </div>

                  <ul class="feature-list clearfix">
                    <li class="true">Conference Tickets</li>
                    <li class="true">50 Videos to Upload</li>
                    <li class="true">Easy Access</li>
                    <li class="true">16 Contacts</li>
                    <li class="true">Free Lunch And Coffee</li>
                    <li class="true">Printed Metarials</li>
                    <li class="true">Certificate</li>
                    <li class="true">More Options</li>
                  </ul>

                  <div class="btn-box">
                    <a href="#" class="theme-btn btn-style-three">
                      <span class="btn-title">Buy Ticket</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="clients-section">
        <div class="auto-container">
          <div class="sec-title text-center">
            <span class="title">Event Sponsors</span>
            <h2>Partners &amp; Sponsors</h2>
          </div>

          <div class="sponsors-outer wow fadeInUp animated">
            <div class="title-box">
              <h3>Platinum Sponsors</h3>
            </div>

            <ul class="sponsor-carousel owl-carousel owl-theme owl-loaded owl-drag">
              <div class="owl-stage-outer">
                <div
                  class="owl-stage"
                 

                  style={{transform: 'translate3d(0px, 0px, 0px)', transition: 'all', width: '551px'}}
                >
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgP1} alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgP2} alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgP3} alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                </div>
              </div>
              <div class="owl-nav disabled">
                <div class="owl-prev disabled">
                  <span class="flaticon-next-1"></span>
                </div>
                <div class="owl-next disabled">
                  <span class="flaticon-next-1"></span>
                </div>
              </div>
              <div class="owl-dots disabled">
                <div class="owl-dot active">
                  <span></span>
                </div>
              </div>
            </ul>
          </div>

          <div
            class="sponsors-outer gold-sponsor wow fadeInUp animated"
      
          >
            <div class="title-box">
              <h3>Gold Sponsors</h3>
            </div>

            <ul class="sponsor-carousel owl-carousel owl-theme owl-loaded owl-drag">
              <div class="owl-stage-outer">
                <div
                  class="owl-stage"


                  style={{transform: 'translate3d(0px, 0px, 0px)', transition: '0.5s', width: '918px'}}
                >
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgG1}  alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                  <div class="owl-item active" style={{width: '183.6px'}}  >
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgG2}  alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgG3}  alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgG4}  alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgG5} alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                </div>
              </div>
              <div class="owl-nav disabled">
                <div class="owl-prev disabled">
                  <span class="flaticon-next-1"></span>
                </div>
                <div class="owl-next disabled">
                  <span class="flaticon-next-1"></span>
                </div>
              </div>
              <div class="owl-dots disabled">
                <div class="owl-dot active">
                  <span></span>
                </div>
              </div>
            </ul>
          </div>

          <div
            class="sponsors-outer silver-sponsor wow fadeInUp animated"
      
          >
            <div class="title-box">
              <h3>Silver Sponsors</h3>
            </div>

            <ul class="sponsor-carousel owl-carousel owl-theme owl-loaded owl-drag">
              <div class="owl-stage-outer">
                <div
                  class="owl-stage"
                

                  style={{transform: 'translate3d(0px, 0px, 0px)', transition: 'all', width: '918px'}}
                >
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgS1} alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgS2} alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgS3} alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgS4} alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                  <div class="owl-item active" style={{width: '183.6px'}}>
                    <li class="slide-item">
                      <figure class="image">
                        <a href="#">
                          <img src={imgS5} alt="" />
                        </a>
                      </figure>
                    </li>
                  </div>
                </div>
              </div>
              <div class="owl-nav disabled">
                <div class="owl-prev disabled">
                  <span class="flaticon-next-1"></span>
                </div>
                <div class="owl-next disabled">
                  <span class="flaticon-next-1"></span>
                </div>
              </div>
              <div class="owl-dots disabled">
                <div class="owl-dot active">
                  <span></span>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </section>

      <footer class="main-footerz">

        <div class="widgets-section">
            <div class="auto-container">
                <div class="row">
                    <div class="big-column col-xl-5 col-lg-12 col-md-12">
                 
                        <div class="footer-column about-widget">
                            <h2 class="widget-title">Event Detail</h2>
                            <div class="widget-content">
                                <div class="date">January 24 to 27, 2019</div>
                                <div class="address">Mirpur Dosh, Dhaka Street New York, USA</div>

                            </div>
                        </div>
                    </div>

                    <div class="big-column col-xl-7 col-lg-12 col-md-12">
                        <div class="row">
                            <div class="footer-column col-lg-5 col-md-6 col-sm-12">
                                <div class="footer-widget links-widget">
                                    <h2 class="widget-title">Useful Links</h2>
                                    <div class="widget-content">
                                        <ul class="list">
                                            <li><a href="#">Home</a></li>
                                            <li><a href="#">Speakers</a></li>
                                            <li><a href="#">Schedule</a></li>
                                            <li><a href="#">Register</a></li>
                                    
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="footer-column col-lg-7 col-md-6 col-sm-12">
                                <div class="footer-widget social-widget">
                                    <h2 class="widget-title">Social Updates</h2>
                                    <div class="widget-content">
                                        <div class="text">Cheeky bugger a blinding shot jolly good lavatory vagabond easy peasy.!</div>
                                        <ul class="social-icon-one">
                                            <li><a href="#"><span class="fab fa-facebook-f"></span></a></li>
                                            <li><a href="#"><span class="fab fa-twitter"></span></a></li>
                                            <li><a href="#"><span class="fab fa-vimeo-v"></span></a></li>
                                            <li><a href="#"><span class="fab fa-linkedin-in"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
   
        <div class="footer-bottom">
            <div class="auto-container">
                <div class="copyright-text">© Copyright 2025 Designed by <a href="#">eConnect</a></div>
            </div>
        </div>
        
    </footer>
    </div>
  );
};

export default EventDetailsz;
