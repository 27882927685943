import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";

import logo from "../dashboardstyle/images/CONNECT white.png";

import { auth } from "../firebase";

const AttendeeHeader = ({ activeTab }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [user, setUser] = useState({ firstName: "Foo", lastName: "Bar" });

  useEffect(() => {
    // Fetch the current user from Firebase Authentication
    const currentUser = auth.currentUser;

    if (currentUser) {
      // Assuming displayName is stored in the format "First Last"
      const displayName = currentUser.displayName || "Foo Bar";
      const [firstName, lastName] = displayName.split(" ");

      setUser({ firstName, lastName });
    }
  }, []);
  return (
    <div>
      <div className="nav-header">
        <a href="" className="brand-logo">
        <img src={logo}  />
        </a>
        <div className="nav-control">
          <div className="hamburger">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>

      <div class="header">
        <div class="header-content">
          <nav class="navbar navbar-expand">
            <div class="collapse navbar-collapse justify-content-between">
              <div class="header-left">
                <div class="dashboard_bar">{activeTab}</div>
              </div>
              <ul class="navbar-nav header-right">
                {/* <li class="nav-item d-flex align-items-center">
                  <div class="input-group search-area">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search here..."
                    />
                    <span class="input-group-text">
                      <a href="javascript:void(0)">
                        <i class="flaticon-381-search-2"></i>
                      </a>
                    </span>
                  </div>
                </li> */}
                <li class="nav-item d-flex align-items-center">
                  <div class="">
                    <span>
                   Hi, <strong> {`${user.firstName} ${user.lastName}`}</strong>
                    </span>
                   
                   
                  </div>
                </li> 



                {/* <li class="nav-item dropdown notification_dropdown">
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.3333 19.8333H23.1187C23.2568 19.4597 23.3295 19.065 23.3333 18.6666V12.8333C23.3294 10.7663 22.6402 8.75902 21.3735 7.12565C20.1068 5.49228 18.3343 4.32508 16.3333 3.80679V3.49996C16.3333 2.88112 16.0875 2.28763 15.6499 1.85004C15.2123 1.41246 14.6188 1.16663 14 1.16663C13.3812 1.16663 12.7877 1.41246 12.3501 1.85004C11.9125 2.28763 11.6667 2.88112 11.6667 3.49996V3.80679C9.66574 4.32508 7.89317 5.49228 6.6265 7.12565C5.35983 8.75902 4.67058 10.7663 4.66667 12.8333V18.6666C4.67053 19.065 4.74316 19.4597 4.88133 19.8333H4.66667C4.35725 19.8333 4.0605 19.9562 3.84171 20.175C3.62292 20.3938 3.5 20.6905 3.5 21C3.5 21.3094 3.62292 21.6061 3.84171 21.8249C4.0605 22.0437 4.35725 22.1666 4.66667 22.1666H23.3333C23.6428 22.1666 23.9395 22.0437 24.1583 21.8249C24.3771 21.6061 24.5 21.3094 24.5 21C24.5 20.6905 24.3771 20.3938 24.1583 20.175C23.9395 19.9562 23.6428 19.8333 23.3333 19.8333Z"
                        fill="#717579"
                      />
                      <path
                        d="M9.9819 24.5C10.3863 25.2088 10.971 25.7981 11.6766 26.2079C12.3823 26.6178 13.1838 26.8337 13.9999 26.8337C14.816 26.8337 15.6175 26.6178 16.3232 26.2079C17.0288 25.7981 17.6135 25.2088 18.0179 24.5H9.9819Z"
                        fill="#717579"
                      />
                    </svg>
                    <span class="badge light text-white bg-warning rounded-circle">
                      12
                    </span>
                  </a>
                </li>
                <li class="nav-item dropdown notification_dropdown">
                  <a class="nav-link bell-link " href="javascript:void(0);">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.076 6.24662C26.962 5.48439 26.5787 4.78822 25.9955 4.28434C25.4123 3.78045 24.6679 3.50219 23.8971 3.5H4.10289C3.33217 3.50219 2.58775 3.78045 2.00456 4.28434C1.42137 4.78822 1.03803 5.48439 0.924011 6.24662L14 14.7079L27.076 6.24662Z"
                        fill="#717579"
                      />
                      <path
                        d="M14.4751 16.485C14.3336 16.5765 14.1686 16.6252 14 16.6252C13.8314 16.6252 13.6664 16.5765 13.5249 16.485L0.875 8.30025V21.2721C0.875926 22.1279 1.2163 22.9484 1.82145 23.5536C2.42659 24.1587 3.24707 24.4991 4.10288 24.5H23.8971C24.7529 24.4991 25.5734 24.1587 26.1786 23.5536C26.7837 22.9484 27.1241 22.1279 27.125 21.2721V8.29938L14.4751 16.485Z"
                        fill="#717579"
                      />
                    </svg>
                    <span class="badge light text-white bg-danger rounded-circle">
                      76
                    </span>
                  </a>
                </li> */}

                <li className="nav-item dropdown notification_dropdown profile-menu">
                  <a
                    className="nav-link"
                    role="button"
                    onClick={toggleDropdown}
                  >
                    <Avatar
                      name={`${user.firstName} ${user.lastName}`}
                      size="42"
                      color={"#8d14ff"}
                      round={true}
                    />
                  </a>
                  {dropdownOpen && (
                    <div className="dropdown-menu dropdown-menu-endz">
                      <a
                        href="app-profile.html"
                        className="dropdown-item ai-icon"
                      >
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        <span className="ms-2">Profile </span>
                      </a>
                      <a
                        href="email-inbox.html"
                        className="dropdown-item ai-icon"
                      >
                        <svg
                          id="icon-inbox"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-success"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                          <polyline points="22,6 12,13 2,6"></polyline>
                        </svg>
                        <span className="ms-2">Inbox </span>
                      </a>
                      <a
                        href="page-login.html"
                        className="dropdown-item ai-icon"
                      >
                        <svg
                          id="icon-logout"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-danger"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                          <polyline points="16 17 21 12 16 7"></polyline>
                          <line x1="21" y1="12" x2="9" y2="12"></line>
                        </svg>
                        <span className="ms-2">Logout </span>
                      </a>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AttendeeHeader;
