import React, { useState, useMemo, useEffect } from "react";
import { Rating } from 'react-simple-star-rating'
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from "@vis.gl/react-maplibre";
import "maplibre-gl/dist/maplibre-gl.css";
import ControlPanel from "../components/ControlPanel";
import Pin from "../components/Pin";
import Cookies from "js-cookie";
import StaticHeader from "../components/StaticHeader";

import imgList from '../../src/dashboardstyle/images/16.jpg'

import logo1 from '../../src/dashboardstyle/images/dummylogo/logoipsum-363.svg'
import logo2 from '../../src/dashboardstyle/images/dummylogo/logoipsum-292 (1).svg'
import logo3 from '../../src/dashboardstyle/images/dummylogo/logoipsum-295 (1).svg'
import logo4 from '../../src/dashboardstyle/images/dummylogo/logoipsum-331 (1).svg'
import logo5 from '../../src/dashboardstyle/images/dummylogo/logoipsum-298.svg'
import logo6 from '../../src/dashboardstyle/images/dummylogo/logoipsum-331 (1).svg'
import logo7 from '../../src/dashboardstyle/images/dummylogo/logoipsum-365.svg'



const pinStyle = {
  cursor: "pointer",
  color: "yellow",
  fontSize: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background:
    "linear-gradient(to bottom, rgba(242, 4, 135, 1) 0%, rgba(162, 0, 89, 1) 100%)",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
};

const locations = [
  { id: 1, name: "Marky's Restaurant", lat: -26.2041, lng: 28.0473, category: "Restaurant", logo: logo1, rating: 4.5, reviews: 120 },
  { id: 2, name: "Big Pizza for Couples", lat: -26.2100, lng: 28.0499, category: "Restaurant", logo: logo2, rating: 4.2, reviews: 95 },
  { id: 3, name: "Thai Massage", lat: -26.1900, lng: 28.0300, category: "Wellness", logo: logo3, rating: 4.8, reviews: 80  },
  { id: 4, name: "High Mountain Trip", lat: -26.1800, lng: 28.0600, category: "Trip", logo: logo4, rating: 4.9, reviews: 150 },
  { id: 5, name: "Golf Lesson", lat: -26.2200, lng: 28.0200, category: "Adrenaline", logo: logo5, rating: 4.0, reviews: 60 },
  { id: 6, name: "Hyundai i30", lat: -26.1950, lng: 28.0400, category: "Car", logo: logo6, rating: 3.9, reviews: 45 },
  { id: 7, name: "Bambi Planet Houseboat", lat: -26.2300, lng: 28.0350, category: "Hotel", logo: logo7, rating: 4.7, reviews: 110},
];

const ExploreOrganisations = () => {
  const [popupInfo, setPopupInfo] = useState(null);
  const [mapStyle, setMapStyle] = useState(
    "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
  );

  useEffect(() => {
    const updateMapStyle = () => {
      const savedTheme = Cookies.get("theme-version");
      const isDark = savedTheme === "dark";
      setMapStyle(
        isDark
          ? "https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json"
          : "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
      );
    };
    updateMapStyle();

    const observer = new MutationObserver(updateMapStyle);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["data-theme-version"],
    });

    return () => observer.disconnect();
  }, []);

  const pins = useMemo(
    () =>
      locations.map((loc, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={loc.lng}
          latitude={loc.lat}
          anchor="bottom"
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            setPopupInfo(loc);
          }}
        >
          <Pin style={pinStyle} />
        </Marker>
      )),
    []
  );

  return (
    <div className="" style={{ position: "relative" }}>
      <StaticHeader />
      <Map
        initialViewState={{ latitude: -26.2041, longitude: 28.0473, zoom: 12 }}
        style={{ width: "100%", height: "80vh", opacity: 0.8 }}
        mapStyle={mapStyle}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />

        {pins}

        {popupInfo && (
         <Popup
         anchor="top"
         longitude={popupInfo.lng}
         latitude={popupInfo.lat}
         onClose={() => setPopupInfo(null)}
         className="popup-container"
       >
         <div className="modern-popup">

           <img src={popupInfo.logo} alt={popupInfo.name} className="popup-logo" />
           <h4 className="popup-title"><a href="/organisation-details">{popupInfo.name}</a></h4>
           <span className="popup-category">{popupInfo.category}</span>
           <div className="popup-rating">
             {[...Array(5)].map((_, index) => (
               <i key={index} className={index < Math.round(popupInfo.rating) ? "fas fa-star" : "far fa-star"}></i>
             ))}
             <br/>
             <span className="popup-reviews">({popupInfo.reviews} reviews)</span>
           </div>
          
         </div>
       </Popup>
        )}
      </Map>

      <section className="map-filter" style={{ padding: "10px", position: 'relative' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="card card-map" style={{ borderRadius: "10px" }}>
                <div className="card-body">
                  <div className="basic-form">
                    <form>
                      {/* First Row: Input Fields */}
                      <div className="row g-2 align-items-center">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Keyword"
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <select name="" className="form-control" required>
                            <option value="">Category</option>
                            <option value="">Restaurants</option>
                            <option value="">Hotels</option>
                            <option value="">Fitness</option>
                            <option value="">Coffee Shop</option>
                          </select>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <select name="" className="form-control" required>
                            <option value="">Location</option>
                            <option value="">Gauteng</option>
                            <option value="">Limpopo</option>
                            <option value="">Cape Town</option>
                            <option value="">Eastern Cape</option>
                          </select>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-primary form-control gradient-btn"
                          >
                            Search <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      

      <section class="gray-bg small-padding no-top-padding-sec" style={{position: 'relative'}}>
                        <div class="container big-container">
                          
                            <div class="breadcrumbs inline-breadcrumbs fl-wrap block-breadcrumbs text-center align-items-center">
                                <div className="row">

                              <div className="col-lg-6">  <span >Explore All Organisations</span></div>
                          
                         <div className="col-lg-6">
                         <div class="price-opt">
                                              <p class="price-opt-title">Sort   by:</p>
                                              <div class="listsearch-input-item">
                                                  <select data-placeholder="Popularity" class="chosen-select no-search-select">
                                                      <option>Popularity</option>
                                                      <option>Average rating</option>
                                                     
                                                  </select>
                                              </div>
                                          </div>
                         </div>
                                </div>
                            </div>

                            


                         <div className="text-end text-right">
                   
                         </div>


                            
                            <div class="fl-wrap">
                                <div class="mob-nav-content-btn mncb_half color2-bg show-list-wrap-search ntm fl-wrap"><i class="fas fa-filter"></i>  Filters</div>
                                <div class="mob-nav-content-btn mncb_half color2-bg schm ntm fl-wrap"><i class="fas fa-map-marker-alt"></i>  View on map</div>
                                <div class="clearfix"></div>


                                <div class="row d-flex justify-content-center">


                                    <div class="col-md-3 ">
                                        <div class=" fl-wrap  lws_mobile  tabs-act block_box position-sticky">
                                            <div class="filter-sidebar-header fl-wrap" id="filters-column">
                                                <ul class="tabs-menu fl-wrap no-list-style">
                                                    <li class="current"><a > <i class="fas fa-sliders-h"></i> Filters </a></li>

                                                </ul>
                                            </div>
                                            <div class="scrl-content filter-sidebar    fs-viscon">
                                                            
                                                <div class="tabs-container fl-wrap">
                                                    
                                                    <div class="tab">


                                                        <div id="filters-search" class="tab-content  first-tab ">
                                                            
                                                            <div class="listsearch-input-item">
                                                                <span class="iconn-dec"><i class="far fa-bookmark"></i></span>
                                                                <input type="text" placeholder="What are you looking for ?" value=""/>
                                                            </div>
                                                          
                                                            
              
                                                    
                                                                                                                
                                                            
                                                            <div class="listsearch-input-item location autocomplete-container">
                                                                <span class="iconn-dec"><i class="fas fa-map-marker"></i></span>
                                                                <input type="text" placeholder="Where to look?" class="autocomplete-input" id="autocompleteid3" value=""/>
                                                                <a href="#"><i class="fal fa-location"></i></a>
                                                            </div>
                                                                                                                  
                                                            
                                                       
                                                
                                                                                                                                    
                                                           

                                                            
                                                                                                               
                                                            
                                                          
                                                                                                                
                                                          
                                                        </div>
                                                        



                                                        <div id="filters-search" class="tab-content  first-tab ">
                                                            
                                                          
                                                            
                                                            <div class="listsearch-input-item custom-wrapper">

                                                                <select data-placeholder="Categories" class="chosen-select no-search-select" >

                                                                    <option>All Categories</option>
                                                                    <option>Shops</option>
                                                                    <option>Hotels</option>
                                                                    <option>Restaurants</option>
                                                                    <option>Fitness</option>
                                                                    <option>Events</option>
                                                                </select>
                                                            </div>
                                                         
                                                            
                                                            <div class="listsearch-input-item">
                                                                <select data-placeholder="City/Location" class="chosen-select no-search-select" >
                                                                    <option>All Province</option>
                                                                    <option>Gauteng</option>
                                                                    <option>Limpopo</option>
                                                                    <option>North West</option>
                                                                    <option>Mpumalanga</option>
                                                                    <option>Cape Town</option>
                                                                </select>
                                                            </div>
                                                                                                                
                                                            <div class="clear-filter-btnz"><i class="fas fa-redo"></i>Explore Services</div>
                                                            <br/>
                                                            <div class="listsearch-input-item clact">
                                                                <div class=" fl-wrap filter-tags">
                                                                    <ul class="no-list-style">
                                                                        <li>
                                                                            <input id="check-aa" type="checkbox" name="check"/>
                                                                            <label for="check-aa">IT Services</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="check-b" type="checkbox" name="check"/>
                                                                            <label for="check-b">Construction</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="check-c" type="checkbox" name="check"/>
                                                                            <label for="check-c">Education</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="check-d" type="checkbox" name="check"/>
                                                                            <label for="check-d">Healthcare</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="check-d2" type="checkbox" name="check"/> 
                                                                            <label for="check-d2">Legal Services</label> 
                                                                        </li>
                                                                        <li>
                                                                            <input id="check-d3" type="checkbox" name="check" />
                                                                            <label for="check-d3">Financial Services</label> 
                                                                        </li>
                                                                     
                                                                    </ul>

                                                                    
                                                                </div>
                                                            </div>
                                                         
                                                                                                                  
                                                            
                                                           
                                                             
                                                      
                                                                                                                                    
                                                            
                                                          
                                                            
                                                                                                               
                                                            
                                                          
                                                            <div class="listsearch-input-item">
                                                                <button class="toggle-filter-btn tsb_act "><i class="fas fa-search"></i> <span>Search</span></button>
                                                            </div>                                         
                                                      
                                                        </div>
                                                    </div>
                                                    
                                                     
                                                   
                                                    
                                                </div>
                                                                        
                                            </div>
                                        </div>
                                
                                    </div>

                                    
                                    <div class="col-md-8">
                                        
                           
                                                                    
                                        
                                        <div class="listing-item-container three-columns-grid  init-grid-items fl-wrap nocolumn-lic">
                                           
                                            <div class="listing-item">
                                                <article class="geodir-category-listing fl-wrap">
                                                    <div class="geodir-category-img">

                                                        <a href="/organisation-details" class="geodir-category-img-wrap fl-wrap">
                                                        <img src={imgList} alt=""/> 
                                                        </a>
                                                        <div class="listing-avatar"><a href="/organisation-details"><img src={logo5} alt=""/></a>

                                                        </div>
                                                        <div class="geodir_status_date gsd_open color-bg"><i class="fas fa-check-circle"></i>Verified</div>
                                                        <div class="geodir-category-opt">
                                                            <div class="listing-rating-count-wrap">
                                                                <div class="review-score">4.1</div>
                                                                <div class="listing-rating card-popup-rainingvis">
                                                                <div
  style={{
    direction: 'ltr',
    fontFamily: 'sans-serif',
    touchAction: 'none'
  }}
>
  <Rating
    initialValue={4}
    onClick={function noRefCheck(){}}
    size={20}
    allowFractions
    readonly
  />
</div>
<div class="reviews-count">6 reviews</div>
                                                                </div>
                                                                                                       
                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="geodir-category-content fl-wrap">
                                                        <div class="geodir-category-content-title fl-wrap">
                                                            <div class="geodir-category-content-title-item">
                                                                <h3 class="title-sin_map"><a href="/organisation-details">Luxary Resaturant</a><span class="verified-badge"><i class="fal fa-check"></i></span></h3>
                                                                <div class="geodir-category-location fl-wrap"><a href="#1" class="map-item"><i class="fas fa-map-marker-alt"></i> 27th Brooklyn New York, USA</a></div>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-text fl-wrap">
                                                            <p class="small-text">Sed interdum metus at nisi tempor laoreet. Integer gravida orci a justo sodales.</p>
                                                            <div class="facilities-list fl-wrap">
                                                                <div class="facilities-list-title">Socials: </div>
                                                                <ul class="no-list-style">
                                                                    <li class="tolt facebook-iconz"  ><i class="fab fa-facebook"></i></li>
                                                                    <li class="tolt twitter-iconz" ><i class="fa-brands fa-x-twitter"></i></li>
                                                                    <li class="tolt linkedin-iconz"  ><i class="fab fa-linkedin"></i></li>
                                                                    <li class="tolt instagram-iconz"  ><i class="fab fa-instagram"></i></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-footer fl-wrap">
                                                            <a class="listing-item-category-wrap">
                                                                <div class="listing-item-category text-center red-bg-sec"><i class="fas fa-briefcase"></i></div>
                                                                <span>Restaurants</span>
                                                            </a>
                                                            <div class="geodir-opt-list">
                                                                <ul class="no-list-style">
                                                                    <li><a href="#" class="show_gcc text-center"><i class="fas fa-envelope"></i><span class="geodir-opt-tooltip">Contact Info</span></a></li>
                                                                    <li><a href="#1" class="map-item text-center"><i class="fas fa-map-marker-alt"></i><span class="geodir-opt-tooltip">View On the map</span> </a></li>
                                                                 
                                                                </ul>
                                                            </div>
                                                            <div class="price-level geodir-category_price">
                                                                <span class="price-level-item" data-pricerating="3"></span>
                                                                <span class="price-name-tooltip">Pricey</span>
                                                            </div>
                                                            <div class="geodir-category_contacts">
                                                                <div class="close_gcc"><i class="fal fa-times-circle"></i></div>
                                                                <ul class="no-list-style">
                                                                    <li><span><i class="fas fa-phone"></i> Call : </span><a href="#">+38099231212</a></li>
                                                                    <li><span><i class="fas fa-envelope"></i> Write : </span><a href="#">yourmail@domain.com</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div class="listing-item">
                                                <article class="geodir-category-listing fl-wrap">
                                                    <div class="geodir-category-img">

                                                        <a href="/organisation-details" class="geodir-category-img-wrap fl-wrap">
                                                        <img src={imgList} alt=""/> 
                                                        </a>
                                                        <div class="listing-avatar"><a href="/organisation-details"><img src={logo5} alt=""/></a>

                                                        </div>
                                                        <div class="geodir_status_date gsd_open color-bg"><i class="fas fa-check-circle"></i>Verified</div>
                                                        <div class="geodir-category-opt">
                                                            <div class="listing-rating-count-wrap">
                                                                <div class="review-score">4.1</div>
                                                                <div class="listing-rating card-popup-rainingvis">
                                                                <div
  style={{
    direction: 'ltr',
    fontFamily: 'sans-serif',
    touchAction: 'none'
  }}
>
  <Rating
    initialValue={4}
    onClick={function noRefCheck(){}}
    size={20}
    allowFractions
    readonly
  />
</div>
<div class="reviews-count">6 reviews</div>
                                                                </div>
                                                                                                       
                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="geodir-category-content fl-wrap">
                                                        <div class="geodir-category-content-title fl-wrap">
                                                            <div class="geodir-category-content-title-item">
                                                                <h3 class="title-sin_map"><a href="/organisation-details">Luxary Resaturant</a><span class="verified-badge"><i class="fal fa-check"></i></span></h3>
                                                                <div class="geodir-category-location fl-wrap"><a href="#1" class="map-item"><i class="fas fa-map-marker-alt"></i> 27th Brooklyn New York, USA</a></div>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-text fl-wrap">
                                                            <p class="small-text">Sed interdum metus at nisi tempor laoreet. Integer gravida orci a justo sodales.</p>
                                                            <div class="facilities-list fl-wrap">
                                                                <div class="facilities-list-title">Socials: </div>
                                                                <ul class="no-list-style">
                                                                    <li class="tolt facebook-iconz"  ><i class="fab fa-facebook"></i></li>
                                                                    <li class="tolt twitter-iconz" ><i class="fa-brands fa-x-twitter"></i></li>
                                                                    <li class="tolt linkedin-iconz"  ><i class="fab fa-linkedin"></i></li>
                                                                    <li class="tolt instagram-iconz"  ><i class="fab fa-instagram"></i></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-footer fl-wrap">
                                                            <a class="listing-item-category-wrap">
                                                                <div class="listing-item-category text-center red-bg-sec"><i class="fas fa-briefcase"></i></div>
                                                                <span>Restaurants</span>
                                                            </a>
                                                            <div class="geodir-opt-list">
                                                                <ul class="no-list-style">
                                                                    <li><a href="#" class="show_gcc text-center"><i class="fas fa-envelope"></i><span class="geodir-opt-tooltip">Contact Info</span></a></li>
                                                                    <li><a href="#1" class="map-item text-center"><i class="fas fa-map-marker-alt"></i><span class="geodir-opt-tooltip">View On the map</span> </a></li>
                                                                 
                                                                </ul>
                                                            </div>
                                                            <div class="price-level geodir-category_price">
                                                                <span class="price-level-item" data-pricerating="3"></span>
                                                                <span class="price-name-tooltip">Pricey</span>
                                                            </div>
                                                            <div class="geodir-category_contacts">
                                                                <div class="close_gcc"><i class="fal fa-times-circle"></i></div>
                                                                <ul class="no-list-style">
                                                                    <li><span><i class="fas fa-phone"></i> Call : </span><a href="#">+38099231212</a></li>
                                                                    <li><span><i class="fas fa-envelope"></i> Write : </span><a href="#">yourmail@domain.com</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div class="listing-item">
                                                <article class="geodir-category-listing fl-wrap">
                                                    <div class="geodir-category-img">

                                                        <a href="/organisation-details" class="geodir-category-img-wrap fl-wrap">
                                                        <img src={imgList} alt=""/> 
                                                        </a>
                                                        <div class="listing-avatar"><a href="/organisation-details"><img src={logo5} alt=""/></a>

                                                        </div>
                                                        <div class="geodir_status_date gsd_open color-bg"><i class="fas fa-check-circle"></i>Verified</div>
                                                        <div class="geodir-category-opt">
                                                            <div class="listing-rating-count-wrap">
                                                                <div class="review-score">4.1</div>
                                                                <div class="listing-rating card-popup-rainingvis">
                                                                <div
  style={{
    direction: 'ltr',
    fontFamily: 'sans-serif',
    touchAction: 'none'
  }}
>
  <Rating
    initialValue={4}
    onClick={function noRefCheck(){}}
    size={20}
    allowFractions
    readonly
  />
</div>
<div class="reviews-count">6 reviews</div>
                                                                </div>
                                                                                                       
                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="geodir-category-content fl-wrap">
                                                        <div class="geodir-category-content-title fl-wrap">
                                                            <div class="geodir-category-content-title-item">
                                                                <h3 class="title-sin_map"><a href="/organisation-details">Luxary Resaturant</a><span class="verified-badge"><i class="fal fa-check"></i></span></h3>
                                                                <div class="geodir-category-location fl-wrap"><a href="#1" class="map-item"><i class="fas fa-map-marker-alt"></i> 27th Brooklyn New York, USA</a></div>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-text fl-wrap">
                                                            <p class="small-text">Sed interdum metus at nisi tempor laoreet. Integer gravida orci a justo sodales.</p>
                                                            <div class="facilities-list fl-wrap">
                                                                <div class="facilities-list-title">Socials: </div>
                                                                <ul class="no-list-style">
                                                                    <li class="tolt facebook-iconz"  ><i class="fab fa-facebook"></i></li>
                                                                    <li class="tolt twitter-iconz" ><i class="fa-brands fa-x-twitter"></i></li>
                                                                    <li class="tolt linkedin-iconz"  ><i class="fab fa-linkedin"></i></li>
                                                                    <li class="tolt instagram-iconz"  ><i class="fab fa-instagram"></i></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-footer fl-wrap">
                                                            <a class="listing-item-category-wrap">
                                                                <div class="listing-item-category text-center red-bg-sec"><i class="fas fa-briefcase"></i></div>
                                                                <span>Restaurants</span>
                                                            </a>
                                                            <div class="geodir-opt-list">
                                                                <ul class="no-list-style">
                                                                    <li><a href="#" class="show_gcc text-center"><i class="fas fa-envelope"></i><span class="geodir-opt-tooltip">Contact Info</span></a></li>
                                                                    <li><a href="#1" class="map-item text-center"><i class="fas fa-map-marker-alt"></i><span class="geodir-opt-tooltip">View On the map</span> </a></li>
                                                                 
                                                                </ul>
                                                            </div>
                                                            <div class="price-level geodir-category_price">
                                                                <span class="price-level-item" data-pricerating="3"></span>
                                                                <span class="price-name-tooltip">Pricey</span>
                                                            </div>
                                                            <div class="geodir-category_contacts">
                                                                <div class="close_gcc"><i class="fal fa-times-circle"></i></div>
                                                                <ul class="no-list-style">
                                                                    <li><span><i class="fas fa-phone"></i> Call : </span><a href="#">+38099231212</a></li>
                                                                    <li><span><i class="fas fa-envelope"></i> Write : </span><a href="#">yourmail@domain.com</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div class="listing-item">
                                                <article class="geodir-category-listing fl-wrap">
                                                    <div class="geodir-category-img">

                                                        <a href="/organisation-details" class="geodir-category-img-wrap fl-wrap">
                                                        <img src={imgList} alt=""/> 
                                                        </a>
                                                        <div class="listing-avatar"><a href="/organisation-details"><img src={logo5} alt=""/></a>

                                                        </div>
                                                        <div class="geodir_status_date gsd_open color-bg"><i class="fas fa-check-circle"></i>Verified</div>
                                                        <div class="geodir-category-opt">
                                                            <div class="listing-rating-count-wrap">
                                                                <div class="review-score">4.1</div>
                                                                <div class="listing-rating card-popup-rainingvis">
                                                                <div
  style={{
    direction: 'ltr',
    fontFamily: 'sans-serif',
    touchAction: 'none'
  }}
>
  <Rating
    initialValue={4}
    onClick={function noRefCheck(){}}
    size={20}
    allowFractions
    readonly
  />
</div>
<div class="reviews-count">6 reviews</div>
                                                                </div>
                                                                                                       
                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="geodir-category-content fl-wrap">
                                                        <div class="geodir-category-content-title fl-wrap">
                                                            <div class="geodir-category-content-title-item">
                                                                <h3 class="title-sin_map"><a href="/organisation-details">Luxary Resaturant</a><span class="verified-badge"><i class="fal fa-check"></i></span></h3>
                                                                <div class="geodir-category-location fl-wrap"><a href="#1" class="map-item"><i class="fas fa-map-marker-alt"></i> 27th Brooklyn New York, USA</a></div>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-text fl-wrap">
                                                            <p class="small-text">Sed interdum metus at nisi tempor laoreet. Integer gravida orci a justo sodales.</p>
                                                            <div class="facilities-list fl-wrap">
                                                                <div class="facilities-list-title">Socials: </div>
                                                                <ul class="no-list-style">
                                                                    <li class="tolt facebook-iconz"  ><i class="fab fa-facebook"></i></li>
                                                                    <li class="tolt twitter-iconz" ><i class="fa-brands fa-x-twitter"></i></li>
                                                                    <li class="tolt linkedin-iconz"  ><i class="fab fa-linkedin"></i></li>
                                                                    <li class="tolt instagram-iconz"  ><i class="fab fa-instagram"></i></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-footer fl-wrap">
                                                            <a class="listing-item-category-wrap">
                                                                <div class="listing-item-category text-center red-bg-sec"><i class="fas fa-briefcase"></i></div>
                                                                <span>Restaurants</span>
                                                            </a>
                                                            <div class="geodir-opt-list">
                                                                <ul class="no-list-style">
                                                                    <li><a href="#" class="show_gcc text-center"><i class="fas fa-envelope"></i><span class="geodir-opt-tooltip">Contact Info</span></a></li>
                                                                    <li><a href="#1" class="map-item text-center"><i class="fas fa-map-marker-alt"></i><span class="geodir-opt-tooltip">View On the map</span> </a></li>
                                                                 
                                                                </ul>
                                                            </div>
                                                            <div class="price-level geodir-category_price">
                                                                <span class="price-level-item" data-pricerating="3"></span>
                                                                <span class="price-name-tooltip">Pricey</span>
                                                            </div>
                                                            <div class="geodir-category_contacts">
                                                                <div class="close_gcc"><i class="fal fa-times-circle"></i></div>
                                                                <ul class="no-list-style">
                                                                    <li><span><i class="fas fa-phone"></i> Call : </span><a href="#">+38099231212</a></li>
                                                                    <li><span><i class="fas fa-envelope"></i> Write : </span><a href="#">yourmail@domain.com</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>

                                            <div class="listing-item">
                                                <article class="geodir-category-listing fl-wrap">
                                                    <div class="geodir-category-img">

                                                        <a href="/organisation-details" class="geodir-category-img-wrap fl-wrap">
                                                        <img src={imgList} alt=""/> 
                                                        </a>
                                                        <div class="listing-avatar"><a href="/organisation-details"><img src={logo5} alt=""/></a>

                                                        </div>
                                                        <div class="geodir_status_date gsd_open color-bg"><i class="fas fa-check-circle"></i>Verified</div>
                                                        <div class="geodir-category-opt">
                                                            <div class="listing-rating-count-wrap">
                                                                <div class="review-score">4.1</div>
                                                                <div class="listing-rating card-popup-rainingvis">
                                                                <div
  style={{
    direction: 'ltr',
    fontFamily: 'sans-serif',
    touchAction: 'none'
  }}
>
  <Rating
    initialValue={4}
    onClick={function noRefCheck(){}}
    size={20}
    allowFractions
    readonly
  />
</div>
<div class="reviews-count">6 reviews</div>
                                                                </div>
                                                                                                       
                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="geodir-category-content fl-wrap">
                                                        <div class="geodir-category-content-title fl-wrap">
                                                            <div class="geodir-category-content-title-item">
                                                                <h3 class="title-sin_map"><a href="/organisation-details">Luxary Resaturant</a><span class="verified-badge"><i class="fal fa-check"></i></span></h3>
                                                                <div class="geodir-category-location fl-wrap"><a href="#1" class="map-item"><i class="fas fa-map-marker-alt"></i> 27th Brooklyn New York, USA</a></div>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-text fl-wrap">
                                                            <p class="small-text">Sed interdum metus at nisi tempor laoreet. Integer gravida orci a justo sodales.</p>
                                                            <div class="facilities-list fl-wrap">
                                                                <div class="facilities-list-title">Socials: </div>
                                                                <ul class="no-list-style">
                                                                    <li class="tolt facebook-iconz"  ><i class="fab fa-facebook"></i></li>
                                                                    <li class="tolt twitter-iconz" ><i class="fa-brands fa-x-twitter"></i></li>
                                                                    <li class="tolt linkedin-iconz"  ><i class="fab fa-linkedin"></i></li>
                                                                    <li class="tolt instagram-iconz"  ><i class="fab fa-instagram"></i></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-footer fl-wrap">
                                                            <a class="listing-item-category-wrap">
                                                                <div class="listing-item-category text-center red-bg-sec"><i class="fas fa-briefcase"></i></div>
                                                                <span>Restaurants</span>
                                                            </a>
                                                            <div class="geodir-opt-list">
                                                                <ul class="no-list-style">
                                                                    <li><a href="#" class="show_gcc text-center"><i class="fas fa-envelope"></i><span class="geodir-opt-tooltip">Contact Info</span></a></li>
                                                                    <li><a href="#1" class="map-item text-center"><i class="fas fa-map-marker-alt"></i><span class="geodir-opt-tooltip">View On the map</span> </a></li>
                                                                 
                                                                </ul>
                                                            </div>
                                                            <div class="price-level geodir-category_price">
                                                                <span class="price-level-item" data-pricerating="3"></span>
                                                                <span class="price-name-tooltip">Pricey</span>
                                                            </div>
                                                            <div class="geodir-category_contacts">
                                                                <div class="close_gcc"><i class="fal fa-times-circle"></i></div>
                                                                <ul class="no-list-style">
                                                                    <li><span><i class="fas fa-phone"></i> Call : </span><a href="#">+38099231212</a></li>
                                                                    <li><span><i class="fas fa-envelope"></i> Write : </span><a href="#">yourmail@domain.com</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>

                                            <div class="listing-item">
                                                <article class="geodir-category-listing fl-wrap">
                                                    <div class="geodir-category-img">

                                                        <a href="/organisation-details" class="geodir-category-img-wrap fl-wrap">
                                                        <img src={imgList} alt=""/> 
                                                        </a>
                                                        <div class="listing-avatar"><a href="/organisation-details"><img src={logo5} alt=""/></a>

                                                        </div>
                                                        <div class="geodir_status_date gsd_open color-bg"><i class="fas fa-check-circle"></i>Verified</div>
                                                        <div class="geodir-category-opt">
                                                            <div class="listing-rating-count-wrap">
                                                                <div class="review-score">4.1</div>
                                                                <div class="listing-rating card-popup-rainingvis">
                                                                <div
  style={{
    direction: 'ltr',
    fontFamily: 'sans-serif',
    touchAction: 'none'
  }}
>
  <Rating
    initialValue={4}
    onClick={function noRefCheck(){}}
    size={20}
    allowFractions
    readonly
  />
</div>
<div class="reviews-count">6 reviews</div>
                                                                </div>
                                                                                                       
                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="geodir-category-content fl-wrap">
                                                        <div class="geodir-category-content-title fl-wrap">
                                                            <div class="geodir-category-content-title-item">
                                                                <h3 class="title-sin_map"><a href="/organisation-details">Luxary Resaturant</a><span class="verified-badge"><i class="fal fa-check"></i></span></h3>
                                                                <div class="geodir-category-location fl-wrap"><a href="#1" class="map-item"><i class="fas fa-map-marker-alt"></i> 27th Brooklyn New York, USA</a></div>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-text fl-wrap">
                                                            <p class="small-text">Sed interdum metus at nisi tempor laoreet. Integer gravida orci a justo sodales.</p>
                                                            <div class="facilities-list fl-wrap">
                                                                <div class="facilities-list-title">Socials: </div>
                                                                <ul class="no-list-style">
                                                                    <li class="tolt facebook-iconz"  ><i class="fab fa-facebook"></i></li>
                                                                    <li class="tolt twitter-iconz" ><i class="fa-brands fa-x-twitter"></i></li>
                                                                    <li class="tolt linkedin-iconz"  ><i class="fab fa-linkedin"></i></li>
                                                                    <li class="tolt instagram-iconz"  ><i class="fab fa-instagram"></i></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="geodir-category-footer fl-wrap">
                                                            <a class="listing-item-category-wrap">
                                                                <div class="listing-item-category text-center red-bg-sec"><i class="fas fa-briefcase"></i></div>
                                                                <span>Restaurants</span>
                                                            </a>
                                                            <div class="geodir-opt-list">
                                                                <ul class="no-list-style">
                                                                    <li><a href="#" class="show_gcc text-center"><i class="fas fa-envelope"></i><span class="geodir-opt-tooltip">Contact Info</span></a></li>
                                                                    <li><a href="#1" class="map-item text-center"><i class="fas fa-map-marker-alt"></i><span class="geodir-opt-tooltip">View On the map</span> </a></li>
                                                                 
                                                                </ul>
                                                            </div>
                                                            <div class="price-level geodir-category_price">
                                                                <span class="price-level-item" data-pricerating="3"></span>
                                                                <span class="price-name-tooltip">Pricey</span>
                                                            </div>
                                                            <div class="geodir-category_contacts">
                                                                <div class="close_gcc"><i class="fal fa-times-circle"></i></div>
                                                                <ul class="no-list-style">
                                                                    <li><span><i class="fas fa-phone"></i> Call : </span><a href="#">+38099231212</a></li>
                                                                    <li><span><i class="fas fa-envelope"></i> Write : </span><a href="#">yourmail@domain.com</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                                                                  
                                           
                                          
                                        </div>
                                        
                                        <div className="text-center paginsation">
                                            <div class="pagination fwmpag">
                                                <a href="#" class="prevposts-link"><i class="fas fa-caret-left"></i><span>Prev</span></a>
                                                <a href="#">1</a>
                                                <a href="#" class="current-page">2</a>
                                                <a href="#">3</a>
                                                <a href="#">...</a>
                                                <a href="#">7</a>
                                                <a href="#" class="nextposts-link"><span>Next</span><i class="fas fa-caret-right"></i></a>
                                            </div>
                                            </div>
                                    </div>
                                    
                                </div>

                                                                                                        
                              
                                
                            </div>
                     
                        </div>


                        
                    </section>

    </div>
  );
};

export default ExploreOrganisations;
