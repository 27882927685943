import React from "react";
import StaticHeader from "../components/StaticHeader";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import imgDetails from "../../src/images/6.jpg";
import { Rating } from "react-simple-star-rating";


import imgRelated from "../../src/images/1.png";

import imgGall1 from "../../src/images/24.jpg";

import imgGall2 from "../../src/images/12.jpg";

import imgVPromo from "../../src/images/48.jpg";

import avaR from "../../src/images/4 (1).jpg";
import avaR2 from "../../src/images/rev6.jpg";
import CountUp from "react-countup";



const OrganisationDetails = () => {
  return (
    <div>
      <StaticHeader />

      <section
        class="listing-hero-section hidden-section diff-pos"
        data-scrollax-parent="true"
        id="sec1"
      >
        <div class="bg-parallax-wrap">
          <div
            class="bg par-elem "
            data-bg="images/bg/6.jpg"
            style={{
              backgroundImage: `url(${imgDetails})`,
              transform: "translateZ(0px) translateY(-3.56612%)",
            }}
            data-scrollax="properties: { translateY: '30%' }"
          ></div>
          <div class="overlay"></div>
        </div>
        <div class="container">
          <div class="list-single-header-item  fl-wrap">
            
            <div class="row">
              
              <div class="col-md-9">
                
                <h1>
                  Iconic Cafe in Manhattan{" "}
                  <span class="verified-badge color-bg">
                    <i class="fas fa-check"></i>
                  </span>
                </h1>
                <div class="geodir-category-location fl-wrap">
                  <a href="#">
                    <i class="fas fa-map-marker-alt"></i> 57 Sloane Street, The
                    Campus, Bryanston
                  </a>{" "}
                  <a href="#">
                    {" "}
                    <i class="fas fa-phone"></i>+27 11 234 5678
                  </a>{" "}
                  <a href="#">
                    <i class="fas fa-envelope"></i> info@thinkt.co.za
                  </a>
                </div>
              </div>
              <div class="col-md-3">
                <a
                  class="fl-wrap list-single-header-column custom-scroll-link "
                  href="#sec5"
                >
                  <i className="fas fa-comments"></i>
                  <div class="listing-rating-count-wrap single-list-count">
                    <div class="review-score">4.1</div>
                    <div class="listing-ratingz card-popup-rainingvis">
                      <div
                        style={{
                          direction: "ltr",
                          fontFamily: "sans-serif",
                          touchAction: "none",
                        }}
                      >
                        <Rating
                          initialValue={4}
                          onClick={function noRefCheck() {}}
                          size={20}
                          allowFractions
                          readonly
                        />
                      </div>
                    </div>

                    <div class="reviews-count">2 reviews</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="list-single-header_bottom fl-wrap">
            <a class="listing-item-category-wrap" href="#">
              <div class="listing-item-category text-center  red-bg-sec">
                <i class="fas fa-briefcase"></i>
              </div>
              <span>Restaurants</span>
            </a>

            <div class="list-single-stats">
              <ul class="no-list-style">
                <li>
                  <span class="viewed-counter">
                    <i class="fas fa-eye"></i> Viewed - 156{" "}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <br/>

      <section class="gray-bg no-top-padding">
        <div class="container">
         
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-md-8">


              <div class="list-single-main-wrapper fl-wrap" id="sec2">
                <div class="list-single-main-media fl-wrap">
                  <img src={imgVPromo} class="respimg" alt="" />
                  <a
                    href="https://vimeo.com/70851162"
                    class="promo-link text-center  image-popup"
                  >
                    <i class="fas fa-video"></i>
                    <span>Promo Video</span>
                  </a>
                </div>

                <div class="list-single-main-item fl-wrap block_box">
                  <div class="list-single-main-item-title">
                    <h3>Description</h3>
                  </div>
                  <div class="list-single-main-item_content fl-wrap">
                    <p>
                      Praesent eros turpis, commodo vel justo at, pulvinar
                      mollis eros. Mauris aliquet eu quam id ornare. Morbi ac
                      quam enim. Cras vitae nulla condimentum, semper dolor non,
                      faucibus dolor. Vivamus adipiscing eros quis orci
                      fringilla, sed pretium lectus viverra. Pellentesque
                      habitant morbi tristique senectus et netus et malesuada
                      fames ac turpis egestas. Donec nec velit non odio aliquam
                      suscipit. Sed non neque faucibus, condimentum lectus at,
                      accumsan enim.{" "}
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Maecenas in pulvinar neque. Nulla finibus lobortis
                      pulvinar. Donec a consectetur nulla. Nulla posuere sapien
                      vitae lectus suscipit, et pulvinar nisi tincidunt. Aliquam
                      erat volutpat. Curabitur convallis fringilla diam sed
                      aliquam. Sed tempor iaculis massa faucibus feugiat. In
                      fermentum facilisis massa, a consequat purus viverra.
                    </p>
                    <div>
                      <button class="btn btn-style-three me-2"  type="button"><i class="fa fa-link me-1"></i>Website</button>
                    
                      <button class="btn btn-style-five me-2"type="button"><i class="fa fa-user-tie me-1"></i>Reception</button>
                    
                 
                    </div>
                  </div>
                </div>

                <div class="list-single-main-item fl-wrap block_box">
                  <div class="list-single-main-item-title">
                    <h3>Our Services</h3>
                  </div>
                  <div class="list-single-main-item_content fl-wrap">
                    <div class="listing-features fl-wrap">
                      <ul class="no-list-style">
                        <li>
                          <a href="#">
                            <i class="fas fa-user-tie"></i> Dine-In Services
                          </a>
                        </li>

                        <li>
                          <a href="#">
                            <i class="fas fa-user-tie"></i> Takeout & Delivery
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fas fa-user-tie"></i> Catering Services
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fas fa-user-tie"></i> Beverage & Bar Services
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fas fa-user-tie"></i> Specialty Services
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fas fa-user-tie"></i> Digital & Tech Services
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fas fa-user-tie"></i> Entertainment & Experience Services
                          </a>
                        </li>
                        
                      
                      
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="list-single-main-item fl-wrap block_box" id="sec3">
                  <div class="list-single-main-item-title">
                    <h3>Gallery / Photos</h3>
                  </div>
                  <div className="list-single-main-item_content fl-wrap">
      <div className="single-carousel-wrap fl-wrap lightgallery">
        {/* Swiper Navigation Buttons */}
        <div
          className="sc-next sc-btn color2-bg text-center"
          role="button"
          aria-label="Next slide"
        >
          <i className="fas fa-caret-right"></i>
        </div>
        <div
          className="sc-prev sc-btn color2-bg text-center"
          role="button"
          aria-label="Previous slide"
        >
          <i className="fas fa-caret-left"></i>
        </div>

        {/* Swiper Carousel */}
        <div className="single-carousel fl-wrap full-height">
          <Swiper
            modules={[Navigation, FreeMode]}
            navigation={{
              nextEl: ".sc-next",
              prevEl: ".sc-prev",
            }}
            freeMode={true}
            slidesPerView={1}
            spaceBetween={10}
            style={{ cursor: "grab" }}
          >
            <SwiperSlide>
              <div className="box-item">
                <img src={imgGall1} alt="Gallery Item 1" />
                <a href="#" className="gal-link popup-image text-center">
                  <i className="fa fa-search-plus"></i>
                </a>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="box-item">
                <img src={imgGall2} alt="Gallery Item 2" />
                <a href="#" className="gal-link popup-image text-center">
                  <i className="fa fa-search-plus"></i>
                </a>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="box-item">
                <img src={imgGall2} alt="Gallery Item 3" />
                <a href="#" className="gal-link popup-image text-center">
                  <i className="fa fa-search-plus"></i>
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
                </div>

                <div class="list-single-facts fl-wrap">
                  <div class="row">
                    <div class="col-md-4">
                      <div
                        class="inline-facts-wrap gradient-bg "
                        style={{ height: "113px" }}
                      >
                        <div class="inline-facts">
                        <i class="fas fa-award"></i>
                          <div class="milestone-counter">
                            <div class="stats animaper">
                              <div class="num" data-content="0" data-num="245">
                              <CountUp end={245} />
                              </div>
                            </div>
                          </div>
                          <h6>New Visiters Every Week</h6>
                        </div>
                        <div class="stat-wave">
                          <svg viewBox="0 0 100 25">
                            <path
                              fill="#fff"
                              d="M0 30 V12 Q30 17 55 2 T100 11 V30z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div
                        class="inline-facts-wrap gradient-bg "
                        style={{ height: "113px" }}
                      >
                        <div class="inline-facts">
                        <i class="fas fa-award"></i>
                          <div class="milestone-counter">
                            <div class="stats animaper">
                              <div class="num" data-content="0" data-num="2557">
                              <CountUp end={2557} />
                              </div>
                            </div>
                          </div>
                          <h6>Happy customers every year</h6>
                        </div>
                        <div class="stat-wave">
                          <svg viewBox="0 0 100 25">
                            <path
                              fill="#fff"
                              d="M0 30 V12 Q30 17 55 12 T100 11 V30z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div
                        class="inline-facts-wrap gradient-bg "
                        style={{ height: "113px" }}
                      >
                        <div class="inline-facts">
                          <i class="fas fa-award"></i>
                          <div class="milestone-counter">
                            <div class="stats animaper">
                              <div class="num" data-content="0" data-num="25">
                              <CountUp end={25} />
                              </div>
                            </div>
                          </div>
                          <h6>Won Awards</h6>
                        </div>
                        <div class="stat-wave">
                          <svg viewBox="0 0 100 25">
                            <path
                              fill="#fff"
                              d="M0 30 V12 Q30 12 55 5 T100 11 V30z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

               

                <div class="list-single-main-item fl-wrap block_box" id="sec5">
                  <div class="list-single-main-item-title">
                    <h3>
                    Reviews
                    </h3>

                 
                  </div>
                  <div class="review-score-totalz text-center">
                      <span class="review-score-total-item">4.1</span>
                    
                    </div>

                 

                  <div class="list-single-main-item_content fl-wrap">
                    <div class="reviews-comments-wrap">
                      <div class="reviews-comments-item">
                        <div class="review-comments-avatar">
                          <img src={avaR} alt="" />
                        </div>
                        <div class="reviews-comments-item-text fl-wrap">
                          <div class="reviews-comments-header fl-wrap">
                            <h4>
                              <a href="#">Liza Rose</a>
                            </h4>
                            <div class="review-score-user text-center">
                              <span class="review-score-user_item">4.2</span>
                              <div
                                class="listing-rating card-popup-rainingvis"
                                data-starrating2="4"
                              >
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <div class="card-popup-rainingvis_bg">
                                  <span class="card-popup-rainingvis_bg_item"></span>
                                  <span class="card-popup-rainingvis_bg_item"></span>
                                  <span class="card-popup-rainingvis_bg_item"></span>
                                  <span class="card-popup-rainingvis_bg_item"></span>
                                  <span class="card-popup-rainingvis_bg_item"></span>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p>
                            " Donec quam felis, ultricies nec, pellentesque eu,
                            pretium quis, sem. Nulla consequat massa quis enim.
                            Donec pede justo, fringilla vel, aliquet nec,
                            vulputate eget, arcu. In enim justo, rhoncus ut,
                            imperdiet a, venenatis vitae, justo. Nullam dictum
                            felis eu pede mollis pretium. "
                          </p>
                          <div class="reviews-comments-item-footer fl-wrap">
                            <div class="reviews-comments-item-date">
                              <span>
                                <i class="far fa-calendar-check"></i>12 April
                                2025
                              </span>
                            </div>
                            <a href="#" class="rate-review">
                              <i class="fas fa-thumbs-up"></i> Helpful Review{" "}
                              <span>2</span>{" "}
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="reviews-comments-item">
                        <div class="review-comments-avatar">
                          <img src={avaR2} alt="" />
                        </div>
                        <div class="reviews-comments-item-text fl-wrap">
                          <div class="reviews-comments-header fl-wrap">
                            <h4>
                              <a href="#">Adam Koncy</a>
                            </h4>
                            <div class="review-score-user text-center">
                              <span class="review-score-user_item">5.0</span>
                              <div
                                class="listing-rating card-popup-rainingvis"
                                data-starrating2="5"
                              >
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <div class="card-popup-rainingvis_bg">
                                  <span class="card-popup-rainingvis_bg_item"></span>
                                  <span class="card-popup-rainingvis_bg_item"></span>
                                  <span class="card-popup-rainingvis_bg_item"></span>
                                  <span class="card-popup-rainingvis_bg_item"></span>
                                  <span class="card-popup-rainingvis_bg_item"></span>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p>
                            " Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc posuere convallis purus non cursus. Cras
                            metus neque, gravida sodales massa ut. "
                          </p>
                          <div class="review-images ">
                            <a href="images/all/18.jpg" class="image-popup">
                              <img src="images/all/18.jpg" alt="" />
                            </a>
                            <a href="images/all/24.jpg" class="image-popup">
                              <img src="images/all/24.jpg" alt="" />
                            </a>
                          </div>
                          <div class="reviews-comments-item-footer fl-wrap">
                            <div class="reviews-comments-item-date">
                              <span>
                                <i class="far fa-calendar-check"></i>03 December
                                2025
                              </span>
                            </div>
                            <a href="#" class="rate-review">
                              <i class="fas fa-thumbs-up"></i> Helpful Review{" "}
                              <span>4</span>{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="list-single-main-item fl-wrap block_box" id="sec6">
                  <div class="list-single-main-item-title fl-wrap">
                    <h3>Add Review</h3>
                  </div>

                  <div id="add-review" class="add-review-box">
                    <form
                      id="add-comment"
                      class="add-comment  custom-form"
                      name="rangeCalc"
                    >
                      <fieldset>

                        <div className="text-center">
                        <div
  style={{
    direction: 'ltr',
    fontFamily: 'sans-serif',
    touchAction: 'none'
  }}
>
  <Rating
    onClick={function noRefCheck(){}}
    showTooltip
    size={20}
    tooltipArray={[
      'Terrible',
      'Bad',
      'Average',
      'Great',
      'Prefect'
    ]}
  />
</div>
                

                        </div>
                      
                        <div class="list-single-main-item_content fl-wrap">
                          <div class="row">
                            <div class="col-md-6">
                              <label>
                                <i class="fa fa-user"></i>
                              </label>
                              <input
                                type="text"
                                placeholder="Your Name *"
                                value=""
                              />
                            </div>
                            <div class="col-md-6">
                              <label>
                                <i class="fas fa-envelope"></i>{" "}
                              </label>
                              <input
                                type="text"
                                placeholder="Email Address*"
                                value=""
                              />
                            </div>
                          </div>
                          <textarea
                            cols="40"
                            rows="3"
                            placeholder="Your Review:"
                          ></textarea>
                          <div class="clearfix"></div>
                          <div class="listsearch-input-item fl-wrap">
                            <div class="fuzone">
                              <div class="fu-text">
                                <span>
                                  <i class="fas fa-images"></i> Click here or
                                  drop files to upload
                                </span>
                                <div class="photoUpload-files fl-wrap"></div>
                              </div>
                              <input type="file" class="upload" multiple="" />
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <button class="btn btn-style-three float-btn">
                            Submit Review <i class="fas fa-paper-plane"></i>
                          </button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="box-widget-item fl-wrap block_box">
                <div class="box-widget-item-header">
                  <h3>Working Hours</h3>
                </div>
                <div class="box-widget opening-hours fl-wrap">
                  <div class="box-widget-content">
                    <ul class="no-list-style">
                      <li class="mon">
                        <span class="opening-hours-day">Monday </span>
                        <span class="opening-hours-time">9 AM - 5 PM</span>
                      </li>
                      <li class="tue">
                        <span class="opening-hours-day">Tuesday </span>
                        <span class="opening-hours-time">9 AM - 5 PM</span>
                      </li>
                      <li class="wed">
                        <span class="opening-hours-day">Wednesday </span>
                        <span class="opening-hours-time">9 AM - 5 PM</span>
                      </li>
                      <li class="thu todaysDay">
                        <span class="opening-hours-day">Thursday </span>
                        <span class="opening-hours-time">9 AM - 5 PM</span>
                      </li>
                      <li class="fri">
                        <span class="opening-hours-day">Friday </span>
                        <span class="opening-hours-time">9 AM - 5 PM</span>
                      </li>
                      <li class="sat">
                        <span class="opening-hours-day">Saturday </span>
                        <span class="opening-hours-time">9 AM - 3 PM</span>
                      </li>
                      <li class="sun">
                        <span class="opening-hours-day">Sunday </span>
                        <span class="opening-hours-time">Closed</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="box-widget-item fl-wrap block_box">
                <div class="box-widget-item-header">
                  <h3>Message Business</h3>
                </div>
                <div class="box-widget">
                  <div class="box-widget-content">
                    <form class="add-comment custom-form">
                      <fieldset>
                        <label>
                          <i class="fas fa-user"></i>
                        </label>
                        <input type="text" placeholder="Your Name *" value="" />
                        <div class="clearfix"></div>
                        <label>
                          <i class="fas fa-envelope"></i>{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Email Address*"
                          value=""
                        />
                      
                      
                        <textarea
                          cols="40"
                          rows="3"
                          placeholder="Additional Information:"
                        ></textarea>
                      </fieldset>
                    

                      <button class="btn btn-style-three me-2"  type="button"><i class="fa fa-envelope me-1"></i>Send Message</button>
                    
                    </form>
                  </div>
                </div>
              </div>

              <div class="box-widget-item fl-wrap block_box">
                <div class="box-widget-item-header">
                  <h3>Location / Contacts </h3>
                </div>
                <div class="box-widget">
                  <div className="map-container"></div>
                  <div class="box-widget-content bwc-nopad">
                    <div class="list-author-widget-contacts list-item-widget-contacts bwc-padside">
                      <ul class="no-list-style">
                        <li>
                          <span>
                            <i class="fas fa-map-marker"></i> Adress :
                          </span>{" "}
                          <a href="#">Bryanston 27TH Brooklyn </a>
                        </li>
                        <li>
                          <span>
                            <i class="fas fa-phone"></i> Phone :
                          </span>{" "}
                          <a href="#">+7(123)987654</a>
                        </li>
                        <li>
                          <span>
                            <i class="fas fa-envelope"></i> Mail :
                          </span>{" "}
                          <a href="#">AlisaNoory@domain.com</a>
                        </li>
                        <li>
                          <span>
                            <i class="fas fa-globe-africa"></i> Website :
                          </span>{" "}
                          <a href="#">thinkt.co.za</a>
                        </li>
                      </ul>
                    </div>
                    <div class="list-widget-social bottom-bcw-box  fl-wrap">
                      <ul class="no-list-style">
                        <li>
                          <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fab fa-x-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                      <div class="bottom-bcw-box_link text-center">
                        <a
                          href="#"
                          class="show-single-contactform tolt"
                          data-microtip-position="top"
                          data-tooltip="Write Message"
                        >
                          <i class="fas fa-envelope"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            

         

              <div class="box-widget-item fl-wrap block_box">
                <div class="box-widget-item-header">
                  <h3>Similar listings :</h3>
                </div>
                <div class="box-widget  fl-wrap">
                  <div class="box-widget-content">
                    <div class="widget-posts  fl-wrap">
                      <ul class="no-list-style">
                        <li>
                          <div class="widget-posts-img">
                            <a href="listing-single.html">
                              <img
                                src={imgRelated}
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="widget-posts-descr">
                            <h4>
                              <a href="listing-single.html">Iconic Cafe</a>
                            </h4>
                            <div class="geodir-category-location fl-wrap">
                              <a href="#">
                                <i class="fas fa-map-marker-alt"></i> 40 Journal
                                Square Plaza, NJ, Bryanston
                              </a>
                            </div>
                            <div class="widget-posts-descr-link">
                              <a href="listing.html">Restaurants </a>{" "}
                              <a href="listing.html">Cafe</a>
                            </div>
                            <div class="widget-posts-descr-score text-center ">4.1</div>
                          </div>
                        </li>
                        <li>
                          <div class="widget-posts-img">
                            <a href="listing-single.html">
                              <img
                                  src={imgRelated}
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="widget-posts-descr">
                            <h4>
                              <a href="listing-single.html">MontePlaza Hotel</a>
                            </h4>
                            <div class="geodir-category-location fl-wrap">
                              <a href="#">
                                <i class="fas fa-map-marker-alt"></i> 70 Bright
                                St The Campus, Bryanston{" "}
                              </a>
                            </div>
                            <div class="widget-posts-descr-link">
                              <a href="listing.html">Hotels </a>{" "}
                            </div>
                            <div class="widget-posts-descr-score text-center">5.0</div>
                          </div>
                        </li>
                        <li>
                          <div class="widget-posts-img">
                            <a href="listing-single.html">
                              <img
                                  src={imgRelated}
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="widget-posts-descr">
                            <h4>
                              <a href="listing-single.html">
                                Rocko Band in Marquee Club
                              </a>
                            </h4>
                            <div class="geodir-category-location fl-wrap">
                              <a href="#">
                                <i class="fas fa-map-marker-alt"></i>75 Prince
                                St, , Bryanston
                              </a>
                            </div>
                            <div class="widget-posts-descr-link">
                              <a href="listing.html">Events</a>{" "}
                            </div>
                            <div class="widget-posts-descr-score text-center">4.2</div>
                          </div>
                        </li>
                        <li>
                          <div class="widget-posts-img">
                            <a href="listing-single.html">
                              <img
                           src={imgRelated}
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="widget-posts-descr">
                            <h4>
                              <a href="listing-single.html">
                                Premium Fitness Gym
                              </a>
                            </h4>
                            <div class="geodir-category-location fl-wrap">
                              <a href="#">
                                <i class="fas fa-map-marker-alt"></i> W 85th St,
                                The Campus, Bryanston
                              </a>
                            </div>
                            <div class="widget-posts-descr-link">
                              <a href="listing.html">Fitness</a>{" "}
                              <a href="listing.html">Gym</a>{" "}
                            </div>
                            <div class="widget-posts-descr-score text-center">5.0</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box-widget-item fl-wrap block_box">
                <div class="box-widget-item-header">
                  <h3>Tags</h3>
                </div>
                <div class="box-widget opening-hours fl-wrap">
                  <div class="box-widget-content">
                    <div class="list-single-tags tags-stylwrap">
                      <a href="#">Hotel</a>
                      <a href="#">Hostel</a>
                      <a href="#">Room</a>
                      <a href="#">Spa</a>
                      <a href="#">Restourant</a>
                      <a href="#">Parking</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrganisationDetails;
