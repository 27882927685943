import React from 'react'
import Redirect from '../Redirect'
import Footer from '../components/Footer'
import FHeader from '../components/FHeader'

import p1 from '../../src/dashboardstyle/images/place/01.jpg'
import p2 from '../../src/dashboardstyle/images/place/02.jpg'
import p3 from '../../src/dashboardstyle/images/place/03.jpg'
import p4 from '../../src/dashboardstyle/images/place/04.jpg'

import spk4 from '../../src/images/resource/speaker-1.jpg';

import spkpop from '../../src/images/resource/speaker-popup.jpg'

import spk5 from '../../src/images/resource/speaker-5.jpg'
import spk6 from '../../src/images/resource/speaker-6.jpg'

import spk3 from '../../src/images/resource/speaker-3.jpg'

import imgSch from '../../src/dashboardstyle/images/schedule/schedule-3/user.jpg'
import imgSch1 from '../../src/dashboardstyle/images/schedule/schedule-3/user-2.jpg'
import imgSc2 from '../../src/dashboardstyle/images/schedule/schedule-3/user-3.jpg'


import imgSchedule from '../../src/dashboardstyle/images/schedule/schedule-3/schedule.jpg';
import imgSchedule2 from '../../src/dashboardstyle/images/schedule/schedule-3/schedule-2.jpg';
import imgSchedule3 from '../../src/dashboardstyle/images/schedule/schedule-3/schedule-3.jpg';
import imgSchedule4 from '../../src/dashboardstyle/images/schedule/schedule-3/schedule-4.jpg';
import { useNavigate } from 'react-router-dom'





const Home = () => {
  // return <Redirect url="https://e-connect.co.za" />;

const navigate = useNavigate();


const handleClickAllListings = () => {
    navigate("/explore-organisations"); 
}


const handleClickAllEvents = () => {
    navigate("/explore-events"); 
}

const handleClickAllPeople = () => {
    navigate("/people"); 
}



  return (
    <div style={{background: '#fff'}}>
 <FHeader/>


 <section class="welcome-area bg-overlay d-flex align-items-center overflow-hidden">
 <div class="container">
                <div class="row align-items-center">
              
                    <div class="header-image left h1 col-12 col-md-7 col-lg-6" data-aos="fade-right">
                        <div class="welcome-intro">
                            <div class="left wow fadeInLeft">
                            <h1>Discover, Connect, and Thrive with eConnect</h1>
<p className='text-white'>With thousands of active events and networking opportunities, eConnect is South Africa’s leading platform for seamless virtual and hybrid experiences.</p>

                                <a href="" class="btn-white">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 col-lg-6" data-aos="fade-left">
                   
                        <div class="welcome-thumb text-center mx-auto">
                   
                            <a class="play-btn popup-video popup-youtube" data-fancybox href="https://www.youtube.com/watch?v=14semTlwyUY">
                                <div class="btn-circle play-animation"></div>
                                <div class="btn-circle play-animation animation-short"></div>
                     
                                <div class="play-icon">
                                    <i class="fas fa-play"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

    <div className="shape-bottom">
      <svg
        fill="#fff"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 1921 819.8"
        style={{ enableBackground: "new 0 0 1921 819.8" }}
        xmlSpace="preserve"
      >
        <path
          className="st0"
          d="M1921,413.1v406.7H0V0.5h0.4l228.1,598.3c30,74.4,80.8,130.6,152.5,168.6c107.6,57,212.1,40.7,245.7,34.4  
            c22.4-4.2,54.9-13.1,97.5-26.6L1921,400.5V413.1z"
        ></path>
        <path
          className="st1"
          d="M1921,387.9v431.9H0V0h13l226.6,594.4c14.4,35.7,34,67.4,58.3,94.3c24.4,26.9,54.2,49.8,88.6,68.1  
            c50.2,26.6,105.8,40.1,165.2,40.1c36.1,0,63.6-5.2,72.6-6.9c21.6-4,53.9-12.9,96.1-26.2L1921,387.9z"
        ></path>
      </svg>
    </div>
</section>

<section class="popular-categories">

            <div class="container">
                <div class="sec-title text-center">
                    <h2><span>Popular </span>Categories</h2>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-6 remb-30" data-aos="zoom-in">
                        <div class="popular-categories-item"> <span class="step-point">01</span>
                            <i class="fas fa-utensils"></i>
                            <h3>Restaurants</h3>
                            <p>500 Listings</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 remb-30" data-aos="zoom-in">
                        <div class="popular-categories-item"> <span class="step-point">02</span>
                            <i class="fas fa-umbrella-beach"></i>
                            <h3>Hotels</h3>
                            <p>635 Listings</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 rtmb-30" data-aos="zoom-in">
                        <div class="popular-categories-item"> <span class="step-point">03</span>
                            <i class="fas fa-dumbbell"></i>
                            <h3>Fitness</h3>
                            <p>723 Listings</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6" data-aos="zoom-in">
                        <div class="popular-categories-item"> <span class="step-point">04</span>
                            <i class="fas fa-coffee"></i>
                            <h3>Coffee Shop</h3>
                            <p>897 Listings</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-4 second">
                    <div class="col-lg-3 col-md-6 remb-30" data-aos="zoom-in">
                        <div class="popular-categories-item"> <span class="step-point">05</span>
                            <i class="fas fa-shopping-bag"></i>
                            <h3>Shopping</h3>
                            <p>954 Listings</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 remb-30" data-aos="zoom-in">
                        <div class="popular-categories-item"> <span class="step-point">06</span>
                            <i class="fas fa-film"></i>
                            <h3>Movie Theater</h3>
                            <p>1023 Listings</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 rtmb-30" data-aos="zoom-in">
                        <div class="popular-categories-item"> <span class="step-point">07</span>
                            <i class="fas fa-university"></i>
                            <h3>Banks</h3>
                            <p>1234 Listings</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6" data-aos="zoom-in">
                        <div class="popular-categories-item"> <span class="step-point">08</span>
                            <i class="fas fa-shopping-cart"></i>
                            <h3>Super Market</h3>
                            <p>1378 Listings</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>



      <section className='featured-listings' style={{background: '#f5f6ff'}}>

      <div class="anim-icons">


<span class="icon icon-twist-line-2 wow zoomIn" data-wow-delay="800ms"></span>
<span class="icon icon-circle-8 wow zoomIn" data-wow-delay="1200ms"></span>
</div>


<div class="anim-icons">
<span class="icon icon-circle-15"></span>
<span class="icon icon-circle-2"></span>
<span class="icon icon-circle-3"></span>
<span class="icon icon-circle-4"></span>
</div>
      <div class="py-5 position-relative overflow-hidden  rounded-4 mx-3 mt-3">
        <div class="container py-4">
            <div class="row justify-content-center">
                <div class="col-sm-10 col-md-10 col-lg-8 col-xl-7">
                <div class="sec-title text-center">
                    <h2><span>Featured </span>Listings</h2>
                </div>
               
                </div>
            </div>
            <div class="row g-4">
                <div class="col-lg-6">
          
                    <div class="card border-0 shadow-sm overflow-hidden rounded-4 card-hover card-hover-bg">
                        <a href="" class="stretched-link"></a>
                        <div class="card-body p-0">
                            <div class="g-0 row">
                                <div class="bg-white col-lg-5 col-md-5 col-xl-5 position-relative">
                                    <div class="card-image-hover dark-overlay h-100 overflow-hidden position-relative">
                                  
                                        <img src={p1} alt="" class="h-100 w-100 object-fit-cover"/>
                                   
                                        <div class="bg-primary-sec card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2"><i class="fas fa-star me-1"></i>Featured</div>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7 col-xl-7 p-3 p-lg-4 p-md-3 p-sm-4">
                                    <div class="d-flex flex-column h-100">
                                        
                                   
                                    
                                        <h4 class="fs-18 fw-semibold mb-0">
                                            Green Mart Apartment
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check-fill text-success" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                            </svg>
                                        </h4>

                                        <div class="align-items-center d-flex flex-wrap gap-1 text-primary  card-start mt-2">
                                        
                                        <i class="fas fa-star"></i>
                                    
                                        <span class="fw-medium text-primary"><span class="fs-6 fw-semibold me-1">(4.5)</span>2,391 reviews</span>
                                      
                                    </div>
                               
                                        <p class="mt-3">Amet minim mollit non deserunt ullamco est sit aliqua dolor.</p>
                                  
                                        <div class="d-flex flex-wrap gap-3 mt-auto z-1">
                                            <a href="tel:+4733378901" class="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#9b9b9b" class="bi bi-telephone" viewBox="0 0 16 16">
                                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                </svg>
                                                <span>(123) 456-7890</span>
                                            </a>
                                            <a href="#" class="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#9b9b9b" class="bi bi-compass" viewBox="0 0 16 16">
                                                    <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                                    <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                                                </svg>
                                                <span>Directions</span>
                                            </a>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div class="col-lg-6">
                  
                    <div class="card border-0 shadow-sm overflow-hidden rounded-4 card-hover card-hover-bg">
                        <a href="" class="stretched-link"></a>
                        <div class="card-body p-0">
                            <div class="g-0 row">
                                <div class="bg-white col-lg-5 col-md-5 col-xl-5 position-relative">
                                    <div class="card-image-hover dark-overlay h-100 overflow-hidden position-relative">
                                       
                                        <img src={p2} alt="" class="h-100 w-100 object-fit-cover"/>
                                  
                                        <div class="bg-primary-sec card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2"><i class="fas fa-star me-1"></i>Featured</div>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7 col-xl-7 p-3 p-lg-4 p-md-3 p-sm-4">
                                    <div class="d-flex flex-column h-100">
                            
                                    
                                        <h4 class="fs-18 fw-semibold mb-0">
                                            Green Mart Apartment
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check-fill text-success" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                            </svg>
                                        </h4>

                                                    
                                        <div class="align-items-center d-flex flex-wrap gap-1 text-primary  card-start mt-2">
                                        
                                            <i class="fas fa-star"></i>
                                        
                                            <span class="fw-medium text-primary"><span class="fs-6 fw-semibold me-1">(4.5)</span>2,391 reviews</span>
                                  
                                        </div>
                                        
                                        <p class="mt-3">Amet minim mollit non deserunt ullamco est sit aliqua dolor.</p>
                                      
                                        <div class="d-flex flex-wrap gap-3 mt-auto z-1">
                                            <a href="tel:+4733378901" class="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#9b9b9b" class="bi bi-telephone" viewBox="0 0 16 16">
                                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                </svg>
                                                <span>(123) 456-7890</span>
                                            </a>
                                            <a href="#" class="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#9b9b9b" class="bi bi-compass" viewBox="0 0 16 16">
                                                    <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                                    <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                                                </svg>
                                                <span>Directions</span>
                                            </a>
                                        </div>
                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
                </div>
                <div class="col-lg-6">
               
                    <div class="card border-0 shadow-sm overflow-hidden rounded-4 card-hover card-hover-bg">
                        <a href="" class="stretched-link"></a>
                        <div class="card-body p-0">
                            <div class="g-0 row">
                                <div class="bg-white col-lg-5 col-md-5 col-xl-5 position-relative">
                                    <div class="card-image-hover dark-overlay h-100 overflow-hidden position-relative">
                                   
                                        <img src={p3} alt="" class="h-100 w-100 object-fit-cover"/>
                                  
                                        <div class="bg-primary-sec card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2"><i class="fas fa-star me-1"></i>Featured</div>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7 col-xl-7 p-3 p-lg-4 p-md-3 p-sm-4">
                                    <div class="d-flex flex-column h-100">
                             
                                        
                                        <h4 class="fs-18 fw-semibold mb-0">
                                            The Barber's Lounge
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check-fill text-success" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                            </svg>
                                        </h4>

                                                   
                                        <div class="align-items-center d-flex flex-wrap gap-1 text-primary  card-start mt-2">
                                      
                                            <i class="fas fa-star"></i>
                                        
                                            <span class="fw-medium text-primary"><span class="fs-6 fw-semibold me-1">(4.5)</span>2,391 reviews</span>
                                            
                                        </div>
                                     
                                        <p class="mt-3">Amet minim mollit non deserunt ullamco est sit aliqua dolor.</p>
                                      
                                        <div class="d-flex flex-wrap gap-3 mt-auto z-1">
                                            <a href="tel:+4733378901" class="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#9b9b9b" class="bi bi-telephone" viewBox="0 0 16 16">
                                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                </svg>
                                                <span>(123) 456-7890</span>
                                            </a>
                                            <a href="#" class="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#9b9b9b" class="bi bi-compass" viewBox="0 0 16 16">
                                                    <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                                    <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                                                </svg>
                                                <span>Directions</span>
                                            </a>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
                <div class="col-lg-6">

                    <div class="card border-0 shadow-sm overflow-hidden rounded-4 mb-4 card-hover card-hover-bg">
                        <a href="" class="stretched-link"></a>
                        <div class="card-body p-0">
                            <div class="g-0 row">
                                <div class="bg-white col-lg-5 col-md-5 col-xl-5 position-relative">
                                    <div class="card-image-hover dark-overlay h-100 overflow-hidden position-relative">
                                      
                                        <img src={p4} alt="" class="h-100 w-100 object-fit-cover"/>
                                      
                                        <div class="bg-primary-sec card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2"><i class="fas fa-star me-1"></i>Featured</div>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7 col-xl-7 p-3 p-lg-4 p-md-3 p-sm-4">
                                    <div class="d-flex flex-column h-100">
                                        
                                      
                                  
                                        <h4 class="fs-18 fw-semibold mb-0">
                                            Gaming Expo Spectacle
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check-fill text-success" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                            </svg>
                                        </h4>

                                        <div class="align-items-center d-flex flex-wrap gap-1 text-primary  card-start mt-2">
                                      
                                      <i class="fas fa-star"></i>
                                   
                                      <span class="fw-medium text-primary"><span class="fs-6 fw-semibold me-1">(4.5)</span>2,391 reviews</span>
                     
                                  </div>
                                   
                                        <p class="mt-3">Amet minim mollit non deserunt ullamco est sit aliqua dolor.</p>
                                    
                                        <div class="d-flex flex-wrap gap-3 mt-auto z-1">
                                            <a href="tel:+4733378901" class="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#9b9b9b" class="bi bi-telephone" viewBox="0 0 16 16">
                                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                </svg>
                                                <span>(123) 456-7890</span>
                                            </a>
                                            <a href="#" class="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#9b9b9b" class="bi bi-compass" viewBox="0 0 16 16">
                                                    <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                                    <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                                                </svg>
                                                <span>Directions</span>
                                            </a>
                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
             
                </div>
            </div>

          <div className='text-center' style={{paddingTop: '8px'}}>
          <div className='g-btn'>
           <button onClick={handleClickAllEvents}>
  View All Listings <i className='fas fa-arrow-right'></i>
</button>
           </div>
          </div>
        </div>
    </div>
     
      </section>

      

        <section class="speakers-section-two">
<span class="float-text">Community</span>
<div class="large-container">
<div class="sec-title">
<h2>Featured Community Members</h2>
</div>
<div class="speakers-area clearfix">

<div class="speaker-block-two wow fadeInUp">
<div class="image-box">
<figure class="image"><img src={spk4} alt/></figure>
<div class="overlay-box">
<a href="#popup_11" class="link view-speaker ts-image-popup" data-effect="mfp-zoom-in"><span class="flaticon-add-1"></span></a>
<div class="info">
<h5 class="name">Olivia Jenelia</h5>
<span class="designation">General Manager</span>
</div>
</div>
</div>


</div>

<div class="speaker-block-two wow fadeInUp" data-wow-delay="400ms">
<div class="image-box">
<figure class="image"><img src={spk6} alt/></figure>
<div class="overlay-box">
<a href="#popup_12" class="link view-speaker ts-image-popup" data-effect="mfp-zoom-in"><span class="flaticon-add-1"></span></a>
<div class="info">
<h5 class="name">Olivia Jenelia</h5>
<span class="designation">General Manager</span>
</div>
</div>
</div>


</div>

<div class="speaker-block-two wow fadeInUp" data-wow-delay="800ms">
<div class="image-box">
<figure class="image"><img src={spk3} alt/></figure>
<div class="overlay-box">
<a href="#popup_13" class="link view-speaker ts-image-popup" data-effect="mfp-zoom-in"><span class="flaticon-add-1"></span></a>
<div class="info">
<h5 class="name">Olivia Jenelia</h5>
<span class="designation">General Manager</span>
</div>
</div>
</div>


</div>

<div class="speaker-block-two wow fadeInUp" data-wow-delay="1200ms">
<div class="image-box">
<figure class="image"><img src={spk5} alt/></figure>
<div class="overlay-box">
<a href="#popup_14" class="link view-speaker ts-image-popup" data-effect="mfp-zoom-in"><span class="flaticon-add-1"></span></a>
<div class="info">
<h5 class="name">Olivia Jenelia</h5>
<span class="designation">General Manager</span>
</div>
</div>
</div>


</div>




<div className='text-center' >
          <div className='g-btn'>
           <button onClick={handleClickAllPeople}>
  View All Members <i className='fas fa-arrow-right'></i>
</button>
           </div>
          </div>
</div>

</div>

</section>




<section className='featured-events style-two'>

<div class="td-schedule-area  pt-140 pb-110 bg-position">
            <div class="container">

            
                              <div class="sec-title text-center">
                    <h2><span>Featured </span>Events</h2>
                </div>
                           
            
                <div class="row">
                    <div class="tp-contact-form-wrapper tp-black-bg9  tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade active show" id="v-pills-home1" role="tabpanel" aria-labelledby="v-pills-home1-tab">
                            <div class="td-schedule-3-wrap mb-30">
                                <div class="row align-items-center">
                                    <div class="col-xl-4 col-lg-5">
                                        <div class="td-schedule-3-thumb p-relative">
                                            <img class="w-100" src={imgSchedule} alt=""/>
                                            <span class="td-schedule-2-date"><i class="fas fa-calendar"></i> jan 10, 2025</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-8 col-lg-7">
                                        <div class="td-schedule-3-content">
                                            <h2 class="td-schedule-3-title"><a href="contact.html">Innovate 2025 Your Pathway to Business<br/> 
                                                Transformation Energy Save</a></h2>
                                            <div class="td-schedule-3-destination mb-10">
                                                <span class="mr-25">
                                                    <i class="fas fa-map-pin"></i>
                                                    Apple Upper West Side, Brooklyn
                                                </span>
                                                <span>
                                                    <i class="fas fa-clock"></i>
                                                    19:15 a.m. – 07:15 a.m.
                                                </span>
                                            </div>
                                            <p class="mb-25 para">when an unknown printer took a galley of type and scrambled it to make a type specimen bookIt 
                                                has survived not only five centuries, but also the leap into electronic.</p>
                                            <div class="td-schedule-3-btn d-flex align-items-center flex-wrap">
                                                <div class="td-schedule-3-user-wrap d-flex align-items-center">
                                                    <span class="td-schedule-3-user mr-10">
                                                        <img src={imgSch} alt=""/>
                                                    </span>
                                                    <div>
                                                        <h6 class="name">Parker Roben</h6>
                                                        <span class="position">Author, Orginiser</span>
                                                    </div>
                                                </div>
                                                <span class="dvdr"></span>
                                                <a class="td-btn td-btn-3-squre td-left-right text3 mr-25" href="contact.html">
                                                    <span class="mr10 td-text d-inline-block mr-5">
                                                    <div class="btn-box"><a href="/detailsev" class="theme-btn btn-style-three">View Event</a></div>
                                                    </span>
                                                    <span class="td-arrow-angle">
                                                        <svg class="td-arrow-svg-top-right" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.943836 13.5C0.685616 13.5 0.45411 13.4021 0.276027 13.224C0.0979452 13.0459 0 12.8055 0 12.5562C0 12.3068 0.0979452 12.0664 0.276027 11.8884L9.76781 2.38767H2.02123C1.49589 2.38767 1.0774 1.96027 1.0774 1.44384C1.0774 0.927397 1.50479 0.5 2.03014 0.5H12.0562C12.1274 0.5 12.1986 0.508904 12.2788 0.526712L12.4034 0.562329L12.537 0.633562C12.5637 0.65137 12.5993 0.678082 12.626 0.69589C12.6973 0.749315 12.7507 0.80274 12.7952 0.856164C12.8219 0.891781 12.8575 0.927397 12.8842 0.989726L12.9555 1.1411L12.9822 1.22123C13 1.29247 13.0089 1.3726 13.0089 1.44384V11.4699C13.0089 11.9952 12.5815 12.4137 12.0651 12.4137C11.5486 12.4137 11.1212 11.9863 11.1212 11.4699V3.72329L1.62055 13.224C1.44247 13.4021 1.20205 13.5 0.943836 13.5Z" fill="white" />
                                                            <path d="M0.943836 13.5C0.685616 13.5 0.45411 13.4021 0.276027 13.224C0.0979452 13.0459 0 12.8055 0 12.5562C0 12.3068 0.0979452 12.0664 0.276027 11.8884L9.76781 2.38767H2.02123C1.49589 2.38767 1.0774 1.96027 1.0774 1.44384C1.0774 0.927397 1.50479 0.5 2.03014 0.5H12.0562C12.1274 0.5 12.1986 0.508904 12.2788 0.526712L12.4034 0.562329L12.537 0.633562C12.5637 0.65137 12.5993 0.678082 12.626 0.69589C12.6973 0.749315 12.7507 0.80274 12.7952 0.856164C12.8219 0.891781 12.8575 0.927397 12.8842 0.989726L12.9555 1.1411L12.9822 1.22123C13 1.29247 13.0089 1.3726 13.0089 1.44384V11.4699C13.0089 11.9952 12.5815 12.4137 12.0651 12.4137C11.5486 12.4137 11.1212 11.9863 11.1212 11.4699V3.72329L1.62055 13.224C1.44247 13.4021 1.20205 13.5 0.943836 13.5Z" fill="white" />
                                                        </svg> 
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="td-schedule-3-wrap mb-30">
                                <div class="row align-items-center">
                                    <div class="col-xl-4 col-lg-5">
                                        <div class="td-schedule-3-thumb p-relative">
                                            <img class="w-100" src={imgSchedule2} alt=""/>
                                            <span class="td-schedule-2-date"><i class="fas fa-calendar"></i>jan 10, 2025</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-8 col-lg-7">
                                        <div class="td-schedule-3-content">
                                            <h2 class="td-schedule-3-title"><a href="contact.html">Innovate 2022 Your Pathway to Business<br/> 
                                                Transformation Energy Save</a></h2>
                                            <div class="td-schedule-3-destination mb-10">
                                                <span class="mr-25">
                                                    <i class="fas fa-map-pin"></i>
                                                    Apple Upper West Side, Brooklyn
                                                </span>
                                                <span>
                                                    <i class="fas fa-clock"></i>
                                                    19:15 a.m. – 07:15 a.m.
                                                </span>
                                            </div>
                                            <p class="mb-25 para">when an unknown printer took a galley of type and scrambled it to make a type specimen bookIt 
                                                has survived not only five centuries, but also the leap into electronic.</p>
                                            <div class="td-schedule-3-btn d-flex align-items-center flex-wrap">
                                                <div class="td-schedule-3-user-wrap d-flex align-items-center">
                                                    <span class="td-schedule-3-user mr-10">
                                                        <img src={imgSch1} alt=""/>
                                                    </span>
                                                    <div>
                                                        <h6 class="name">Parker Roben</h6>
                                                        <span class="position">Author, Orginiser</span>
                                                    </div>
                                                </div>
                                                <span class="dvdr"></span>
                                                <a class="td-btn td-btn-3-squre td-left-right text3 mr-25" href="contact.html">
                                                    <span class="mr10 td-text d-inline-block mr-5">  <div class="btn-box"><a href="/detailsev" class="theme-btn btn-style-three">View Event</a></div></span>
                                                    <span class="td-arrow-angle">
                                                        <svg class="td-arrow-svg-top-right" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.943836 13.5C0.685616 13.5 0.45411 13.4021 0.276027 13.224C0.0979452 13.0459 0 12.8055 0 12.5562C0 12.3068 0.0979452 12.0664 0.276027 11.8884L9.76781 2.38767H2.02123C1.49589 2.38767 1.0774 1.96027 1.0774 1.44384C1.0774 0.927397 1.50479 0.5 2.03014 0.5H12.0562C12.1274 0.5 12.1986 0.508904 12.2788 0.526712L12.4034 0.562329L12.537 0.633562C12.5637 0.65137 12.5993 0.678082 12.626 0.69589C12.6973 0.749315 12.7507 0.80274 12.7952 0.856164C12.8219 0.891781 12.8575 0.927397 12.8842 0.989726L12.9555 1.1411L12.9822 1.22123C13 1.29247 13.0089 1.3726 13.0089 1.44384V11.4699C13.0089 11.9952 12.5815 12.4137 12.0651 12.4137C11.5486 12.4137 11.1212 11.9863 11.1212 11.4699V3.72329L1.62055 13.224C1.44247 13.4021 1.20205 13.5 0.943836 13.5Z" fill="white" />
                                                            <path d="M0.943836 13.5C0.685616 13.5 0.45411 13.4021 0.276027 13.224C0.0979452 13.0459 0 12.8055 0 12.5562C0 12.3068 0.0979452 12.0664 0.276027 11.8884L9.76781 2.38767H2.02123C1.49589 2.38767 1.0774 1.96027 1.0774 1.44384C1.0774 0.927397 1.50479 0.5 2.03014 0.5H12.0562C12.1274 0.5 12.1986 0.508904 12.2788 0.526712L12.4034 0.562329L12.537 0.633562C12.5637 0.65137 12.5993 0.678082 12.626 0.69589C12.6973 0.749315 12.7507 0.80274 12.7952 0.856164C12.8219 0.891781 12.8575 0.927397 12.8842 0.989726L12.9555 1.1411L12.9822 1.22123C13 1.29247 13.0089 1.3726 13.0089 1.44384V11.4699C13.0089 11.9952 12.5815 12.4137 12.0651 12.4137C11.5486 12.4137 11.1212 11.9863 11.1212 11.4699V3.72329L1.62055 13.224C1.44247 13.4021 1.20205 13.5 0.943836 13.5Z" fill="white" />
                                                        </svg> 
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="td-schedule-3-wrap mb-30">
                            
                                <div class="row align-items-center">
                                    <div class="col-xl-4 col-lg-5">
                                        <div class="td-schedule-3-thumb p-relative">
                                            <img class="w-100" src={imgSchedule4} alt=""/>
                                            <span class="td-schedule-2-date"><i class="fas fa-calendar"></i>jan 10, 2025</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-8 col-lg-7">
                                        <div class="td-schedule-3-content">
                                            <h2 class="td-schedule-3-title"><a href="contact.html">Innovate 2023 Your Pathway to Business<br/> 
                                                Transformation Energy Save</a></h2>
                                            <div class="td-schedule-3-destination mb-10">
                                                <span class="mr-25">
                                                    <i class="fas fa-map-pin"></i>
                                                    Apple Upper West Side, Brooklyn
                                                </span>
                                                <span>
                                                    <i class="fas fa-clock"></i>
                                                    19:15 a.m. – 07:15 a.m.
                                                </span>
                                            </div>
                                            <p class="mb-25 para">when an unknown printer took a galley of type and scrambled it to make a type specimen bookIt 
                                                has survived not only five centuries, but also the leap into electronic.</p>
                                            <div class="td-schedule-3-btn d-flex align-items-center flex-wrap">
                                                <div class="td-schedule-3-user-wrap d-flex align-items-center">
                                                    <span class="td-schedule-3-user mr-10">
                                                        <img src={imgSc2} alt=""/>
                                                    </span>
                                                    <div>
                                                        <h6 class="name">Parker Roben</h6>
                                                        <span class="position">Author, Orginiser</span>
                                                    </div>
                                                </div>
                                                <span class="dvdr"></span>
                                                <a class="td-btn td-btn-3-squre td-left-right text3 mr-25" href="contact.html">
                                                    <span class="mr10 td-text d-inline-block mr-5">  <div class="btn-box"><a href="/detailsev" class="theme-btn btn-style-three">View Event</a></div></span>
                                                    <span class="td-arrow-angle">
                                                        <svg class="td-arrow-svg-top-right" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.943836 13.5C0.685616 13.5 0.45411 13.4021 0.276027 13.224C0.0979452 13.0459 0 12.8055 0 12.5562C0 12.3068 0.0979452 12.0664 0.276027 11.8884L9.76781 2.38767H2.02123C1.49589 2.38767 1.0774 1.96027 1.0774 1.44384C1.0774 0.927397 1.50479 0.5 2.03014 0.5H12.0562C12.1274 0.5 12.1986 0.508904 12.2788 0.526712L12.4034 0.562329L12.537 0.633562C12.5637 0.65137 12.5993 0.678082 12.626 0.69589C12.6973 0.749315 12.7507 0.80274 12.7952 0.856164C12.8219 0.891781 12.8575 0.927397 12.8842 0.989726L12.9555 1.1411L12.9822 1.22123C13 1.29247 13.0089 1.3726 13.0089 1.44384V11.4699C13.0089 11.9952 12.5815 12.4137 12.0651 12.4137C11.5486 12.4137 11.1212 11.9863 11.1212 11.4699V3.72329L1.62055 13.224C1.44247 13.4021 1.20205 13.5 0.943836 13.5Z" fill="white" />
                                                            <path d="M0.943836 13.5C0.685616 13.5 0.45411 13.4021 0.276027 13.224C0.0979452 13.0459 0 12.8055 0 12.5562C0 12.3068 0.0979452 12.0664 0.276027 11.8884L9.76781 2.38767H2.02123C1.49589 2.38767 1.0774 1.96027 1.0774 1.44384C1.0774 0.927397 1.50479 0.5 2.03014 0.5H12.0562C12.1274 0.5 12.1986 0.508904 12.2788 0.526712L12.4034 0.562329L12.537 0.633562C12.5637 0.65137 12.5993 0.678082 12.626 0.69589C12.6973 0.749315 12.7507 0.80274 12.7952 0.856164C12.8219 0.891781 12.8575 0.927397 12.8842 0.989726L12.9555 1.1411L12.9822 1.22123C13 1.29247 13.0089 1.3726 13.0089 1.44384V11.4699C13.0089 11.9952 12.5815 12.4137 12.0651 12.4137C11.5486 12.4137 11.1212 11.9863 11.1212 11.4699V3.72329L1.62055 13.224C1.44247 13.4021 1.20205 13.5 0.943836 13.5Z" fill="white" />
                                                        </svg> 
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        


                   

                    </div>
                    <div className='text-center' >
          <div className='g-btn'>
           <button onClick={handleClickAllEvents}>
  View All Events <i className='fas fa-arrow-right'></i>
</button>
           </div>
          </div>
                </div>
            </div>
        </div>

</section>







   


      <Footer/>
    </div>
  )
}



export default Home
