import React from 'react'
import imgLogo from './../../src/dashboardstyle/images/e light.png'

const StaticHeader = () => {
  return (
    <div>
           <header class="main-header static-head" style={{position: 'relative'}}>

<div class="header-upper">
<div class="outer-container">
<div class="clearfix">
<div class="pull-left logo-box">
<div class="logo"><a href="/"><img width='50' className='imgLogoN' alt /></a></div>


</div>
<div class="nav-outer clearfix">

<nav class="main-menu navbar-expand-md">
<div class="navbar-header">
<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
<span class="icon-bar"></span>
<span class="icon-bar"></span>
<span class="icon-bar"></span>
</button>
</div>
<div class="navbar-collapse collapse scroll-nav clearfix" id="navbarSupportedContent">
<ul class="navigation clearfix">
<li class="current dropdown"><a href="/">Home</a>

</li>

<li><a href="/platform">Platform</a></li>
<li><a href="explore-organisations">Explore Organisations</a></li>
<li class="dropdown"><a href="/people">People</a></li>

<li class="dropdown"><a href="/explore-events">Explore Events</a>

</li>
<li class="dropdown"><a href="/pricing">Pricing</a></li>
</ul>
</div>
</nav>

</div>

<div class="outer-box">

<div class="btn-box">
<a href="/login" class="theme-btn btn-style-one-three">Get Started </a>
</div>

</div>
</div>
</div>
</div>

</header>
    </div>
  )
}

export default StaticHeader
