  import React, { useState } from 'react';
  import Modal from 'react-modal'; 
  import { db, auth, storage } from '../../firebase'; 
  import { collection, addDoc, doc, updateDoc } from 'firebase/firestore';
  import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
  import { toast } from 'react-toastify';
  import Select from "react-select";
  import CreatableSelect from 'react-select/creatable';
  import { ClipLoader, MoonLoader, SyncLoader } from 'react-spinners'; // Import loader spinner

  // Modal custom styles
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '35%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
    },
  };

  const VirtualOfficeForm = ({ handleBackClick, editData  }) => {
    const [officeName, setOfficeName] = useState(editData ? editData.officeName : '');
    const [description, setDescription] = useState(editData ? editData.description : '');
    const [logo, setLogo] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [units, setUnits] = useState(editData ? editData.units : []);
    const [visitorAccess, setVisitorAccess] = useState(editData ? editData.visitorAccess : '');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [unitName, setUnitName] = useState('');
    const [currentUnit, setCurrentUnit] = useState(null);
    const [tables, setTables] = useState([]);
    const [tableName, setTableName] = useState('');
    const [tableLogo, setTableLogo] = useState(null);
    const [chairs, setChairs] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress
    const [isFinalizing, setIsFinalizing] = useState(false); // Loader for finalizing unit
    const [isAddingTable, setIsAddingTable] = useState(false); // Loader for adding a table

    const handleFileChange = (e) => setLogo(e.target.files[0]);
    const handleTableFileChange = (e) => setTableLogo(e.target.files[0]);



const [participants, setParticipants] = useState(editData ? editData.participants : []);
const [editingUnitIndex, setEditingUnitIndex] = useState(null);





    // Function to upload images to Firebase Storage with progress tracking
    const uploadImageToStorage = async (file, path) => {
      return new Promise((resolve, reject) => {
        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress); // Update the progress state
          },
          (error) => {
            toast.error('Error uploading file');
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });
    };



// Inside handleSubmit:
const handleSubmit = async (e) => {
  e.preventDefault();

  // Validate required fields (logo, visitorAccess, etc.)
  if (!logo && !editData) {
    toast.error('Please upload an office logo before submitting');
    return;
  }
  if (!visitorAccess) {
    toast.error('Please select who can visit the space');
    return;
  }

  setIsSubmitting(true);

  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User not authenticated');
    }

    let officeLogoUrl = '';
    // Only upload a new logo if one is selected or if creating new
    if (logo) {
      officeLogoUrl = await uploadImageToStorage(logo, `virtual-offices/${logo.name}`);
    }

    // Prepare the data to be saved
    const officeData = {
      officeName,
      description,
      visitorAccess,
      units,
      participants: [...participants, user.email],
      // Update the logo only if a new one was uploaded, or keep the existing logo in edit mode.
      ...(officeLogoUrl ? { logo: officeLogoUrl } : {}),
      // Optionally update the createdAt if needed (or leave as is)
    };

    if (editData) {
      // Update existing document
      await updateDoc(doc(db, 'virtual-offices', editData.id), officeData);
      toast.success('Virtual space updated successfully');
    } else {
      // Create a new document
      const docRef = await addDoc(collection(db, 'virtual-offices'), {
        ...officeData,
        type: 'virtual space',
        userId: user.uid,
        createdAt: new Date(),
      });
      const officeLink = `https://app.e-connect.co.za/virtual-office-space/${docRef.id}`;
      await sendEmailInvitation(participants, { officeName, description }, officeLink);
      toast.success('Virtual space created successfully');
    }
    
    // Reset form or navigate back
    setIsSubmitting(false);
    handleBackClick();
  } catch (error) {
    console.error('Error saving virtual office space: ', error);
    toast.error('Error saving virtual office space');
    setIsSubmitting(false);
  }
};


    // Handle form submission
    // const handleSubmit = async (e) => {
    //   e.preventDefault();

    //   if (!logo) {
    //     toast.error('Please upload an office logo before submitting');
    //     return;
    //   }

    //   if (!visitorAccess) {
    //     toast.error('Please select who can visit the space');
    //     return;
    //   }

    //   setIsSubmitting(true);

    //   try {
    //     const user = auth.currentUser;
    //     if (!user) {
    //       throw new Error('User not authenticated');
    //     }

    //     // Upload office logo
    //     const officeLogoUrl = await uploadImageToStorage(logo, `virtual-offices/${logo.name}`);

    //     const docRef = await addDoc(collection(db, 'virtual-offices'), {
    //       officeName,
    //       description,
    //       logo: officeLogoUrl,
    //       type: 'virtual space',
    //       visitorAccess,
    //       userId: user.uid,
    //       createdAt: new Date(),
    //       units,
    //       participants: [...participants, user.email],
    //     });



    //     const officeLink = `https://app.e-connect.co.za/virtual-office-space/${docRef.id}`;

    //          // Send invitations to participants
    //          await sendEmailInvitation(participants, { officeName, description }, officeLink);

    //     toast.success('Virtual space created successfully');
    //     setOfficeName('');
    //     setDescription('');
    //     setLogo(null);
    //     setVisitorAccess('');
    //     setUnits([]);
    //     setIsSubmitting(false);
    //     handleBackClick();
    //   } catch (error) {
    //     console.error('Error saving virtual office space: ', error);
    //     toast.error('Error saving virtual office space');
    //     setIsSubmitting(false);
    //   }
    // };

    const handleEditUnit = (unitIndex) => {
      const unit = units[unitIndex];
      setEditingUnitIndex(unitIndex);
      setCurrentUnit(unit.unitName);
      setTables(unit.tables);
      setIsModalOpen(true);
    };
    

    const addUnit = () => {
      if (!unitName) {
        toast.error('Unit name is required');
        return;
      }
      setCurrentUnit(unitName);
      setUnitName('');
    };

    const addTable = async () => {
      if (!tableName || !chairs || chairs <= 0 || chairs > 20) { // Limit the chairs to 22
        toast.error('Table name and valid number of chairs (1-20) are required');
        return;
      }
    
      setIsAddingTable(true); // Show loader while adding a table
    
      try {
        let tableLogoUrl = '';
        if (tableLogo) {
          tableLogoUrl = await uploadImageToStorage(tableLogo, `virtual-offices/tables/${tableLogo.name}`);
        }
    
        setTables([...tables, { tableName, tableLogo: tableLogoUrl, chairs }]);
        setTableName('');
        setTableLogo(null);
        setChairs(0);
        toast.success('Table added successfully');
      } catch (error) {
        console.error('Error adding table: ', error);
        toast.error('Error adding table');
      } finally {
        setIsAddingTable(false); // Hide the loader after adding
      }
    };

    const finalizeUnit = async () => {
      if (tables.length === 0) {
        toast.error('Each unit must have at least one table');
        return;
      }
    
      setIsFinalizing(true); // Show the loader
    
      setTimeout(() => {
        if (editingUnitIndex !== null) {
          // Update the existing unit at editingUnitIndex
          const updatedUnits = [...units];
          updatedUnits[editingUnitIndex] = { unitName: currentUnit, tables };
          setUnits(updatedUnits);
          setEditingUnitIndex(null);
        } else {
          // Add as new unit if not in edit mode
          setUnits([...units, { unitName: currentUnit, tables }]);
        }
        setCurrentUnit(null);
        setTables([]);
        setIsModalOpen(false);
        setIsFinalizing(false);
        toast.success('Office added successfully');
      }, 2000);
    };

    const sendEmailInvitation = async (emails, officeDetails, officeLink) => {
      const mailDoc = {
        to: emails,
        message: {
          subject: `Invitation to Virtual Office: ${officeDetails.officeName}`,
          html: `
            <p>You have been invited to join the virtual office "<strong>${officeDetails.officeName}</strong>".</p>
            <p><strong>Description:</strong> ${officeDetails.description}</p>
    
            <p>Before you can access the office space, please follow these instructions:</p>
            
            <ol>
              <li>Ensure you have a stable internet connection.</li>
              <li>Click <a href="https://app.e-connect.co.za/sign-up">here</a> to create an account on eConnect if you do not have one.</li>
            </ol>
    
            <p><strong>Step 1:</strong> After creating an account you will be navigated to the following screen to choose the following account type: <strong>Attend Events on eConnect</strong></p>
            <img src="https://e-connect.co.za/app_assets/images/acc-type.png" alt="Step 1 Instruction" width="500"/>
    
            <p><strong>Step 2:</strong> After selecting the account type type and clicking continue you will be directed to the following dashboard:</p>
            <img src="https://e-connect.co.za/app_assets/images/dash-img.png" alt="Step 2 Instruction" width="500"/>

<p><strong>Step 3:</strong> In the left pane, click on "Events" to view all the events you have access to.</p>
            <img src="https://e-connect.co.za/app_assets/images/events-img.png" alt="Step 2 Instruction" width="500"/>
    
            <p><a href="${officeLink}">Alternatively click here Join this Virtual Office</a></p>
          `,
        },
      };
    
      await addDoc(collection(db, 'mail'), mailDoc);
    };
    

    // Finalize adding unit with tables to units list
    // const finalizeUnit = async () => {
    //   if (tables.length === 0) {
    //     toast.error('Each unit must have at least one table');
    //     return;
    //   }

    //   setIsFinalizing(true); // Show the loader

    //   // Simulate a delay for finalizing (or any logic if needed)
    //   setTimeout(() => {
    //     setUnits([...units, { unitName: currentUnit, tables }]);
    //     setCurrentUnit(null);
    //     setTables([]);
    //     setIsModalOpen(false);
    //     setIsFinalizing(false); // Hide the loader
    //     toast.success('Office added successfully');
    //   }, 2000);
    // };

    // const sendEmailInvitation = async (emails, officeDetails, officeLink) => {
    //   const mailDoc = {
    //     to: emails,
    //     message: {
    //       subject: `Invitation to Virtual Office: ${officeDetails.officeName}`,
    //       html: `<p>You have been invited to join the virtual office "${officeDetails.officeName}".</p>
    //              <p><strong>Description:</strong> ${officeDetails.description}</p>

    //              <p>Before you can access the office space make sure 
    //              <p><a href="${officeLink}">Click here: ${officeLink}</a> to access the virtual office.</p>`,
    //     },
    //   };
  
    //   await addDoc(collection(db, 'mail'), mailDoc); 
    // };

    return (
      <div className="dash-backgroundz">
        <div className="content-bodyz">
          <div className="container-fluid">
            <div className="">
              <div className="mb-4">
                <button onClick={handleBackClick} className="theme-btn btn-style-five">
                  <i style={{ color: "#fff", marginRight: "6px" }} className="fas fa-arrow-left"></i> Go Back
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-9 col-lg-8">
                <div className="card profile-card card-bx m-b30">
                  <div className="card-header">
                    <h6 className="title">Virtual Office Space</h6>
                  </div>
                  <form className="profile-form" onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lx-6 m-b30">
                          <label className="form-label">Business Name</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            placeholder="Give your office space an exciting name"
                            value={officeName}
                            onChange={(e) => setOfficeName(e.target.value)}
                          />
                        </div>

                        <div className="col-lx-6 m-b30">
                          <label className="form-label">Who can visit the space?</label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="visitorAccess"
                              value="Anyone can visit"
                              onChange={(e) => setVisitorAccess(e.target.value)}
                              checked={visitorAccess === 'Anyone can visit'}
                            />
                            <label className="form-check-label">Anyone can visit, no need to create an account!</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="visitorAccess"
                              value="Account required"
                              onChange={(e) => setVisitorAccess(e.target.value)}
                              checked={visitorAccess === 'Account required'}
                            />
                            <label className="form-check-label">Have to create an account before visiting our office space!</label>
                          </div>
                        </div>

                        <div className="col-lx-6 m-b30">
                          <label className="form-label">Description</label>
                          <textarea
                            rows="8"
                            className="form-control"
                            placeholder="Office description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>

                        <div className="form-group">
                          <label className="form-label">Units and Tables</label>
                          <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Units and Tables" readOnly />
                            <button className="theme-btn btn-style-five" type="button" onClick={() => setIsModalOpen(true)}>Add Units and Tables</button>
                          </div>
{/* 
                          {units.length > 0 && (
                            <div style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                              <label className="form-label">Added Units and Tables</label>
                              <table className="table table-striped table-units">
                                <thead>
                                  <tr>
                                    <th>Unit Name</th>
                                    <th>Table Name</th>
                                    <th>Number of Chairs</th>
                                    <th>Table Logo</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {units.map((unit, index) => (
                                    unit.tables.map((table, tableIndex) => (
                                      <tr key={`${index}-${tableIndex}`}>
                                        {tableIndex === 0 && (
                                          <td rowSpan={unit.tables.length}>
                                            {unit.unitName}
                                          </td>
                                        )}
                                        <td>{table.tableName}</td>
                                        <td>{table.chairs}</td>
                                        <td>{table.tableLogo ? <img src={table.tableLogo} alt="Table Logo" width="50" height="50" /> : 'No Logo'}</td>
                                      </tr>
                                    ))
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )} */}

{units.length > 0 && (
  <div style={{ paddingTop: '16px', paddingBottom: '16px' }}>
    <label className="form-label">Added Units and Tables</label>
    <table className="table table-striped table-units">
      <thead>
        <tr>
          <th>Unit Name</th>
          <th>Table Name</th>
          <th>Number of Chairs</th>
          <th>Table Logo</th>
          <th>Edit</th> {/* New column for edit */}
        </tr>
      </thead>
      <tbody>
        {units.map((unit, unitIndex) => (
          unit.tables.map((table, tableIndex) => (
            <tr key={`${unitIndex}-${tableIndex}`}>
              {tableIndex === 0 && (
                <td rowSpan={unit.tables.length}>
                  {unit.unitName}
                </td>
              )}
              <td>{table.tableName}</td>
              <td>{table.chairs}</td>
              <td>
                {table.tableLogo ? (
                  <img src={table.tableLogo} alt="Table Logo" width="50" height="50" />
                ) : 'No Logo'}
              </td>
              {tableIndex === 0 && (
                <td rowSpan={unit.tables.length}>
            <button
  type="button" 
  onClick={() => handleEditUnit(unitIndex)}
  style={{ background: 'none', border: 'none', cursor: 'pointer' }}
>
  <i className="fa fa-pencil" aria-hidden="true"></i>
</button>

                </td>
              )}
            </tr>
          ))
        ))}
      </tbody>
    </table>
  </div>
)}

                        </div>

                        <div className="mb-3">
          <label className="form-label">Participants</label>
          <CreatableSelect
            isMulti
            value={participants.map(email => ({ value: email, label: email }))}
            onChange={(selectedOptions) => setParticipants(selectedOptions.map(option => option.value))}
            placeholder="Type email and press enter..."
          />
        </div>



        <div className="mb-3">
          <label className="form-label">Moderators</label>
          <CreatableSelect
            isMulti

            placeholder="Type email and press enter..."
          />
        </div>

                        <div className="form-group">
                          <label className="form-label">Upload Office Logo</label>
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button className="theme-btn btn-style-three" disabled={isSubmitting}>
                        {isSubmitting ? 'Saving...' : 'Create Office Space'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={customStyles}>
              {!currentUnit ? (
                <div>
                  <h4 style={{ color: '#000' }}>Add Office</h4>
                  <div>
                    <label>Office (Room) Name</label>
                    <input
                      type="text"
                      value={unitName}
                      onChange={(e) => setUnitName(e.target.value)}
                      className="form-control"
                      placeholder="Enter Unit Name"
                    />
                    <br />
                    <button onClick={addUnit} className="btn btn-primary">Add Office</button>
                  </div>
                </div>
              ) : (
                <div>
                  <h4 style={{ color: '#000' }}>Add Tables to Office: {currentUnit}</h4>
                  <div>
                    <label>Table Name</label>
                    <input
                      type="text"
                      value={tableName}
                      onChange={(e) => setTableName(e.target.value)}
                      className="form-control"
                      placeholder="Enter Table Name"
                    />
                    <label>Upload Table Logo</label>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      onChange={handleTableFileChange}
                    />
                    <label>Number of Chairs: <small style={{color: 'red'}}>limit 20 chairs per table</small> </label>
                    <input
                      type="number"
                      value={chairs}
                      onChange={(e) => setChairs(e.target.value)}
                      className="form-control"
                      placeholder="Enter Number of Chairs"
                    />
                    <button onClick={addTable} className="btn btn-secondary" style={{ marginTop: '10px' }} disabled={isAddingTable}>
                      {isAddingTable ? <SyncLoader size={8} color={'#fff'} /> : 'Add Table'}
                    </button>

                    {/* Display current tables */}
                    <h6 style={{paddingTop: '5px', color: '#000', fontWeight: 'bold'}}>Added Tables:</h6>
                    <ul>
                      {tables.map((table, index) => (
                        <li key={index}>
                          {table.tableName} (Chairs: {table.chairs}, Logo: {table.tableLogo ? <img src={table.tableLogo} alt="Table Logo" width="50" height="50" /> : 'No Logo'})
                        </li>
                      ))}
                    </ul>
                    <button onClick={finalizeUnit} className="btn btn-primary" disabled={isFinalizing}>
                      {isFinalizing ? <SyncLoader size={8} color={'#fff'} /> : 'Finalize Office'}
                    </button>
                  </div>
                </div>
              )}
            </Modal>
          </div>
        </div>
      </div>
    );
  };

  export default VirtualOfficeForm;
