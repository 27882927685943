import React from 'react'
import StaticHeader from '../components/StaticHeader'

import bannerImg from "../images/events.jpg";
import imgSch from '../../src/dashboardstyle/images/schedule/schedule-3/user.jpg'
import imgSch1 from '../../src/dashboardstyle/images/schedule/schedule-3/user-2.jpg'
import imgSc2 from '../../src/dashboardstyle/images/schedule/schedule-3/user-3.jpg'

import imgSchedule from '../../src/dashboardstyle/images/schedule/schedule-3/schedule.jpg';
import imgSchedule2 from '../../src/dashboardstyle/images/schedule/schedule-3/schedule-2.jpg';
import imgSchedule3 from '../../src/dashboardstyle/images/schedule/schedule-3/schedule-3.jpg';

const ExploreEvents = () => {
  return (
    <div>

      <StaticHeader/>
      <div className="listing">
        <section
          class="parallax-section single-par"
          data-scrollax-parent="true"
        >
          <div
            class="bg par-elem "
            style={{
              transform: "translateZ(0px), translateY(-3.77358%",
              backgroundImage: `url(${bannerImg})`,
            }}
          ></div>
          <div class="overlay op7"></div>
          <div class="container">
            <div class="section-title center-align big-title">
              <h2>
                <span>All Events</span>
              </h2>
              <span class="section-separator"></span>
            </div>
          </div>
          <div class="header-sec-link">
            <a href="#sec1" class="custom-scroll-link text-center">
              <i class="fas fa-angle-double-down"></i>
            </a>
          </div>
        </section>
      </div>

      <section class="gray-bg small-padding no-top-padding-sec" id="sec1">
        <div class="container">
          <div
            class="list-main-wrap-header fl-wrap   block_box no-vis-shadow no-bg-header fixed-listing-header"
            style={{ zIndex: 1000 }}
          >
            <div class="list-main-wrap-title">
              <h2>Explore Events</h2>
            </div>

            <div class="list-main-wrap-opt">
              <div class="price-opt">
                {/* <span class="price-opt-title">Sort by:</span> */}
                <div class="listsearch-input-item">
                  <select
                    data-placeholder="Popularity"
                    class="chosen-select no-search-select"
                  >
                    <option>Upcoming Events</option>
              
                  </select>
                </div>
              </div>
            </div>

            <a class="custom-scroll-link back-to-filters clbtg" href="#lisfw">
              <i class="fas fa-search"></i>
            </a>
          </div>
          <div></div>

         
        </div>
      </section>

      


  <div class="schedule-area py-120">
            <div class="container">
              
             
                <div class="tab-content wow fadeInUp" data-wow-delay=".25s" id="pills-tabContent-schedule">
                    
                    <div class="tab-pane fade show active" id="pills-schedule1" role="tabpanel" aria-labelledby="pills-schedule-tab1" tabindex="0">
                        <div class="row g-4">
                            <div class="col-lg-12">
                                <div class="schedule-item">
                                    <span class="schedule-count">01</span>
                                    <div class="schedule-content-wrap">

                                  

                                    <div>

                                    <div class="schedule-img p-relative">
                                            <img src={imgSchedule3} alt=""/>
                                            <span class="td-schedule-2-datez"><i class="fas fa-calendar"></i> jan 10, 2025</span>
                                        </div>
                                        
                                    </div>
                                        <div class="schedule-content">
                                            <div class="schedule-info">
                                                <div class="schedule-meta">
                                                    <ul>
                                                        <li><i class="fas fa-clock"></i> 10.00 AM - 12.00 PM</li>
                                                        <li><i class="fas fa-location-dot"></i> 25/B Milford, New York</li>
                                                    </ul>
                                                </div>
                                                <h4><a href="/detailsev">Business World Event Introduction</a></h4>
                                                <p>There are many variations of passages available but the majority have suffered alteration in some form by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                            <div class="schedule-bottom">
                                                <div class="schedule-speaker">
                                                    <div class="speaker-item">
                                                        <div class="speaker-img">
                                                            <img src={imgSch} alt=""/>

                                                            <span class="speaker-img-icon"><i class="fas fa-microphone-lines"></i></span>
                                                        </div>
                                                        <div class="speaker-info">
                                                            <h6>Frederick Taylor</h6>
                                                            <span>UX Designer</span>
                                                        </div>
                                                    </div>
                                                    <div class="speaker-item">
                                                        <div class="speaker-img">
                                                            <img src={imgSch1} alt=""/>
                                                            <span class="speaker-img-icon"><i class="fas fa-microphone-lines"></i></span>
                                                        </div>
                                                        <div class="speaker-info">
                                                            <h6>Tony Mendoza</h6>
                                                            <span>Developer</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href="/detailsev" class="theme-btn btn-style-three">View Event<i class="fas fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="schedule-item">
                                    <span class="schedule-count">02</span>
                                    <div class="schedule-content-wrap">
                                        <div class="schedule-img p-relative">
                                            <img src={imgSchedule2 } alt=""/>
                                            <span class="td-schedule-2-datez"><i class="fas fa-calendar"></i> jan 10, 2025</span>
                                        </div>
                                        <div class="schedule-content">
                                            <div class="schedule-info">
                                                <div class="schedule-meta">
                                                    <ul>
                                                        <li><i class="fas fa-clock"></i> 10.00 AM - 12.00 PM</li>
                                                        <li><i class="fas fa-location-dot"></i> 25/B Milford, New York</li>
                                                    </ul>
                                                </div>
                                                <h4><a href="/detailsev">Introduction Digital Technology Conference.</a></h4>
                                                <p>There are many variations of passages available but the majority have suffered alteration in some form by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                            <div class="schedule-bottom">
                                                <div class="schedule-speaker">
                                                    <div class="speaker-item">
                                                        <div class="speaker-img">
                                                            <img src={imgSch} alt=""/>
                                                            <span class="speaker-img-icon"><i class="fas fa-microphone-lines"></i></span>
                                                        </div>
                                                        <div class="speaker-info">
                                                            <h6>Frederick Taylor</h6>
                                                            <span>UX Designer</span>
                                                        </div>
                                                    </div>
                                                    <div class="speaker-item">
                                                        <div class="speaker-img">
                                                            <img src={imgSch1} alt=""/>
                                                            <span class="speaker-img-icon"><i class="fas fa-microphone-lines"></i></span>
                                                        </div>
                                                        <div class="speaker-info">
                                                            <h6>Tony Mendoza</h6>
                                                            <span>Developer</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href="/detailsev" class="theme-btn btn-style-three">View Event<i class="fas fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="schedule-item last">
                                    <span class="schedule-count">03</span>
                                    <div class="schedule-content-wrap">
                                        <div class="schedule-img p-relative">
                                            <img src={imgSchedule} alt=""/>
                                            <span class="td-schedule-2-datez"><i class="fas fa-calendar"></i> jan 10, 2025</span>
                                        </div>
                                        <div class="schedule-content">
                                            <div class="schedule-info">
                                                <div class="schedule-meta">
                                                    <ul>
                                                        <li><i class="fas fa-clock"></i> 10.00 AM - 12.00 PM</li>
                                                        <li><i class="fas fa-location-dot"></i> 25/B Milford, New York</li>
                                                    </ul>
                                                </div>
                                                <h4><a href="/detailsev">International Technology Summit Conference.</a></h4>
                                                <p>There are many variations of passages available but the majority have suffered alteration in some form by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                            <div class="schedule-bottom">
                                                <div class="schedule-speaker">
                                                    <div class="speaker-item">
                                                        <div class="speaker-img">
                                                            <img src={imgSch} alt=""/>
                                                            <span class="speaker-img-icon"><i class="fas fa-microphone-lines"></i></span>
                                                        </div>
                                                        <div class="speaker-info">
                                                            <h6>Frederick Taylor</h6>
                                                            <span>UX Designer</span>
                                                        </div>
                                                    </div>
                                                    <div class="speaker-item">
                                                        <div class="speaker-img">
                                                            <img src={imgSch1} alt=""/>
                                                            <span class="speaker-img-icon"><i class="fas fa-microphone-lines"></i></span>
                                                        </div>
                                                        <div class="speaker-info">
                                                            <h6>Tony Mendoza</h6>
                                                            <span>Developer</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href="/detailsev" class="theme-btn btn-style-three">View Event<i class="fas fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                   
                        
                <div className="text-center paginsation">
                                            <div class="pagination fwmpag">
                                                <a href="#" class="prevposts-link"><i class="fas fa-caret-left"></i><span>Prev</span></a>
                                                <a href="#">1</a>
                                                <a href="#" class="current-page">2</a>
                                                <a href="#">3</a>
                                                <a href="#">...</a>
                                                <a href="#">7</a>
                                                <a href="#" class="nextposts-link"><span>Next</span><i class="fas fa-caret-right"></i></a>
                                            </div>
                                            </div>
                </div>
              
            </div>
          
        </div>
    </div>
  )
}

export default ExploreEvents
